import {Component} from '@angular/core';

@Component({
  selector: 'app-check-hire',
  templateUrl: './checkHire.html',
})
export class CheckHireComponent {

  constructor() {
  }
}
