import {Component, ViewChild} from '@angular/core';
import {HireService} from './hire.service';
import {Router} from '@angular/router';
import {DopParamsService} from '../../services/dopParams.service';
import {GlobalParamsRental} from '../rental/global-params-rental';
import {ApplicationsService} from '../applications/applications.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';
import {CdkDragDrop, CdkDragStart, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatTableDataSource} from '@angular/material';
import {GlobalParams} from '../../storage/global-params';
import {concat} from 'rxjs';

@Component({
  selector: 'app-hire',
  templateUrl: './hire.component.html',
})


export class HireComponent {
  p = 1;
  statusList: InterFaceDopParamsColor[] = [];
  // отображение фильтра
  showFilters = false;
  // отображение фильтра
  showActiveFields = false;
  // отображение колонок таблицы
  showTableColumns = false;
  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = [];
  activeFieldsTablesSecond = [];
  branches: InterFaceDopParams[] = [];
  hireStates: InterFaceHireState[] = [];
  // список доп статусов для заявок
  applicationDopStatusList: InterFaceDopParamsColor[] = [];
  cdkDragDisabled = true;
  filters = {
    like: '',
    likeEq: '',
    likeClient: '',
    status: '',
    branch: 0,
    date_start: '',
    date_end: '',
    show_close_hire: '0',
    date_start_hire: '',
    date_end_hire: '',
    state: 0,
    sum: '',
    sale_sum: '',
    sum_hire: '',
    total_paid: '',
    date_create: '',
    comment: '',
    date_close_hire: '',
    id_dog: '',
    user_type: 0,
    square: '',
    month_sum: '',
    currentPay: 0,
    sort: {active: '', direction: ''},
    offset: 0,
    dop_status: [],
    limit: 50
  };
  dop_statusS = [];
  closeHireOption = [
    {val: '0', name: 'Скрыть'},
    {val: '1', name: 'Показать'},
  ];

  // список прокатов
  hires: InterFaceHire[] = [];

  sortedData: InterFaceHire[];
  lesa = false;
  sum = {remainder: 0, paid_today: 0};
  timerId = null;
  hiresCount = null;

  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  displayedColumns: string[] = [];
  dataSource: any = [];

  previousIndex: number;

  currentPayList = [
    {val: 0, name: 'Нет'},
    {val: 1, name: 'Да'},
  ];

  constructor(public hireService: HireService,
              private router: Router,
              private dopParamsService: DopParamsService,
              private applicationsService: ApplicationsService,
              public globalParamsUser: GlobalParamsUser,
              public globalParams: GlobalParams,
              private globalParamsRental: GlobalParamsRental) {


    this.cdkDragDisabled = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(window.navigator.userAgent));

    this.hireService.getHireFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении списка полей проката: ', error);
      });

    this.hireService.getHireState().then((data: InterFaceHireState[]) => {
        this.hireStates = [{'val': 0, name: 'Все'}].concat(data);
      },
      (error) => {
        console.log('Ошибка при получении списка статусов: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = [{'val': 0, name: 'Все'}].concat(data);
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.applicationsService.refreshInfo.subscribe(() => {
      this.getHire();
    });

    this.applicationsService.getApplicationsDopStatus().then((data: InterFaceDopParamsColor[]) => {
        this.applicationDopStatusList = data;

        if (this.globalParamsUser.type === 3) {
          this.dop_statusS = this.applicationDopStatusList;
          this.filters.dop_status = this.applicationDopStatusList.map(item => item.val);
        }

        this.getHire();
      },
      (error) => {
        console.log('Ошибка при получении статусов заявок: ', error);
      });
  }

  // отображение фильтра
  changeShowFilters() {
    this.showFilters = !this.showFilters;
  }

  // отображение фильтра
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }
  // отображение колонок таблицы
  changeTableColumns() {
      this.showTableColumns = !this.showTableColumns;
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    this.activeFieldsTablesSecond = [];
    for (const value of this.activeFields) {
      if (value.flag === 1) {
        this.activeFieldsTablesSecond.push(value);
      }
      this.activeFieldsTables[value.code] = value.flag;
    }
    this.setDisplayedColumns(false);
  }

  // изменение списка активных полей
  changeFields() {
    this.hireService.changeFields({data: this.activeFields}).then(() => {
        this.showActiveFields = false;
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }

  // изменение списка полей
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  changeFilterDopStatus(data) {
    this.dop_statusS = data;
    this.filters.dop_status = this.dop_statusS;
    this.getHire();
  }

  // очистка фильтра
  clearFilter() {
    this.filters = {
      like: '',
      likeEq: '',
      likeClient: '',
      status: '',
      branch: 0,
      date_start: '',
      date_end: '',
      show_close_hire: '0',
      date_start_hire: '',
      date_end_hire: '',
      state: 0,
      sum: '',
      sale_sum: '',
      sum_hire: '',
      total_paid: '',
      date_create: '',
      comment: '',
      date_close_hire: '',
      id_dog: '',
      user_type: 0,
      square: '',
      month_sum: '',
      currentPay: 0,
      sort: {active: '', direction: ''},
      offset: 0,
      dop_status: [],
      limit: 50
    };

    this.getHire();
  }

  hireDetails(id) {
    console.log('hireDetails from hire.components');
    this.router.navigate(['/hire/' + id]);
  }

  // смена типа клиента
  changeUserType(data) {
    this.filters.user_type = data;
    this.getHire();
  }

  getHireTimeOut() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }


    this.timerId = setTimeout(() => {
      this.filters.offset = 0;
      this.p = 1;
      this.getHire();
    }, 1000);
  }

  getHire() {
    this.hireService.getHire({
      status: this.filters.status,
      like: this.filters.like,
      likeEq: this.filters.likeEq,
      likeClient: this.filters.likeClient,
      branch: this.filters.branch,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      show_close_hire: this.filters.show_close_hire,
      date_start_hire: this.filters.date_start_hire !== '' ? moment(this.filters.date_start_hire).format().slice(0, 10) : '',
      date_end_hire: this.filters.date_end_hire !== '' ? moment(this.filters.date_end_hire).format().slice(0, 10) : '',
      state: this.filters.state,
      sum: this.filters.sum,
      sale_sum: this.filters.sale_sum,
      sum_hire: this.filters.sum_hire,
      total_paid: this.filters.total_paid,
      date_create: this.filters.date_create !== '' ? moment(this.filters.date_create).format().slice(0, 10) : '',
      comment: this.filters.comment,
      date_close_hire: this.filters.date_close_hire !== '' ? moment(this.filters.date_close_hire).format().slice(0, 10) : '',
      id_dog: this.filters.id_dog,
      square: this.filters.square,
      month_sum: this.filters.month_sum,
      user_type: this.filters.user_type,
      lesa: false,
      currentPay: this.filters.currentPay,
      sort: this.filters.sort,
      offset: this.filters.offset,
      dop_status: this.filters.dop_status,
      limit: this.filters.limit,
    }).then((data: { data: InterFaceHire[], remainder: number, paid_today: number, count: number }) => {
        this.sum.remainder = data.remainder;
        this.sum.paid_today = data.paid_today;
        this.hires = data.data;
        this.dataSource = new MatTableDataSource(this.hires);

        this.sortedData = this.hires.slice();
        this.showFilters = false;
        this.hiresCount = data.count;
      },
      (error) => {
        console.log('Ошибка при получении списка прокатов: ', error);
      });
  }

  // вывод телефона при наведении
  mouseEnter(value, value2, value3) {
    let tel = '';

    [value, value2, value3].forEach(function (ll) {
      if (ll !== '') {
        const country = ll.substr(0, 1);
        const city = ll.substr(1, 3);
        const number = ll.substr(4, 3);
        const number2 = ll.substr(7, 2);
        const number3 = ll.substr(9, 2);
        tel += `${country}(${city})-${number}-${number2}-${number3}, `;
      }
    });

    return `Телефон : ${tel}`;
  }

  // вывод примечания
  mouseCommit(value) {
    return `Примечание : ${value}`;
  }

  showInsertRental(app_id, typeLease_id, rent_end) {
    const rent_end_c = moment(rent_end, 'DD.MM.YYYY HH:mm').format();

    this.globalParamsRental.data = {
      show: true,
      date_end: rent_end,
      type_lease: typeLease_id,
      app_id: app_id,
      date_end_format: rent_end_c,
      const_date_end_format: rent_end_c,
      count: null
    };
  }

  sortData(sort: any) {
    if (sort !== this.filters.sort.active || this.filters.sort.active === '') {
      this.filters.sort = {active: sort, direction: 'asc'};
    } else {
      const direction = this.filters.sort.direction === 'asc' ? 'desc' : 'asc';
      this.filters.sort = {active: sort, direction};
    }

    this.getHire();
  }

  changePage(data) {
    this.p = data;
    this.filters.offset = this.p * 50 - 50;
    this.getHire();
  }

  changeCount(count) {
    this.filters.limit = count;
    this.getHire();
  }

  setDisplayedColumns(update) {
    this.activeFieldsTablesSecond.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.code;
    });

    if (update) {
      this.hireService.sortColumn({data: this.displayedColumns, type: 'hire'}).then(() => {
        },
        () => {
          console.log('Ошибка при сортировке колонок');
        });
    }
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.activeFieldsTablesSecond, this.previousIndex, index);
      this.setDisplayedColumns(true);
    }
  }

  public handlePage(e: any) {
    this.filters.limit = e.pageSize;
    this.filters.offset = e.pageSize * e.pageIndex;
    this.getHire();
  }

  onChanged(increased: any, type: string, update = false) {
    this.filters[type] = increased;

    if (update) {
      this.getHire();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event) {
      moveItemInArray(this.activeFieldsTablesSecond, event.previousIndex, event.currentIndex);
      this.setDisplayedColumns(true);
    }
  }
}

