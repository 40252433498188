import {Component} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HireService} from '../hire/hire.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {GlobalPayList} from '../pay_list/global-pay-list';
import {GlobalParamsPay} from '../pay/global-params-pay';
import {GlobalParamsRental} from '../rental/global-params-rental';
import {ApplicationsCreateService} from '../applications-create/applicationsCreate.service';
import {DopParamsService} from '../../services/dopParams.service';
import {ApplicationsService} from '../applications/applications.service';
import {GlobalExtensionsList} from '../extensions_list/global-extensions-list';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';
import {GlobalParamsTame} from '../tame/global-params-tame';
import {DocumentService} from '../../services/document.service';
import {EquipmentsService} from '../equipments/equipments.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FinanceService} from '../finance/finance.service';
import {ClientInfoService} from '../client_info/clientInfo.service';
import {GlobalParams} from '../../storage/global-params';

@Component({
  selector: 'app-hire-info',
  templateUrl: './hireInfo.component.html',
})
export class HireInfoComponent {
  // идентификатор заявки
  hireId: null;
  showCloseHireButton = false;
  delivery: InterFaceDopParams[] = [];
  showButtonCloseHire = false;
  applicationsTypeLeases: InterFaceDopParams[] = [];
  // список доп статусов для заявок
  applicationDopStatusList: InterFaceDopParamsColor[] = [];
  branches: InterFaceBranches[] = [];
  showReturnEq = false;
  // окно доставки
  showDelivery = false;
  img = '';
  // комментарий при отмене брони
  canceledReservation = {
    view: false,
    comment: ''
  };

  // переход по вкладкам
  currentTab = 1;
  canSendFeedback = true;
  // показываем список звонков
  showRecording = false;

  showDoc = false;

  // список прокатов
  hireInfo: InterFaceHireInfo = {
    id: null,
    branch: '',
    branch_id: null,
    delivery: null,
    delivery_sum: '0',
    typeLease_id: null,
    sale: '',
    sale_in_sum: '',
    source: null,
    status_id: null,
    status_name: '',
    comment: '',
    rent_start: '',
    rent_end: '',
    client_fio: '',
    client_phone: '',
    client_inn: '',
    client_comment: '',
    client_status: '',
    sum: '',
    total_paid: '',
    remainder: '',
    count: null,
    hire_state_id: null,
    hire_state: '',
    client_type: null,
    equipments: {
      equipments_id: '',
      name: '',
      state: '',
      photo: null,
      state_id: null,
      photo_alias: '',
      price_per_day: ''
    },
    delivery_address: '',
    pay_list: [{
      'date': null,
      'user_id': null,
      'sum': '',
      'cash_box': '',
      'cash_box_id': null,
      'branch': ''
    }],
    extensions: [{
      'date': null,
      'user_id': '',
      'count': null
    }],
    lesa: '',
    rama_prokhodnaya: null,
    rama_letsnitsey: null,
    diagonalnaya_svyaz: null,
    gorizontalnaya_svyaz: null,
    rigel: null,
    nastil: null,
    rama_prokhodnaya_id: null,
    rama_letsnitsey_id: null,
    diagonalnaya_svyaz_id: null,
    gorizontalnaya_svyaz_id: null,
    rigel_id: null,
    nastil_id: null,
    month_sum: '',
    square: '',
    address: '',
    delivery_data: {delivery_sum: '', delivery_sum_paid: '', delivery_app: [{name: '', id: null}]},
    recordingList: [],
    phone_chief: '',
    phone_second: '',
    phone_first_name: '',
    client_email: '',
    phone_second_name: '',
    phone_chief_name: '',
    client_id: null,
    check_zalog: false,
    sum_zalog: '',
    dop_status: '',
    appDocs: [],
    clientDocs: [],
    hire_log: [],
  };
  financeCashBox: InterFaceFinanceCashBox[];
  showChangePay = false;
  currentPay: {
    'date': null,
    'id': null,
    'user_id': null,
    'sum': '',
    'cash_box': '',
    'cash_box_id': null,
    'branch': ''
  } = {
    'date': null,
    'id': null,
    'user_id': null,
    'sum': '',
    'cash_box': '',
    'cash_box_id': null,
    'branch': ''
  };
  tmp_rent_start = '';
  countDay = 0;
  showCloseHireModal = false;
  clientPhones = [];
  whatsUpForm = {
    phone: null,
    text: null,
  };
  photo = {
    photo_name: '',
    photo_content: ''
  };
  // загрузка изображения
  load_file: any = [];
  fileString = '';
  files = [];

  sms = {
    show: false,
    phone: '',
    templateId: '',
    txt: '',
  };

  constructor(public hireService: HireService,
              private router: ActivatedRoute,
              private globalParamsMessage: GlobalParamsMessage,
              public globalPayList: GlobalPayList,
              private globalParamsPay: GlobalParamsPay,
              private clientInfoService: ClientInfoService,
              private globalParamsRental: GlobalParamsRental,
              private globalParamsTame: GlobalParamsTame,
              private globalParams: GlobalParams,
              private applicationsService: ApplicationsService,
              private applicationsCreateService: ApplicationsCreateService,
              private dopParamsService: DopParamsService,
              private routerCurrent: Router,
              private documentService: DocumentService,
              public globalParamsUser: GlobalParamsUser,
              public financeService: FinanceService,
              public equipmentsService: EquipmentsService,
              public sanitizer: DomSanitizer,
              private globalExtensionsList: GlobalExtensionsList) {

    this.router.params.subscribe(
      (params: Params): void => {
        this.hireId = params.id;
      }
    );

    this.applicationsService.refreshInfo.subscribe(() => {
      this.getHireInfo();
    });

    this.financeService.getFinanceCashBOx().then((data: InterFaceFinanceCashBox[]) => {
        this.financeCashBox = data;
      },
      (error) => {
        console.log('Ошибка при получении касс: ', error);
      });

    this.applicationsCreateService.getApplicationsDelivery().then((data: InterFaceDopParams[]) => {
        this.delivery = data;
      },
      (error) => {
        console.log('Ошибка при получении статусов доставки: ', error);
      });

    this.applicationsCreateService.getApplicationsTypeLease().then((data: InterFaceDopParams[]) => {
        this.applicationsTypeLeases = data;
      },
      (error) => {
        console.log('Ошибка при получении типов аренды: ', error);
      });
    this.applicationsService.getApplicationsDopStatus().then((data: InterFaceDopParamsColor[]) => {
        this.applicationDopStatusList = data;
      },
      (error) => {
        console.log('Ошибка при получении статусов заявок: ', error);
      });
    this.dopParamsService.getBranch().then((data: InterFaceBranches[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
    this.getHireInfo();
  }

  chowChangeFunc(pay) {
    this.currentPay = pay;
    this.showChangePay = true;
  }

  getHireInfo() {
    this.hireService.getHireInfo({id: this.hireId}).then((data: InterFaceHireInfo) => {
        this.showReturnEq = false;
        this.hireInfo = data;
        this.tmp_rent_start = moment(this.hireInfo.rent_start).format().slice(0, 16);
        this.hireInfo.rent_start = moment(this.hireInfo.rent_start).format().slice(0, 16);
        this.hireInfo.rent_end = moment(this.hireInfo.rent_end).format().slice(0, 16);
        this.globalPayList.data.pay_list = this.hireInfo.pay_list;
        this.globalExtensionsList.data.extension_list = this.hireInfo.extensions;
        this.initHireModalData();

        if (this.globalParamsUser.rights.indexOf(4) !== -1) {
          this.hireInfo.pay_list.forEach(function (value) {
            value.date = moment(value.date).format().slice(0, 16);
          });
        }

        // показ кнопки закрыть прокат
        if (this.globalParamsUser.type === 1) {
          this.showCloseHireButton = true;
        } else {
          this.showCloseHireButton = this.hireInfo.equipments.state_id === 4;
        }

        // показ закрыть прокат только когда админ или менеджер, но оборудование на складе и нет долгов
        if (this.globalParamsUser.type === 1 || (this.globalParamsUser.type === 2 && this.hireInfo.equipments.state_id === 4)) {
          this.showButtonCloseHire = true;
        }

        // возврат оборудования только когда он еще в прокате и прокат находится в стадии 'прокат' или 'бронь'

        if (this.hireInfo.equipments.state_id !== 4 && [1, 2, 4, 5].indexOf(this.hireInfo.hire_state_id)) {
          this.showReturnEq = true;
        }

        if (this.hireInfo.equipments.state_id === 4) {
          this.img = 'ok';
        }

        const date1 = new Date(this.hireInfo.rent_start);
        const date2 = new Date(this.hireInfo.rent_end);

        const daysLag = Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));

        if (this.hireInfo.typeLease_id === 1) {
          this.countDay = daysLag;
        } else {
          this.countDay = Math.round(daysLag / 30);
        }
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
  }
  initHireModalData() {
    const branch = this.branches.filter(item => item.val === this.hireInfo.branch_id)[0];
    let text = '';
    if (this.hireInfo.client_type === 1) {
      const client_name = this.hireInfo.client_fio.split(' ');
      text += client_name[1] + ' ' + client_name[2] + ', ';

    } else {
      if (this.hireInfo.phone_chief_name && this.hireInfo.phone_chief_name.length > 0) {
        text += this.hireInfo.phone_chief_name + ', ';
      }
    }
    text += 'благодарим за пользование нашим сервисом аренды. Оставьте пожалуйста оценку или отзыв по ссылке ниже:';
    if (branch.yandex_link && branch.yandex_link.length > 0) {
      text += ' \n' + branch.yandex_link + '\n\n';

    }
    text += 'Ссылка станет активной если написать в чат любое сообщение либо добавить нас в контакты, спасибо!';
    this.whatsUpForm.text = text;
    this.clientPhones = [];
    if (this.hireInfo.client_phone) {
      this.whatsUpForm.phone = this.hireInfo.client_phone;
      this.clientPhones.push({
        val: this.hireInfo.client_phone,
        name: this.hireInfo.client_phone
      });
    }
    if (this.hireInfo.phone_second) {
      this.clientPhones.push({
        val: this.hireInfo.phone_second,
        name: this.hireInfo.phone_second
      });
    }
    if (this.hireInfo.phone_chief) {
      this.clientPhones.push({
        val: this.hireInfo.phone_chief,
        name: this.hireInfo.phone_chief
      });
    }
  }
  getHireInfoDocs() {
    this.hireService.getHireInfoDocs({id: this.hireId}).then((data: any) => {
        this.hireInfo.clientDocs = data.clientDocs;
        this.hireInfo.appDocs = data.appDocs;
      },
      (error) => {
        console.log('Ошибка при получении документов: ', error);
      });
  }

  changeSum() {
    if (+this.hireInfo.sale_in_sum > +this.globalParamsUser.settings.sale_rub_limit) {
      this.hireInfo.sale_in_sum = this.globalParamsUser.settings.sale_rub_limit;
      this.globalParamsMessage.data = {
        title: `Максимальная скидка ` + this.globalParamsUser.settings.sale_rub_limit,
        type: 'error',
        body: ''
      };

      return false;
    }
    if (+this.hireInfo.sale > +this.globalParamsUser.settings.sale_percent_limit) {
      this.hireInfo.sale = this.globalParamsUser.settings.sale_percent_limit;
      this.globalParamsMessage.data = {
        title: `Максимальная скидка ` + this.globalParamsUser.settings.sale_percent_limit,
        type: 'error',
        body: ''
      };

      return false;
    }

    this.updateHire();
  }

  updateHire() {
    const newRentTime = moment.utc(this.hireInfo.rent_start);
    const oldRentTime = moment.utc(this.tmp_rent_start);
    const diff = newRentTime.diff(oldRentTime, 'minutes');

    if (diff !== 0) {
      this.hireInfo.rent_end = moment(this.hireInfo.rent_end).add(diff,'minutes').format().slice(0, 16);
      this.tmp_rent_start = moment(this.hireInfo.rent_start).format().slice(0, 16);
    }

    this.hireService.updateHire({
      id: this.hireInfo.id,
      delivery: this.hireInfo.delivery,
      delivery_sum: this.hireInfo.delivery_sum,
      sale: this.hireInfo.sale,
      sale_in_sum: this.hireInfo.sale_in_sum,
      typeLease_id: this.hireInfo.typeLease_id,
      rent_start: this.hireInfo.rent_start !== '' ? moment(this.hireInfo.rent_start).format().slice(0, 16) : '',
      rent_end: this.hireInfo.rent_end !== '' ? moment(this.hireInfo.rent_end).format().slice(0, 16) : '',
      comment: this.hireInfo.comment,
      dop_status: this.hireInfo.dop_status,
      month_sum: this.hireInfo.month_sum,
      lesa: [
        {
          item_id: this.hireInfo.rama_prokhodnaya_id,
          item_count: this.hireInfo.rama_prokhodnaya,
        },
        {
          item_id: this.hireInfo.rama_letsnitsey_id,
          item_count: this.hireInfo.rama_letsnitsey,
        },
        {
          item_id: this.hireInfo.diagonalnaya_svyaz_id,
          item_count: this.hireInfo.diagonalnaya_svyaz,
        },
        {
          item_id: this.hireInfo.gorizontalnaya_svyaz_id,
          item_count: this.hireInfo.gorizontalnaya_svyaz,
        },
        {
          item_id: this.hireInfo.rigel_id,
          item_count: this.hireInfo.rigel,
        },
        {
          item_id: this.hireInfo.nastil_id,
          item_count: this.hireInfo.nastil,
        }
        ]
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Заявка успешно обновлена', type: 'success', body: ''};
        this.getHireInfo();
      },
      (error) => {
        console.log('Ошибка при обновлении заявки: ', error);
      });
  }

  equipmentReturn() {
    this.hireService.equipmentReturn({
      id: this.hireInfo.id
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Товар успешно отправлен на склад', type: 'success', body: ''};
        this.getHireInfo();
      },
      (error) => {
        console.log('Ошибка при обновлении заявки: ', error);
      });
  }

  showInsertSum() {
    this.globalParamsPay.data = {
      show: true,
      sum: '',
      app_id: this.hireInfo.id,
      cashBox: null,
      revertSum: false,
      branch: this.hireInfo.branch_id
    };
  }

  // продление проката
  showInsertRental() {
    console.log(this.hireInfo);
    this.globalParamsRental.data = {
      show: true,
      date_end: moment(this.hireInfo.rent_end).format('DD.MM.YYYY HH:MM'),
      type_lease: this.hireInfo.typeLease_id,
      app_id: this.hireInfo.id,
      date_end_format: moment(this.hireInfo.rent_end).format().slice(0, 16),
      const_date_end_format: moment(this.hireInfo.rent_end).format().slice(0, 16),
      count: null
    };
  }

  // сокращение проката
  showTameRental() {
    this.globalParamsTame.data = {
      show: true,
      date_end: this.hireInfo.rent_end,
      type_lease: this.hireInfo.typeLease_id,
      app_id: this.hireInfo.id,
      count: ''
    };
  }

  // удалить прока
  delete_hire() {
    this.hireService.deleteHire({
      id: this.hireInfo.id
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Прокат успешно удален', type: 'success', body: ''};

        if (this.hireInfo.lesa === '1') {
          this.routerCurrent.navigate(['/hire-lesa']);
        } else {
          this.routerCurrent.navigate(['/hire']);
        }

      },
      (error) => {
        console.log('Ошибка при удалении проката: ', error);
      });
  }
  openUrlAndCloseHire() {
    this.close_hire(false);
    const formattedText = this.whatsUpForm.text.replace(/\n/g, '%0A');
   const url = 'https://web.whatsapp.com/send?phone=' + this.whatsUpForm.phone + '&text=' + formattedText;



    if (this.canSendFeedback) {
      this.canSendFeedback = false;
      this.hireService.sendStatFeedback({
        type: 1,
        hire_id: this.hireId
      }).then(() => {
        this.canSendFeedback = true;
      });
    }

    window.open(url, '_blank').focus();
  }
  // закрыть прокат
  close_hire(sentFeedback) {
    if (this.canSendFeedback && sentFeedback) {
      this.canSendFeedback = false;
      this.hireService.sendStatFeedback({
        type: 0,
        hire_id: this.hireId
      }).then(() => {
        this.canSendFeedback = true;
      });
    }

    this.hireService.closeHire({
      id: this.hireInfo.id
    }).then(() => {

        this.getHireInfo();
      },
      (error) => {
        console.log('Ошибка при закрытии проката: ', error);
      });
  }

  // отмена брони
  sendCanceledReservation() {
    if (this.canceledReservation.comment === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать причину отмены брони', type: 'success', body: ''};
      return false;
    }

    this.hireService.canceledHire({
      id: this.hireInfo.id,
      comment: this.canceledReservation.comment
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Бронь успешно отменена', type: 'success', body: ''};

        if (this.hireInfo.lesa === '1') {
          this.routerCurrent.navigate(['/hire-lesa']);
        } else {
          this.routerCurrent.navigate(['/hire']);
        }

      },
      (error) => {
        console.log('Ошибка при отмене брони: ', error);
      });
  }

  // получение доков
  save_word(data, multiple) {
    this.documentService.getWord({
      hireInfoId: this.hireInfo.id,
      lesa: this.hireInfo.lesa,
      check: data,
      multiple: multiple
    }).then((result: any) => {
        window.open(this.globalParams.path + '/uploads/tmp/' + result, '_blank');
      },
      (error) => {
        console.log('Ошибка при скачивании документа: ', error);
      });
  }

  // получение доков по прокату
  get_hire_doc(name) {
    window.open(this.globalParams.path + '/uploads/hire/' + name, '_blank');
  }

  // получение доков по клиенту
  get_hire_client(name) {
    window.open(this.globalParams.path + '/uploads/client/' + name, '_blank');
  }

  // смена даты
  changeDate() {
    this.hireService.changeDate({
      id: this.currentPay.id,
      date: this.currentPay.date,
      sum: this.currentPay.sum,
      cashBoxId: this.currentPay.cash_box_id
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Платеж успешно изменен', type: 'success', body: ''};
        this.showChangePay = false;
        this.getHireInfo();
      },
      (error) => {
        this.globalParamsMessage.data = {title: 'Ошибка при изменении платежа', type: 'error', body: ''};
      });
  }

  revertZalog() {
    this.hireService.revertSum({app_id: this.hireInfo.id}).then(() => {
        this.globalParamsMessage.data = {title: 'Залог успешно вернули', type: 'success', body: ''};
        this.getHireInfo();
      },
      (error) => {
        this.globalParamsMessage.data = {title: 'Ошибка при возврате залога', type: 'error', body: ''};
      });
  }

  callMoiZvonki(phone) {
    this.clientInfoService.callMoiZvonki({phone: phone}).then(() => {
      },
      (error) => {
        console.log('Ошибка при звонке: ', error);
      });
  }

  returnHire() {
    this.hireService.returnHire({id: this.hireInfo.id}).then(() => {
        this.globalParamsMessage.data = {title: 'Прокат успешно вернули', type: 'success', body: ''};
        this.getHireInfo();
      },
      (error) => {
        console.log('Ошибка при возврате проката: ', error);
      });
  }

  addPhoto(event) {
    const target = event.target || event.srcElement;
    for (let j = 0; target.files.length > j; j++) {
      this.load_file = target.files[j];
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this, name);
      reader.readAsBinaryString(this.load_file);
    }
  }

  _handleReaderLoaded(data, readerEvt) {
    const binaryString = readerEvt.target.result;
    this.fileString = btoa(binaryString);

    this.photo.photo_name = this.load_file.name;
    this.photo.photo_content = this.fileString;
  }

  saveHireDoc(type) {
    const formData = new FormData();
    for (const file of this.files) {
      formData.append(name, file, file.name);
    }

    this.hireService.setHireDoc({
      file_name: this.photo.photo_name,
      files: this.photo.photo_content,
      type: type,
      id: type === 'hire' ? this.hireInfo.id : this.hireInfo.client_id
    }).then(() => {
        this.getHireInfoDocs();
      },
      () => {
        console.log('Ошибка при загрузке изображения');
      });
  }

  deleteHireDoc(type, file_name) {
    this.hireService.deleteHireDoc({
      file_name: file_name,
      type: type,
      id: type === 'hire' ? this.hireInfo.id : this.hireInfo.client_id
    }).then(() => {
        this.getHireInfoDocs();
      },
      () => {
        console.log('Ошибка при удалении изображения');
      });
  }

  selectTemplate(template) {
    const branch = this.branches.filter(item => item.val === this.hireInfo.branch_id)[0];
    const date_bron = moment(this.hireInfo.rent_start).format('DD.MM.YYYY h:mm');
    this.sms.templateId = template;
    if (template === 'bron') {
      this.sms.txt = 'Здравствуйте, это Стройка-Аренда.' +
        '\r\n' + 'Бронь ' + this.hireInfo.equipments.name + ' на ' + date_bron + ' .' +
        '\r\n' + 'Самовывоз ' + branch.address +
        '\r\n' + 'При себе иметь паспорт и СТС на автомобиль.';
    }
    if (template === 'email') {
      this.sms.txt = 'Здравствуйте, это Стройка-Аренда.' +
        '\r\n' + 'Наша почта: ' + branch.email +
        '\r\n' + 'Наш адрес: ' + branch.address;
    }
    if (template === 'prolongation') {
      this.sms.txt = 'Здравствуйте, прокат продлен ' + this.hireInfo.equipments.name + '.' +
        '\r\n' + 'С уважением, Стройка-Аренда.';
    }
    if (template === 'notification') {
      this.sms.txt = 'Здравствуйте, это Стройка-Аренда. Оборудование, которым Вы недавно интересовалось появилось в наличии - ' + this.hireInfo.equipments.name;
    }
  }

  changeSms(phone) {
    this.sms = {
      show: true,
      phone: phone,
      templateId: '',
      txt: ''
    };
  }

  sendSms() {
    this.clientInfoService.sendSms({
      phone: this.sms.phone,
      txt: this.sms.txt
    }).then(() => {
        this.sms = {show: false, phone: '', templateId: '', txt: ''};
      },
      (error) => {
        console.log('Ошибка при звонке: ', error);
      });
  }

  onChanged(increased: any, type: string, update = false) {
    this.hireInfo[type] = increased;

    if (update) {
      this.updateHire();
    }
  }

  onChanged2(increased: any, type: string) {
    this.currentPay[type] = increased;
  }
  onChangedPhone(increased: any, type: string) {
    this.whatsUpForm.phone = increased;
  }
}
