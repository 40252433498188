import {Component, DoCheck, OnChanges} from '@angular/core';
import {IncomeCallMessage} from './income_call';
import {HttpClient} from '@angular/common/http';
import {throwError as observableThrowError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {GlobalParamsMessage} from './global-params-message';
import {ClientInfoService} from '../client_info/clientInfo.service';

@Component({
  selector: 'app-income-call-alert',
  templateUrl: './income_call.component.html',
  styleUrls: ['./message_alert.scss']
})
export class IncomeCallComponent implements DoCheck {
  data: any;
  timerId = null;
  phone = null;
  showModal = false;
  sms_message = '';

  static handlerError(error: any = {}) {
    console.log('Ошибка после http-запроса', error);

    let text_error: string;
    if (typeof error.status !== 'undefined') {
      if (error.status === 0) {
        text_error = 'Неизвестная ошибка';
      } else if (error.status === 200) {
        text_error = error.body.json();
      } else if (error.status === 404) {
        text_error = 'Страница не найдена';
      } else if (error.status === 300) {
        text_error = 'Доступ запрещен';
      } else if (error.status === 500) {
        text_error = 'временно не доступен';
        throw {name: 'CRM', message: 'CRM временно не доступен'};
      } else {
        text_error = '';
      }
    } else {
      text_error = '';
    }

    return observableThrowError(text_error);
  }

  constructor(private incomeCallMessage: IncomeCallMessage,
              private http: HttpClient,
              private globalParamsMessage: GlobalParamsMessage,
              private clientInfoService: ClientInfoService) {
    this.data = incomeCallMessage;
  }
  openModalSendSms(phone) {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }
    this.showModal = true;
    this.phone = phone;
  }
  sendSms() {
    if (this.sms_message.length > 3) {
      this.clientInfoService.sendSmsFromIncomeCall({
        phone: this.phone,
        txt: this.sms_message
      }).then(() => {
          this.phone = null;
          this.showModal = false;
          this.globalParamsMessage.data = {title: 'Смс успешно отправлено!', type: 'success', body: ''};
        },
        (error) => {
          this.globalParamsMessage.data = {title: error.message, type: 'error', body: ''};
          console.error('There was an error!', error);
        });
    } else {
      this.globalParamsMessage.data = {title: 'Необходимо указать минимум 3 символа', type: 'error', body: ''};
    }
  }
  ngDoCheck() {
    if (this.data.data.type !== '') {
      if (this.timerId !== null) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.clear();
      }, 10000);
    }
  }
  clear() {
    this.data.data.type = '';
    this.data.data.title = '';
    this.data.data.body = '';
  }
}
