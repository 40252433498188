import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SettingsManagerPlanBonusService {
  managerPlan = [];

  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение плана менеджера бонуса
  public getManagerPlanBonus() {
    return new Promise((resolve, reject) => {
      if (this.managerPlan.length === 0) {
        this.httpService.prepareQuery('get-manager-plan-bonus', '')
          .then((result: InterFaceSettingsLevel[]) => {
              this.managerPlan = result;
              resolve(this.managerPlan);
            },
            (error) => {
              console.log('Ошибка при получении бонусов менеджера', error);
              reject();
            }
          );
      } else {
        resolve(this.managerPlan);
      }
    });
  }

  // обновление плана менеджера бонуса
  public updateManagerPlanBonus(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-manager-plan-bonus', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при обновлении бонусов менеджера', error);
            reject();
          }
        );
    });
  }

}
