import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class ProfileService {

  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }
  // обновленике профила
  public updateProfile(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-profile-info', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при загрузке изображения', error);
            reject();
          }
        );
    });
  }

  // обновленике профила
  public updatePassword(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-profile-password', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при смене пароля', error);
            reject();
          }
        );
    });
  }

  // изменение списка активных полей для таблицы
  public setProfilePhoto(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('set-profile-photo', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при загрузке изображения', error);
            reject();
          }
        );
    });
  }

  // Получение данных по профилю
  public getProfileInfo(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-profile-info', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении плана', error);
            reject();
          }
        );
    });
  }

  // Получение плана за другой год
  public getProfileInfoPlan(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-profile-info-plan', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении плана', error);
            reject();
          }
        );
    });
  }
}
