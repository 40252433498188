import {Component, OnInit} from '@angular/core';
import {ApplicationsService} from './applications.service';
import {DopParamsService} from '../../services/dopParams.service';
import {Router} from '@angular/router';
import {ApplicationsCreateService} from '../applications-create/applicationsCreate.service';
import {ClientService} from '../client/client.service';
import * as moment from 'moment';
import {DomSanitizer} from '@angular/platform-browser';
import {GlobalParams} from '../../storage/global-params';
import {GlobalParamsUser} from '../../storage/global-params-user';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
})
export class ApplicationsComponent implements OnInit {
  p = 1;
  deliveryStatus: InterFaceDopParamsColor[] = [];
  // отображение фильтра
  showFilters = false;
  // отображение фильтра
  showActiveFields = false;
  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};
  applicationsSource: InterFaceDopParams[] = [];
  branches: InterFaceDopParams[] = [];

  statusS = [];
  branchS = [];

  filters = {
    status: [],
    branch: [],
    date_start: '',
    date_end: '',
    date_create: '',
    user: '',
    comment: '',
    phone: '',
    client: '',
    equipments_count: '',
    equipments_name: '',
    sort: {},
    offset: 0,
    limit: 50
  };

  applications: InterFaceApplications[] = [];

  sortedData: InterFaceApplications[];
  hiresCount = null;

  dropdownSettingsStatus = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };

  processedType = 'processed';

  showSimilar = {
    data: {},
    show: false
  };
  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  ngOnInit() {
    this.applicationsService.getApplicationsStatus().then((data: InterFaceDopParamsColor[]) => {
        this.deliveryStatus = data;
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });

    this.applicationsCreateService.getApplicationsSource().then((data: InterFaceDopParams[]) => {
        this.applicationsSource = data;
      },
      (error) => {
        console.log('Ошибка при получении источников: ', error);
      });

    this.applicationsService.getApplicationsFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении списка полей заявки: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.getApplication();
  }

  constructor(private applicationsService: ApplicationsService,
              private dopParamsService: DopParamsService,
              private clientService: ClientService,
              private globalParams: GlobalParams,
              public globalParamsUser: GlobalParamsUser,
              private router: Router,
              public sanitizer: DomSanitizer,
              private applicationsCreateService: ApplicationsCreateService) {
  }

  // отображение фильтра
  changeShowFilters() {
    this.showFilters = !this.showFilters;
  }

  // отображение фильтра
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag;
    }
  }

  startTransition() {
    this.router.navigateByUrl('blank').then(() => {
      this.router.navigateByUrl('application-create');
    });
  }

  // изменение списка активных полей
  changeFields() {
    this.applicationsService.changeFields({data: this.activeFields}).then(() => {
        this.showActiveFields = false;
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }

  deleteApplicationDemand(id) {
    this.applicationsService.deleteApplicationDemand({id: id}).then(() => {
        this.getApplication();
      },
      () => {
        console.log('Ошибка при удалении');
      });
  }

  // изменение списка полей
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  // изменение статуса
  changeStatus(application) {
    this.applicationsService.updateApplicationsStatus({
      application_id: application.id,
      application_status: application.statusId
    }).then((data: any) => {
        this.clientService.insertClientInfo(data.client);
      },
      (error) => {
        console.log('Ошибка при изменении статуса у оборудования: ', error);
      });
  }

  getApplication() {
    if (this.processedType !== 'applications') {
      return;
    }
    this.applicationsService.getApplication({
      status: this.filters.status,
      branch: this.filters.branch,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      date_create: this.filters.date_create !== '' ? moment(this.filters.date_create).format().slice(0, 10) : '',
      user: this.filters.user,
      comment: this.filters.comment,
      phone: this.filters.phone,
      client: this.filters.client,
      equipments_count: this.filters.equipments_count,
      equipments_name: this.filters.equipments_name,
      sort: Object.keys(this.filters.sort).length !== 0,
      offset: this.filters.offset,
      limit: this.filters.limit
    }).then((data: { data: InterFaceApplications[], hiresCount: number }) => {
        this.sortedData = data.data.slice();
        this.hiresCount = data.hiresCount;
        this.applications = data.data;

        if (Object.keys(this.filters.sort).length !== 0) {
          this.sortData(this.filters.sort);
        }
      },
      (error) => {
        console.log('Ошибка при получении списка заявок: ', error);
      });
  }

  // очистка фильтра
  clearFilter() {
    this.filters = {
      status: [],
      branch: [],
      date_start: '',
      date_end: '',
      date_create: '',
      user: '',
      comment: '',
      phone: '',
      client: '',
      equipments_count: '',
      equipments_name: '',
      sort: {},
      offset: 0,
      limit: 50
    };

    this.getApplication();
  }

  applicationDetails(equipments_id, type) {
    if (type === 'app') {
      this.router.navigate(['/hire/' + equipments_id]);
    }
  }

  sortData(sort) {
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.sort = sort;
      this.getApplication();
    } else {
      const data = this.applications.slice();
      this.applications = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'client':
            return compare(a.client, b.client, isAsc);
          case 'phone':
            return compare(a.phone, b.phone, isAsc);
          case 'equipments_name':
            return compare(a.equipments_name, b.equipments_name, isAsc);
          case 'source':
            return compare(a.source, b.source, isAsc);
          case 'user':
            return compare(a.user, b.user, isAsc);
          case 'equipments_count':
            return compare(a.equipments_count, b.equipments_count, isAsc);
          case 'date_create':
            const date_create_a = new Date(a.date_create).getTime();
            const date_create_b = new Date(b.date_create).getTime();
            return compare(date_create_a, date_create_b, isAsc);
          case 'branch':
            return compare(a.branch, b.branch, isAsc);
          default:
            return 0;
        }
      });
    }
  }
  changeSimilar(data) {
    this.showSimilar = {
      show: true,
      data: data['similar']
    };

  }
  changePage(data) {
    this.p = data;
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.offset = this.p * 50 - 50;
      this.getApplication();
    }
  }

  changeFilter(data) {
    this.statusS = data;
    this.filters.status = this.statusS;
    this.getApplication();
  }

  changeFilterBranch(data) {
    this.branchS = data;
    this.filters.branch = this.branchS;
    this.getApplication();
  }

  deliveryStatusList(status) {
    if (status === 4) {
      return this.deliveryStatus.filter(item => item.val === 2 || item.val === 1 || item.val === 4);
    }

    if (status === 5) {
      return this.deliveryStatus.filter(item => item.val === 2 || item.val === 1 || item.val === 5);
    }

    return this.deliveryStatus;

  }

  changeCount(count) {
    this.filters.limit = count;
    this.getApplication();
  }

  onChanged(increased: any, type: string, update = false) {
    this.filters[type] = increased;

    if (update) {
      this.getApplication();
    }
  }
}

function compare(a: Date | number | string, b: Date | number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
