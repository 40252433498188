import {Component} from '@angular/core';

@Component({
  selector: 'app-check-client',
  templateUrl: './checkClient.html',
})
export class CheckClientComponent {

  constructor() {
  }
}
