import {Component} from '@angular/core';
import {SettingsManagerPlanBonusService} from './settingsManagerPlanBonus.service';

@Component({
  selector: 'app-settings-manager-plan-bonus',
  templateUrl: './settingsManagerPlanBonus.component.html',
})
export class SettingsManagerPlanBonusComponent {
  list: InterFaceManagerAward[] = [];

  constructor(private settingsManagerPlanBonusService: SettingsManagerPlanBonusService) {
    this.settingsManagerPlanBonusService.getManagerPlanBonus().then((data: InterFaceManagerAward[]) => {
        this.list = data;
      },
      (error) => {
        console.log('Ошибка при плана: ', error);
      });
  }

  // изменение значения
  changeItem(item) {
    const data = {id: item.id, percent: item.percent};
    this.settingsManagerPlanBonusService.updateManagerPlanBonus(data).then(() => {
      },
      (error) => {
        console.log('Ошибка при плана: ', error);
      });
  }
}
