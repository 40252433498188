import {Component} from '@angular/core';
import {SessionStorageService} from './storage/session-storage.service';
import {Router} from '@angular/router';
import {AuthService} from './components/auth/auth.service';
import {ClientService} from './components/client/client.service';
import {GlobalParamsUser} from './storage/global-params-user';
import {GlobalParams} from './storage/global-params';
import {HttpService} from './utils/http/http.service';
import {GlobalParamsPay} from './components/pay/global-params-pay';
import {GlobalPayList} from './components/pay_list/global-pay-list';
import {GlobalPayListClient} from './components/pay_list_client/global-pay-list-client';
import {GlobalApplicationListClient} from './components/application_list_client/global-application-list-client';
import {GlobalExtensionsList} from './components/extensions_list/global-extensions-list';
import {GlobalParamsClientChangeStatus} from './components/client_change_status/global-params-client-change-status';
import {GlobalClientChangeStatusList} from './components/client_change_status_list/global-client_change_status_list';
import {GlobalParamsRental} from './components/rental/global-params-rental';
import {GlobalParamsTame} from './components/tame/global-params-tame';
import {GlobalParamsComingLesa} from './components/coming_lesa/global-params-coming-lesa';
import {GlobalParamsConsumptionLesa} from './components/consumption_lesa/global-params-consumption_lesa';
import {GlobalParamsLesaHistory} from './components/lesa_history/global-params-lesa-history';
import {Title} from '@angular/platform-browser';
import {ApplicationsProcessedService} from './components/applicationsProcessed/applicationsProcessed.service';
import { Observable } from 'rxjs';
import { WebsocketService } from './websocket/websocket.service';
import { WS } from './websocket.events';
import {IncomeCallMessage} from './components/message_alert/income_call';
import {NotificationMessage} from './components/message_alert/notification';
import {SocketioService} from './socketio.service';

export interface IMessage {
  id: number;
  text: string;
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  private messages$: Observable<IMessage[]>;
  checkAuth: Boolean = this.sessionStorageService.pubId !== null;
  HiddenMenu = false;
  userType = this.globalParamsUser.type;
  loadGif = false;

  paramsPay: any;
  payList: any;
  payListClient: any;
  listClient: any;
  extensionList: any;
  clientChangeStatus: any;
  clientChangeStatusList: any;
  paramsRental: any;
  paramsTame: any;
  paramsComingLesa: any;
  paramsConsumptionLesa: any;
  paramsLesaHistory: any;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  processed = {
    hiresCount: 0,
    data: [],
  };

  constructor(private sessionStorageService: SessionStorageService,
              private authService: AuthService,
              public globalParamsUser: GlobalParamsUser,
              public globalParams: GlobalParams,
              public httpService: HttpService,
              private globalParamsPay: GlobalParamsPay,
              private globalPayList: GlobalPayList,
              private globalPayListClient: GlobalPayListClient,
              private globalApplicationListClient: GlobalApplicationListClient,
              private globalExtensionsList: GlobalExtensionsList,
              private globalParamsClientChangeStatus: GlobalParamsClientChangeStatus,
              private globalClientChangeStatusList: GlobalClientChangeStatusList,
              private globalParamsRental: GlobalParamsRental,
              private globalParamsTame: GlobalParamsTame,
              private globalParamsComingLesa: GlobalParamsComingLesa,
              private globalParamsConsumptionLesa: GlobalParamsConsumptionLesa,
              private globalParamsLesaHistory: GlobalParamsLesaHistory,
              private titleService: Title,
              private applicationsProcessedService: ApplicationsProcessedService,
              private router: Router,
              private wsService: WebsocketService,
              private clientService: ClientService,
              private incomeCallMessage: IncomeCallMessage,
              private socketService: SocketioService,
              private notificationMessage: NotificationMessage) {

    this.globalParams.getShortHire = 1;
    this.paramsPay = globalParamsPay;
    this.payList = globalPayList;
    this.payListClient = globalPayListClient;
    this.listClient = globalApplicationListClient;
    this.extensionList = globalExtensionsList;
    this.clientChangeStatus = globalParamsClientChangeStatus;
    this.clientChangeStatusList = globalClientChangeStatusList;
    this.paramsRental = globalParamsRental;
    this.paramsTame = globalParamsTame;
    this.paramsComingLesa = globalParamsComingLesa;
    this.paramsConsumptionLesa = globalParamsConsumptionLesa;
    this.paramsLesaHistory = globalParamsLesaHistory;


    this.wsService.on<IMessage[]>(WS.ON.MESSAGES)
      .subscribe((messages: IMessage[]) => {
        this.findClientByPhone(messages);
      });
    this.socketService.setupSocketConnection();

    this.socketService.getNotification.subscribe((data) => {
      this.notificationMessage.data = {
        title: 'Уведомление напоминание',
        type: 'LEAD_NOTIFICATION',
        body: data.text,
      };
    });

    this.sessionStorageService.authenticated.subscribe(item => {
      this.checkAuth = item;
    });

    this.httpService.loadGif.subscribe(item => {
      this.loadGif = item;
    });

    this.authService.getInfoSite().then((data: any) => {
        this.titleService.setTitle(data.title);
        this.globalParams.path = data.path;
        this.favIcon.href = data.favicon;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.checkAuthRedirect();
  }

  findClientByPhone(message) {
    if (message.event_type !== 1 || message.direction === 1) {
      return;
    }
    const phone = {
      phone: message.client_number
    };
    this.clientService.getClientByPhone(phone).then((data: any) => {
      let str = '';
      if (data.name.length > 0) {
        str += '<p><a href="https://t.me/' + message.client_number + '" target="_blank">Перейти в Telegram</a></p>';
        str += '<p><a href="https://wa.me/' + data.phone + '" target="_blank">Перейти в WhatsApp</a></p>';
        str += '<p><a href="/clients/' + data.id + '" target="_blank">Перейти в Карточку клиента</a></p>';
      } else {
        data.name = 'неизвестный';
        str += '<p><a href="https://t.me/' + message.client_number + '" target="_blank">Перейти в Telegram</a></p>';
        str += '<p><a href="https://wa.me/' + data.phone + '" target="_blank">Перейти в WhatsApp</a></p>';
      }
      this.incomeCallMessage.data = {
        title: 'Входящий звонок от ' + data.name + ' ' + data.phone,
        type: 'CALL_INCOME',
        body: str,
        phone: phone.phone,
      };
    });
  }
  checkAuthRedirect() {
    if (!this.checkAuth) {
      this.authService.getInfoSite().then((data: any) => {
          if (data.REDIRECT_URL) {
            window.location = data.REDIRECT_URL;
          }
          this.titleService.setTitle(data.title);
          this.globalParams.path = data.path;
          this.favIcon.href = data.favicon;
        },
        (error) => {
          console.log('Ошибка при получении филиалов: ', error);
        });
      this.router.navigate(['/']);
      clearInterval(this.globalParams.timerId);
    } else {
      if (location.pathname === '/') {
        this.router.navigate(['/main']);
      }

      this.authService.getUser().then(() => {
          console.log('Данные по клиенту успешно получены');
        },
        (error) => {
          console.log('Ошибка при получении данных по клиенту: ', error);
        });
    }
  }
}


