import {Injectable} from '@angular/core';
import {HttpService} from '../utils/http/http.service';
import {GlobalParams} from '../storage/global-params';

@Injectable()
export class DocumentService {

  constructor(private httpService: HttpService,
              public globalParams: GlobalParams) {
  }

  // печать договоров при создании проката
  public printDoc(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('print-doc', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при удалении проекта', error);
            reject();
          }
        );
    });
  }

  // печать договоров при создании проката
  public printDocLesa(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('print-doc-lesa', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при удалении проекта', error);
            reject();
          }
        );
    });
  }

  public getWord(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-doc', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при печати договора проекта', error);
            reject();
          }
        );
    });
  }
}


