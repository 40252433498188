import {Component, OnInit} from '@angular/core';
import {DopParamsService} from '../../services/dopParams.service';
import {EquipmentsService} from '../equipments/equipments.service';
import {Sort} from '@angular/material/sort';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {StockService} from '../stock/stock.service';
import {EquipmentsCreateMiniService} from '../equipments_create_mini/equipmentsCreateMini.service';
import {Router} from '@angular/router';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import * as moment from 'moment';
import {GlobalParams} from '../../storage/global-params';

@Component({
  selector: 'app-stock',
  templateUrl: './demand.component.html',
})
export class DemandComponent implements OnInit {
  p = 1;
  stocks: InterFaceStocks[] = [];

  equipmentsList: InterFaceEquipmentsDemandStock[] = [{
    id: null,
    name: '',
    stock: '',
    count_demand: null,
    user: '',
    client: '',
    coment: '',
    srok: '',
    branch: '',
    date_create: '',
    checked: false,
  }];

  // массив для объединения
  unification = [];

  // список названий
  unificationList = [];

  // показ формы для объединения
  showUnification = false;

  // тип отображения,заявки или оборудования
  type = 'eq';

  // отображение окна добавления нового оборудования
  addNewEquipments = {
    show: false,
  };

  filters: InterFaceFilterEquipmentsDemand = {
    like: '',
    stock: null,
    date_start: '',
    date_end: '',
    client_type: 0,
    offset: 0,
    limit: 50,
    sort: {}
  };

  sortedData: InterFaceEquipmentsDemandStock[];

  // название при объединении
  new_name = '';

  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};

  // показать фильтр
  showFilters = false;

  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  eqCount = null;

  demandModal = {
    show: false,
    data: []
  };

  constructor(private stockService: StockService,
              private dopParamsService: DopParamsService,
              private equipmentsService: EquipmentsService,
              private equipmentsCreateMiniService: EquipmentsCreateMiniService,
              private router: Router,
              private globalParamsMessage: GlobalParamsMessage,
              public globalParamsUser: GlobalParamsUser,
              public globalParams: GlobalParams) {

    this.equipmentsCreateMiniService.closeModalAddEq.subscribe(() => {
      this.addNewEquipments.show = false;
    });
  }

  ngOnInit() {
    this.dopParamsService.getStock().then((data: InterFaceStocks[]) => {
        this.stocks = data;
      },
      (error) => {
        console.log('Ошибка при получении складов: ', error);
      });

    this.equipmentsService.getEquipmentsDemandFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;
        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении списка полей оборудования: ', error);
      });

    this.getEquipments();
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag = 1;

      if (['user', 'client', 'coment', 'date_create', 'stock', 'srok', 'phone'].indexOf(value.code) !== -1 && this.type === 'eq') {
        this.activeFieldsTables[value.code] = value.flag = 0;
      }

      if (value.code === 'count_demand' && this.type === 'hire') {
        this.activeFieldsTables[value.code] = value.flag = 0;
      }
    }
  }

  changeClientType(data) {
    this.filters.client_type = data;
    this.getEquipments();
  }

  // получение списка оборудования
  getEquipments() {
    this.equipmentsService.getEquipmentsDemand({
      like: this.filters.like,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      type: this.type,
      stock: this.filters.stock,
      client_type: this.filters.client_type,
      offset: this.filters.offset,
      limit: this.filters.limit,
      sort: this.filters.sort
    }).then((data: { list: InterFaceEquipmentsDemandStock[], count: number }) => {
        this.equipmentsList = data.list;
        this.sortedData = this.equipmentsList.slice();
        this.showFilters = false;
        this.eqCount = data.count;
      },
      (error) => {
        console.log('Ошибка при получении списка оборудований: ', error);
      });
  }

  sortData(sort: any) {
    this.filters.sort = sort;
    this.getEquipments();
  }

  // изменение типа
  changeType(data) {
    this.type = data;
    this.getEquipments();
    this.changeShowFields();
  }

  // переход на детальную информацию
  demandDetails(id) {
    this.router.navigate(['/demand/' + id]);
  }

  // добавление / удаление из списка
  checkedChange(data) {
    const ss = this.unification.indexOf(data.id);
    if (ss === -1) {
      this.unification.push(data.id);
    } else {
      this.unification.splice(ss, 1);
    }
  }

  checkCheck(equipment_id) {
    return this.unification.find(item => item === equipment_id);
  }

  // показать объединение записей
  changeShowUnification() {
    this.unificationList = this.equipmentsList.filter(item => this.unification.indexOf(item.id) !== -1);
    this.showUnification = true;
  }

  // отправка на объединение
  sendUnification() {
    if (this.new_name === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать наименование', type: 'error', body: ''};
      return false;
    }
    // получение списка оборудования
    this.equipmentsService.sendUnification({
      unification: this.unification,
      new_name: this.new_name
    }).then(() => {
        this.new_name = '';
        this.unificationList = [];
        this.unification = [];
        this.showUnification = false;
        this.getEquipments();
      },
      (error) => {
        console.log('Ошибка при получении списка оборудований: ', error);
      });
  }

  // удаление оборудования
  deleteUnification() {
    if (this.type === 'eq') {
      this.equipmentsService.deleteUnification({
        unification: this.unification
      }).then(() => {
          this.new_name = '';
          this.unificationList = [];
          this.unification = [];
          this.showUnification = false;
          this.getEquipments();
        },
        (error) => {
          console.log('Ошибка при удалении списка оборудований: ', error);
        });
    } else {
      this.equipmentsService.deleteAppUnification({
        unification: this.unification
      }).then(() => {
          this.new_name = '';
          this.unificationList = [];
          this.unification = [];
          this.showUnification = false;
          this.getEquipments();
        },
        (error) => {
          console.log('Ошибка при удалении списка заявок: ', error);
        });
    }
  }

  clearFilter() {
    this.filters = {
      like: '',
      stock: null,
      date_start: '',
      date_end: '',
      client_type: 0,
      offset: 0,
      limit: 50,
      sort: {}
    };
  }

  changePage(data) {
    this.p = data;
    this.filters.offset = this.p * 50 - 50;
    this.getEquipments();
  }

  changeCount(count) {
    this.filters.limit = count;
    this.getEquipments();
  }

  changeDemandModal(data) {
    this.demandModal = {data: data.branches, show: true};
  }

  onChanged(increased: any) {
    this.filters.stock = increased;
  }
}

