import {Component} from '@angular/core';
import {SettingsManagerAwardService} from './settingsManagerAward.service';

@Component({
  selector: 'app-settings-manager-award',
  templateUrl: './settingsManagerAward.component.html',
})
export class SettingsManagerAwardComponent {
  list: InterFaceManagerAward[] = [];
  showChangeDetails = false;
  detailsInfo: InterFaceManagerAward = {
    id: '',
    sum: '',
    limit_sum: ''
  };

  constructor(private settingsManagerAwardService: SettingsManagerAwardService) {
    this.getManagerAward();
  }

  getManagerAward() {
    this.settingsManagerAwardService.getManagerAward().then((data: InterFaceManagerAward[]) => {
        this.list = data;
      },
      (error) => {
        console.log('Ошибка при плана: ', error);
      });
  }

  // изменение значения
  change() {
    const data = {id: this.detailsInfo.id, sum: this.detailsInfo.sum, limit_sum: this.detailsInfo.limit_sum};
    this.settingsManagerAwardService.updateManagerAward(data).then(() => {
        this.showChangeDetails = false;
        this.detailsInfo = {
          id: '',
          sum: '',
          limit_sum: ''
        };
        this.getManagerAward();
      },
      (error) => {
        console.log('Ошибка при плана: ', error);
      });
  }

  changeDetails(item) {
    this.showChangeDetails = true;
    this.detailsInfo = {
      id: item.id,
      sum: item.sum,
      limit_sum: item.limit_sum
    };
  }
}
