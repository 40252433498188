import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class ApplicationsBronService {
  applicationsFieldsList: InterFaceActiveFields[] = [];

  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение списка активных полей
  public getApplicationsBronFields() {
    return new Promise((resolve, reject) => {
      if (this.applicationsFieldsList.length === 0) {
        this.httpService.prepareQuery('get-applications-bron-field', '')
          .then((result: InterFaceActiveFields[]) => {
              this.applicationsFieldsList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей для заявок', error);
              reject();
            }
          );
      } else {
        resolve(this.applicationsFieldsList);
      }
    });
  }

  // изменение списка активных полей для таблицы
  public changeBronFields(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-applications-bron-field', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей', error);
            reject();
          }
        );
    });
  }

  // получение списка заявок
  public getApplicationsBron(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-applications-bron', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении заявок', error);
            reject();
          }
        );
    });
  }

  // получение списка заявок
  public clearApplicationsBron() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('clear-applications-bron', '')
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve();
          },
          (error) => {
            console.log('Ошибка при получении заявок', error);
            reject();
          }
        );
    });
  }

  // получение списка заявок
  public changeApplicationsBron(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-applications-bron', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при получении заявок', error);
            reject();
          }
        );
    });
  }

  // получение списка заявок
  public confirmBron(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('confirm-applications-bron', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при подтверждении брони', error);
            reject();
          }
        );
    });
  }
}
