import {Component, Input, OnInit} from '@angular/core';
import {EquipmentsService} from '../equipments/equipments.service';
import {GlobalParams} from '../../storage/global-params';
import {Router} from '@angular/router';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {FinanceService} from '../finance/finance.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {HttpService} from '../../utils/http/http.service';
import * as moment from 'moment';

@Component({
  selector: 'app-equipments-info',
  templateUrl: './equipments_info.component.html',
})
export class EquipmentsInfoComponent implements OnInit {
  @Input() equipmentsId = null;
  @Input() closeModal: Function;
  equipment: InterFaceInfoEquipments = {
    id: null,
    model: '',
    category_name: '',
    type_name: '',
    mark_name: '',
    stock: '',
    status_name: '',
    date_create: '',
    old_status: null,
    new_status: null,
    old_stock: null,
    new_stock: null,
    discount: null,
    type: null,
    category: null,
    tool_number: null,
    mark: null,
    selling: '',
    purchase_value: '',
    selling_price: '',
    price_per_day: '',
    photo: '',
    photo_alias: '',
    rentals: 0,
    repairs: 0,
    repairs_sum: '0',
    revenue: '0',
    profit: '0',
    degree_wear: null,
    payback_ratio: 0,
    power_energy: '',
    length: '',
    network_cord: '',
    specifications: [],
    power: '',
    frequency_hits: '',
    comment: '',
    confirmed: '',
    count: '',
    count_hire: '',
    count_left: '',
    count_lesa: null,
    change_history: [{
      date: '',
      new_params: '',
      old_params: '',
      type: '',
      reason: '',
      user: '',
    }],
    change_history_status: [{
      date: '',
      new_params: '',
      old_params: '',
      cashBox: '',
      sum: '',
      reason: '',
      user: '',
    }],
    change_history_status_count: 0,
    rent_start: '',
    rent_end: '',
    count_day: '',
    priceListId: null,
    price_1: '',
    price_2: '',
    price_3: '',
    price_4: '',
  };
  showAskModal = false;
  reason_change_status = moment().format('DD.MM.YYYY');
  showModal = '';
  cash_box = '';
  sale_amount = 0;
  currentTab = 'default';
  hireInfo = [];
  hireCount = 0;
  hirePage = 1;
  financeCashBox: InterFaceFinanceCashBox[];

  constructor(private equipmentsService: EquipmentsService,
              public globalParams: GlobalParams,
              private router: Router,
              public globalParamsUser: GlobalParamsUser,
              private financeService: FinanceService,
              private globalParamsMessage: GlobalParamsMessage,
              private httpService: HttpService) {
  }

  ngOnInit() {
    if (this.equipmentsId !== null) {
      this.equipmentsService.getEquipmentInfo({equipmentId: this.equipmentsId}).then((data: InterFaceInfoEquipments) => {
          this.equipment = data;
          this.getEqHires({
            client: '',
            id: data.id,
            offset: 1,
            status: 0,
            sum: '',
          });
        },
        (error) => {
          console.log('Ошибка при получении детальной информации по клиенту: ', error);
        });
    }

    this.financeService.getFinanceCashBOx().then((data: InterFaceFinanceCashBox[]) => {
        this.financeCashBox = data;
      },
      (error) => {
        console.log('Ошибка при получении касс: ', error);
      });
  }
  focusFunction(event) {
    if (event.target.value.length === 0) {
      event.target.value = moment().format('DD.MM.Y');
    }
  }
  changePageHire(data) {
    this.hirePage = data;
    const filter = {
      client: '',
      id: this.equipment.id,
      offset: data * 10 - 10,
      status: 0,
      sum: '',
    };
    this.getEqHires(filter);
  }
  changeTab(tab) {
    this.currentTab = tab;
  }
  /*
  focusOutFunction(event) {
    if (event.target.value === moment().format('DD.MM.Y')) {
        event.target.value = '';
    }
  }
  */

  equipmentDetails(index) {
    this.router.navigate(['/equipments/' + index]);
  }
  // Получение списка прокато оборудования
  public getEqHires(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-eq-hires', data)
        .then((result: any) => {
            this.hireInfo = result.data;
            this.hireCount = result.count;
          },
          (error) => {
            console.log('Ошибка при получении списка прокатов', error);
            reject(error);
          }
        );
    });
  }
  // копирование оборудования из выделенного списка
  copyEqList() {
    this.equipmentsService.copyEqList({
      list: [this.equipment.id]
    }).then(() => {
      },
      (error) => {
        console.log('Ошибка при копировании списка оборудований: ', error);
      });
  }

  changeCloseModal() {
    this.closeModal();
  }

  delete_eq() {
    this.equipmentsService.deleteEquipment({
      id: this.equipment.id
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Оборудование успешно удалено', type: 'success', body: ''};
      },
      (error) => {
        console.log('Ошибка при удалении оборудования: ', error);
      });
  }

  changeStatus() {
    this.equipmentsService.changeEquipmentsStatus({
      id: this.equipment.id,
      new_status: this.equipment.new_status,
      old_status: this.equipment.old_status,
      reason_change_status: this.reason_change_status,
      cash_box: this.cash_box,
      sale_amount: this.sale_amount,
      amount_repair: 0
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Изменения успешно внесены', type: 'success', body: ''};
        this.showModal = '';
      },
      (error) => {
        console.log('Ошибка при изменении оборудования: ', error);
      });
  }

  txtButton() {
    this.equipment.new_status = this.showModal === 'delete' ? 3 : 6;
    return this.showModal === 'delete' ? 'Списать' : 'Продать';
  }

  changeCommit() {
    this.equipmentsService.changeCommit({
      id: this.equipment.id,
      comment: this.equipment.comment
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Комментарий успешно изменен', type: 'success', body: ''};
        this.showModal = '';
      },
      (error) => {
        console.log('Ошибка при изменении оборудования: ', error);
      });
  }

  changePricePerDay() {
    this.equipmentsService.changePricePerDay({
      id: this.equipment.id,
      sum: this.equipment.price_per_day
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Сумма успешно изменена', type: 'success', body: ''};
        this.showModal = '';
      },
      (error) => {
        console.log('Ошибка при изменении оборудования: ', error);
      });
  }

  onChanged(data) {
    this.cash_box = data;
  }
}


