import {Component, OnInit} from '@angular/core';
import {GlobalParamsPay} from './global-params-pay';
import {ApplicationsService} from '../applications/applications.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {GlobalPayList} from '../pay_list/global-pay-list';
import {FinanceService} from '../finance/finance.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';
import {DopParamsService} from '../../services/dopParams.service';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
})
export class PayComponent implements OnInit {
  data = {
    data: {
      show: false,
      sum: '',
      cashBox: null,
      app_id: null,
      revertSum: false,
      branch: null
    }
  };
  currentDate = moment().format().slice(0, 16);
  financeCashBox: InterFaceFinanceCashBox[];
  showFinanceCashBox: InterFaceFinanceCashBox[] = [];
  revertSum = false;
  branches: InterFaceDopParams[] = [];

  checkPledge = false;
  checkDelivery = false;

  constructor(private globalParamsPay: GlobalParamsPay,
              private applicationsService: ApplicationsService,
              private globalParamsMessage: GlobalParamsMessage,
              private globalPayList: GlobalPayList,
              public globalParamsUser: GlobalParamsUser,
              private financeService: FinanceService,
              private dopParamsService: DopParamsService) {
    this.data = globalParamsPay;

    this.financeService.refreshCashBox.subscribe(() => {
      this.getFinanceCashBOx();
    });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
  }

  ngOnInit() {
    this.getFinanceCashBOx();
  }

  getFinanceCashBOx() {
    this.financeService.getFinanceCashBOx().then((data: InterFaceFinanceCashBox[]) => {
        this.financeCashBox = data;
        this.showFinanceCashBox = this.financeCashBox.filter(item => item.zalog === '0' && item.delivery === '0');
      },
      (error) => {
        console.log('Ошибка при получении касс: ', error);
      });
  }

  clear() {
    this.data.data = {show: false, sum: '', app_id: null, cashBox: null, revertSum: false, branch: null};
  }

  send() {
    if (this.globalParamsPay.data.sum === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать сумма', type: 'error', body: ''};
      return false;
    }

    if (this.globalParamsPay.data.cashBox === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }

    if (this.checkDelivery && this.checkPledge) {
      this.globalParamsMessage.data = {title: 'Нельзя выбрать и "залог" и "доставка"!', type: 'error', body: ''};
      return false;
    }

    const fCashBox = this.financeCashBox.filter(item => item.val === this.globalParamsPay.data.cashBox)[0];
    const fCashBoxTxt = fCashBox.name.split(' - ');

    let fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.zalog === '0' && item.delivery === '0');

    if (this.checkDelivery) {
      fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.delivery === '1');
    }

    if (this.checkPledge) {
      fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.zalog === '1');
    }

    if (fCashBoxCurrent.length === 0) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }
    this.globalParamsPay.data.cashBox = fCashBoxCurrent[0].val;

    this.data.data.show = false;
    if (this.data.data.app_id !== null) {
      this.applicationsService.addPay({
        sum: this.globalParamsPay.data.sum,
        cashBox: this.globalParamsPay.data.cashBox,
        app_id: this.globalParamsPay.data.app_id,
        revertSum: this.data.data.revertSum,
        currentDate: this.currentDate,
        branch: this.globalParamsPay.data.branch,
      }).then(() => {
          this.globalParamsMessage.data = {title: 'Оплата успешно добавлена', type: 'success', body: ''};
        },
        (error) => {
          console.log('Ошибка при добавлении нового платежа: ', error);
        });
    }
  }

  changeRevert() {
    this.data.data.revertSum = !this.data.data.revertSum;
  }

  onChanged(increased: any, type: string, update = false) {
    this.data.data[type] = increased;
  }
}
