import {Injectable} from '@angular/core';

@Injectable()
export class GlobalParams {
  showModalCreateUser = false;
  // 1-поиск, 2-получение данных для новой заявки, 3-остановка
  getShortHire = 1;
  // сообщения callibri или от администратора
  typeShortHire = '';
  // отображение левого меню при клике
  showLeftMenu = false;
  // глобальный филиал
  branch = {name: '', val: 0};
  path = '';
  // леса
  createAppLesa = false;
  // удаленная заявка
  createAppRemote = false;
  // поиск при звонках
  timerId = null;
  // создание проката по новому
  createAppNew = false;
  // информация по заявке с мобильника
  mobileInfo = '';
  // количество необработанных
  countHireProcessed = 0;
  showNotification = false;
}
