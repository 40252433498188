import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SettingsCarouselService {
  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // обновление премий менеджера
  public getSiteCarousel() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('info-images', '')
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении информации', error);
            reject();
          }
        );
    });
  }

  // обновление премий менеджера
  public updateSiteCarousel(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-info', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при обновлении информации', error);
            reject();
          }
        );
    });
  }

  // обновление премий менеджера
  public deleteSiteCarousel(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-info', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при удлаении информации', error);
            reject();
          }
        );
    });
  }

  // удаление картинки банера
  public deleteImageSiteCarousel(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-info-image', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при удлаении информации', error);
            reject();
          }
        );
    });
  }
}
