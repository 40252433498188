import {Component} from '@angular/core';
import {SettingsClientBonusPercentService} from './settingsClientBonusPercent.service';

@Component({
  selector: 'app-settings-client-bonus-percent',
  templateUrl: './settingsClientBonusPercent.component.html',
})
export class SettingsClientBonusPercentComponent {
  list: InterFaceClientBonusPercent[] = [];
  newList: InterFaceClientBonusPercent = {
    id: null,
    sum: '',
    percent: ''
  };

  constructor(private settingsClientBonusService: SettingsClientBonusPercentService) {
    this.getBonusList();
  }

  getBonusList() {
    this.settingsClientBonusService.getBonusPercentList().then((data: InterFaceClientBonusPercent[]) => {
        this.list = data;
      },
      (error) => {
        console.log('Ошибка', error);
      });
  }

  deleteBonusList(id) {
    this.settingsClientBonusService.deleteBonusPercentList({'id': id}).then(() => {
        this.getBonusList();
      },
      (error) => {
        console.log('Ошибка', error);
      });
  }

  addBonusList() {
    this.settingsClientBonusService.addBonusPercentList({
      'id': this.newList.id,
      'sum': this.newList.sum,
      'percent': this.newList.percent,
    }).then(() => {
        this.clear();
        this.getBonusList();
      },
      (error) => {
        console.log('Ошибка', error);
      });
  }

  changeItems(data) {
    this.newList = {
      id: data.id,
      sum: data.sum,
      percent: data.percent,
    };
  }

  clear() {
    this.newList = {
      id: null,
      sum: '',
      percent: '',
    };
  }

  showButton() {
    return this.newList.id === null ? 'Добавить' : 'Изменить';
  }
}
