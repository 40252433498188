import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {GlobalParams} from '../../storage/global-params';

@Injectable()
export class ShortHireService {
  refreshShortHire: EventEmitter<any> = new EventEmitter(false);
  list: InterFaceNotifications[] = [];
  currentList: InterFaceNotification = {
    id: null,
    branch: null,
    source: null,
    client_phone: '',
    client_id: null,
    client_fio: '',
    client_email: '',
    comment: '',
    type: '',
  };

  constructor(private httpService: HttpService,
              private globalParams: GlobalParams,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение новыъ заявок
  public getShortApp(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-short-application', data)
        .then((result: { data: any, count: number }) => {
            if (this.list.length !== result.data.length) {
              //this.globalParamsMessage.data = {title: 'У вас новая заявка', type: 'success', body: ''};
            }

            this.list = result.data;
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка оборудования', error);
            reject(error);
          }
        );
    });
  }

  // получение новыъ заявок
  public sendCancelHire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('cancel-short-hire', data)
        .then((result: any) => {
            this.refreshShortHire.emit(true);
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при отмене заявки', error);
            reject(error);
          }
        );
    });
  }

  // получение новыъ заявок
  public setCurrentList(notifications) {
    return new Promise((resolve) => {
      this.currentList = notifications;

      resolve('');
    });
  }

  // получение новыъ заявок
  public getCurrentList() {
    return new Promise((resolve) => {
      resolve(this.currentList);
    });
  }
}
