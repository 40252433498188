import {Component} from '@angular/core';
import {SettingsOrganizationService} from './settingsOrganization.service';

@Component({
  selector: 'app-settings-organization',
  templateUrl: './settingsOrganization.component.html',
})
export class SettingsOrganizationComponent {
  organizationInfo: InterFaceSettingsOrganization = {
    ur_name: '',
    inn: '',
    kpp: '',
    kr: '',
    rr: '',
    bik: '',
    name_bank: '',
    ur_address: '',
    start_text: ''
  };

  constructor(private settingsOrganizationService: SettingsOrganizationService) {
    this.getOrganizationInfo();
  }

  getOrganizationInfo() {
    this.settingsOrganizationService.getOrganizationInfo().then((data: InterFaceSettingsOrganization) => {
        this.organizationInfo = data;
      },
      (error) => {
        console.log('Ошибка при получении: ', error);
      });
  }

  setOrganizationInfo() {
    this.settingsOrganizationService.setOrganizationInfo({
      ur_name: this.organizationInfo.ur_name,
      inn: this.organizationInfo.inn,
      kpp: this.organizationInfo.kpp,
      kr: this.organizationInfo.kr,
      rr: this.organizationInfo.rr,
      bik: this.organizationInfo.bik,
      name_bank: this.organizationInfo.name_bank,
      ur_address: this.organizationInfo.ur_address,
      start_text: this.organizationInfo.start_text
    }).then(() => {
      },
      (error) => {
        console.log('Ошибка при обновлении: ', error);
      });
  }
}
