import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class ClientInfoService {

  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение детальной информации по клиенту
  public getClientInfo(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-client-info', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении детальной информации по клиенту', error);
            reject();
          }
        );
    });
  }

  // звонок
  public callMoiZvonki(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('call-moi-zvonki', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при звонке', error);
            reject();
          }
        );
    });
  }

  // отправка смс
  public sendSms(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('send-sms-to-client', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при звонке', error);
            reject();
          }
        );
    });
  }
  // отправка смс с модалки
  public sendSmsFromIncomeCall(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('sms-from-income-call', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при звонке', error);
            reject();
          }
        );
    });
  }
  //получение списка звонков клиента
  public getRecording(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-recording', data)
        .then((result) => {
          resolve(result);
        },
        (error) => {
          console.log('Ошибка при звонке', error);
          reject();
        }
        );
    });
  }

  // звонок
  public changeClientCreateApp(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-client-create-app', data)
        .then(() => {
            this.globalParamsMessage.data = {
              title: 'Клиент успешно изменен',
              type: 'success',
              body: ''
            };
            resolve('');
          },
          (error) => {
            console.log('Ошибка при звонке', error);
            reject();
          }
        );
    });
  }

  public confirmationCheck(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-client-confirmation', data)
        .then(() => {
            this.globalParamsMessage.data = {
              title: 'Клиент успешно изменен',
              type: 'success',
              body: ''
            };
            resolve('');
          },
          (error) => {
            console.log('Ошибка при звонке', error);
            reject();
          }
        );
    });
  }

  public saveRecording(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('save-recording', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка', error);
            reject();
          }
        );
    });
  }
}
