import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
})
export class SaleComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {

  }
}
