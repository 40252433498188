import {Component, OnInit} from '@angular/core';
import {DopParamsService} from '../../services/dopParams.service';
import {EquipmentsService} from '../equipments/equipments.service';
import {ReportService} from '../reports/report.service';
import {Sort} from '@angular/material/sort';
import {AuthService} from '../auth/auth.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';

@Component({
  selector: 'app-report-eq',
  templateUrl: './report_eq.component.html',
})
export class ReportEqComponent implements OnInit {
  p = 1;
  branches: InterFaceDopParams[] = [];
  equipments: InterFaceReportEquipment[] = [];
  equipmentsTypeList: InterFaceDopParams[] = [];
  equipmentsCategoryList: InterEquipmentsCategory[] = [];
  sortedData: InterFaceReportEquipment[];
  filters = {
    branch: [],
    typeEq: 1,
    date_start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    date_end: moment().format('YYYY-MM-DD'),
    equipmentsCategory: 0,
    equipmentsType: 0,
    sort: 'desc',
    name: '',
    revenue: '',
    count_hire: '',
    profit: '',
    payback_ratio: '',
    selling: '',
    selling_price: '',
    degree_wear: '',
    repairs: '',
    repairs_sum: '',
  };

  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};
  // отображение активных полей
  showActiveFields = false;

  constructor(private reportService: ReportService,
              private dopParamsService: DopParamsService,
              private authService: AuthService,
              public globalParamsUser: GlobalParamsUser,
              private equipmentsService: EquipmentsService) {


    this.getBranch();
  }

  ngOnInit() {
    this.reportService.getReportsFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении списка полей оборудования: ', error);
      });

    this.equipmentsService.getEquipmentsCategory().then((data: InterEquipmentsCategory[]) => {
        this.equipmentsCategoryList = data;

        this.equipmentsTypeList = this.equipmentsCategoryList[0].type;
      },
      (error) => {
        console.log('Ошибка при получении списка категорий оборудования: ', error);
      });
  }

  getBranch() {
    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = [{'val': 0, name: 'Все'}].concat(data);;
        this.filters.branch = this.globalParamsUser.branch.val;
        this.getFilterEquipments();
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
  }

  // список клиентов
  changeTypeFilter(data) {
    this.filters.sort = this.filters.typeEq === data && this.filters.sort === 'desc' ? 'asc' : 'desc';
    this.filters.typeEq = data;
    this.getFilterEquipments();
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag;
    }
  }

  // получение списка товаров
  getFilterEquipments() {
    this.reportService.getEquipments({
      typeEq: this.filters.typeEq,
      branch: this.filters.branch,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      equipmentsCategory: this.filters.equipmentsCategory,
      equipmentsType: this.filters.equipmentsType,
      sort: this.filters.sort,
      name: this.filters.name,
      revenue: this.filters.revenue,
      count_hire: this.filters.count_hire,
      profit: this.filters.profit,
      payback_ratio: this.filters.payback_ratio,
      selling: this.filters.selling,
      selling_price: this.filters.selling_price,
      degree_wear: this.filters.degree_wear,
      repairs: this.filters.repairs,
      repairs_sum: this.filters.repairs_sum,
    }).then((data: InterFaceReportEquipment[]) => {
        this.equipments = data;
        this.sortedData = this.equipments.slice();
      },
      (error) => {
        console.log('Ошибка при получении списка оборудования: ', error);
      });
  }

  // отображение фильтра
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }

  // изменение списка активных полей
  changeFields() {
    this.reportService.changeFields({data: this.activeFields}).then(() => {
        this.showActiveFields = false;
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }

  // изменение прав у нового пользователя
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  sortData(sort: Sort) {
    const data = this.equipments.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.equipments = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'name_category':
          return compare(a.name_category, b.name_category, isAsc);
        case 'name_type':
          return compare(a.name_type, b.name_type, isAsc);
        case 'revenue':
          return compare(a.revenue, b.revenue, isAsc);
        case 'count_hire':
          return compare(a.count_hire, b.count_hire, isAsc);
        case 'profit':
          return compare(a.profit, b.profit, isAsc);
        case 'payback_ratio':
          return compare(a.payback_ratio, b.payback_ratio, isAsc);
        case 'selling':
          return compare(a.selling, b.selling, isAsc);
        case 'selling_price':
          return compare(a.selling_price, b.selling_price, isAsc);
        case 'degree_wear':
          return compare(a.degree_wear, b.degree_wear, isAsc);
        case 'repairs':
          return compare(a.repairs, b.repairs, isAsc);
        case 'repairs_sum':
          return compare(a.repairs_sum, b.repairs_sum, isAsc);
        case 'branch':
          return compare(a.branch, b.branch, isAsc);
        default:
          return 0;
      }
    });
  }

  onChanged(increased: any, type: string, update = false) {
    console.log('START THUNDER', increased);
    this.filters[type] = increased;

    if (update) {
      this.getFilterEquipments();
    }
  }

  // отдельный select для категорий
  changeCategory(increased: any, type: string, update = false) {
    this.filters[type] = increased;
    if (increased === '') {
      this.filters.equipmentsType = 0;
    } else {
      const arr = this.equipmentsCategoryList.filter(item => item.val === increased);
      this.equipmentsTypeList = arr[0].type;
    }

    this.getFilterEquipments();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
