import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalParams} from '../../storage/global-params';
import {NotificationsService} from '../notifications/notifications.service';

@Component({
  selector: 'app-check-calls-modal',
  templateUrl: './checkCallsModal.html',
})
export class CheckCallsModalComponent {
  list: any = [];
  p = 1;
  count = null;
  offset = 0;

  constructor(private router: Router,
              private notificationsService: NotificationsService,
              public globalParams: GlobalParams) {
    this.getShort();
  }

  getShort() {
    this.notificationsService.getApplicationProcessed({
      branch: '',
      date_start: '',
      date_end: '',
      sort: {},
      offset: 0,
    }).then((data: { data: InterFaceApplicationsProcessed[], hiresCount: number }) => {
        this.globalParams.countHireProcessed = data.hiresCount;

        if (this.count === null || data.hiresCount !== this.count) {
          const arr = this.list.map(item => item.id);
          this.count = data.hiresCount;
          const txt = data.data.filter(item => arr.indexOf(item.id) === -1);

          if (txt.length > 0) {
            this.notificationsService.notifSet(`фио: ${txt[0].client_fio}\nтелефон: ${txt[0].client_phone}`);
          }
        }

        this.list = data.data;
      },
      (error) => {
        console.log('Ошибка при получении списка заявок: ', error);
      });
  }

  selectNotifications() {
    this.globalParams.getShortHire = 2;
    this.globalParams.showNotification = false;
    this.router.navigateByUrl('/application');
  }

  changePage(data) {
    this.p = data;
    this.offset = this.p * 50 - 50;
    this.getShort();
  }
}
