import {Component, OnInit} from '@angular/core';
import {ReportService} from './report.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {DopParamsService} from '../../services/dopParams.service';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
})
export class ReportComponent implements OnInit {
  widgets: InterFaceReportWidget[] = [];
  filter = 1;
  branches: InterFaceDopParams[] = [];
  telegram = {
    show: false,
    branch: []
  };

  dropdownSettingsStatus = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };

  branchS = [];
  branchTmp = [];

  showSendReportTelegram = false;

  constructor(private reportService: ReportService,
              public globalParamsUser: GlobalParamsUser,
              private dopParamsService: DopParamsService,
              private authService: AuthService) {

    this.authService.getInfoSiteSecond().then((data: any) => {
        this.showSendReportTelegram = data.report_telegram===true;
      },
      (error) => {
        console.log('Ошибка при получене настроек сайта: ', error);
      });
  }

  ngOnInit() {
    this.reportService.getWidgets().then((data: any) => {
        this.widgets = data;
      },
      (error) => {
        console.log('Ошибка при получении списка виджетов: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
  }

  changeFilterBranch() {
    if (JSON.stringify(this.branchS) !== JSON.stringify(this.branchTmp)) {
      this.branchTmp = this.branchS;
      this.telegram.branch = this.branchS.map(item => item.val);
    }
  }

  sendReportTelegram(check) {
    let ob = {branch: []};
    if (check) {
      ob = {branch: this.telegram.branch};
    }
    this.reportService.sendReportTelegram(ob).then(() => {
        this.telegram.show = false;
      },
      (error) => {
        this.telegram.show = false;
        console.log('Ошибка при отправке: ', error);
      });
  }
}
