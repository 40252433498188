import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
})
export class SelectComponent implements OnChanges {
  @Input() lists: { val: number, name: string }[];
  @Input() status: any;
  @Input() class = '';
  @Input() backgroundColor = '';
  @Input() disabled: any = false;
  @Input() singleSelection: any = true;
  @Input() allowSearchFilter = false;
  @Output() inChanged = new EventEmitter<any>();

  model: any;

  dropdownSettings = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
    singleSelection: true,
    closeDropDownOnSelection: false,
    allowSearchFilter: true,
    searchPlaceholderText: 'Поиск'
  };

  constructor() {
  }

  ngOnChanges() {
    this.dropdownSettings.singleSelection = this.singleSelection;
    this.dropdownSettings.allowSearchFilter = this.allowSearchFilter;
    this.dropdownSettings.closeDropDownOnSelection = this.singleSelection === true;
    if (typeof this.lists !== 'undefined' && typeof this.status !== 'undefined') {
      this.model = this.lists.filter(item => item.val === this.status);
    }
  }

  changeFilter() {
    if (typeof this.model !== 'undefined') {
      if (this.model.length === 0) {
        if (this.singleSelection) {
          this.inChanged.emit('');
        } else {
          this.inChanged.emit([]);
        }
      } else {
        if (this.singleSelection) {
          this.inChanged.emit(this.model[0].val);
        } else {
          const data = this.model.map(item => +item.val);
          this.inChanged.emit(data);
        }
      }
    }
  }
}
