import {Injectable} from '@angular/core';

@Injectable()
export class IncomeCallMessage {
  data = {
    title: '',
    body: '',
    type: '',
    phone: ''
  };
}
