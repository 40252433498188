import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {ShortHireService} from '../short_hire/short_hire.service';

@Injectable()
export class ApplicationsCreateLesaService {
  applicationsTypeLeaseList: InterFaceDopParams[] = [];

  constructor(private httpService: HttpService,
              private shortHireService: ShortHireService) {
  }

  // получение списка типов аренды
  public getApplicationsTypeLease() {
    return new Promise((resolve, reject) => {
      if (this.applicationsTypeLeaseList.length === 0) {
        this.httpService.prepareQuery('get-applications-type-lease', '')
          .then((result: InterFaceDopParams[]) => {
              this.applicationsTypeLeaseList = result;
              resolve(this.applicationsTypeLeaseList);
            },
            (error) => {
              console.log('Ошибка при получении списка типов аренды заявки', error);
              reject();
            }
          );
      } else {
        resolve(this.applicationsTypeLeaseList);
      }
    });
  }

  // создание заявки
  public addApplication(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('add-application', data)
        .then((result) => {
            this.shortHireService.refreshShortHire.emit(true);
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при добавлении новой заявки', error);
            reject();
          }
        );
    });
  }
}
