import {Component, OnInit} from '@angular/core';
import {DopParamsService} from '../../services/dopParams.service';
import {ReportService} from '../reports/report.service';
import {Sort} from '@angular/material/sort';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';

@Component({
    selector: 'app-report-marketing',
    templateUrl: './report_marketing.component.html',
})
export class ReportMarketingComponent implements OnInit {
    p = 1;
    branches: InterFaceDopParams[] = [];
    marketings: InterFaceReportMarketing[] = [];
    sortedData: InterFaceReportMarketing[] = [];

    filters = {
        branch: 0,
        typeEq: 1,
        date_start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        sort: 'desc'
    };

    // список активных полей
    activeFields: InterFaceActiveFields[] = [];
    // список активных полей
    activeFieldsTables = {};
    // отображение активных полей
    showActiveFields = false;

    constructor(private reportService: ReportService,
                public globalParamsUser: GlobalParamsUser,
                private dopParamsService: DopParamsService) {
    }

    ngOnInit() {
        this.reportService.getReportsMarketingFields().then((data: InterFaceActiveFields[]) => {
                this.activeFields = data;

                this.changeShowFields();
            },
            (error) => {
                console.log('Ошибка при получении списка полей оборудования: ', error);
            });

        this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
                this.branches = [{val: 0, name: 'Все'}].concat(data);

                if (this.globalParamsUser.type !== 1) {
                    this.filters.branch = this.globalParamsUser.branch.val;
                }

                this.getFilterEquipments();
            },
            (error) => {
                console.log('Ошибка при получении филиалов: ', error);
            });
    }

    // получение списка товаров
    getFilterEquipments() {
        this.reportService.getReportMarketing({
            typeEq: this.filters.typeEq,
            branch: this.filters.branch,
            date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
            date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
            sort: this.filters.sort
        }).then((data: InterFaceReportMarketing[]) => {
                this.marketings = data;
                this.sortedData = this.marketings.slice();
            },
            (error) => {
                console.log('Ошибка при получении списка оборудования: ', error);
            });
    }

    // список клиентов
    changeTypeFilter(data) {
        this.filters.sort = this.filters.typeEq === data && this.filters.sort === 'desc' ? 'asc' : 'desc';

        this.filters.typeEq = data;
        this.getFilterEquipments();
    }

    // изменение отображений записекй у полей
    changeShowFields() {
        for (const value of this.activeFields) {
            this.activeFieldsTables[value.code] = value.flag;
        }
    }

    // отображение фильтра
    changeActiveFields() {
        this.showActiveFields = !this.showActiveFields;
    }

    // изменение списка активных полей
    changeFields() {
        this.reportService.changeReportsMarketingFields({data: this.activeFields}).then(() => {
                this.showActiveFields = false;
            },
            () => {
                console.log('Ошибка при изменение списка отображаемых полей');
            });
    }

    // изменение прав у нового пользователя
    changeCheckbox(i) {
        this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
        this.changeShowFields();
    }

    sortData(sort: Sort) {
        const data = this.marketings.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }

        this.marketings = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'type':
                    return compare(a.type, b.type, isAsc);
                case 'count_app':
                    return compare(a.count_app, b.count_app, isAsc);
                case 'count_hire':
                    return compare(a.count_hire, b.count_hire, isAsc);
                case 'revenue':
                    return compare(a.revenue, b.revenue, isAsc);
                case 'expenses':
                    return compare(a.expenses, b.expenses, isAsc);
                case 'profit':
                    return compare(a.profit, b.profit, isAsc);
                default:
                    return 0;
            }
        });
    }

    onChanged(increased: any) {
        this.filters.branch = increased;
        this.getFilterEquipments();
    }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
