import {Component, OnInit} from '@angular/core';
import {DopParamsService} from '../../services/dopParams.service';
import {ReportService} from '../reports/report.service';
import {Sort} from '@angular/material/sort';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';

@Component({
  selector: 'app-report-hire',
  templateUrl: './report_hire.component.html',
})
export class ReportHireComponent implements OnInit {
  p = 1;
  branches: InterFaceDopParams[] = [];
  hireList: InterFaceReportHire [] = [];
  sortedData: InterFaceReportHire [] = [];
  filters = {
    branch: 0,
    date_start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    date_end: moment().format('YYYY-MM-DD'),
    typeEq: 1,
    sort: 'desc'
  };

  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};
  // отображение активных полей
  showActiveFields = false;

  constructor(private reportService: ReportService,
              public globalParamsUser: GlobalParamsUser,
              private dopParamsService: DopParamsService) {
  }

  ngOnInit() {
    this.reportService.getReportsHireFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении списка полей оборудования: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = [{val: 0, name: 'Все'}].concat(data);

        if (this.globalParamsUser.type !== 1) {
          this.filters.branch = this.globalParamsUser.branch.val;
        }
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.getFilterEquipments();
  }

  onChanged(data) {
    this.filters.branch = data;
    this.getFilterEquipments();
  }

  // получение списка товаров
  getFilterEquipments() {
    this.reportService.getReportHire({
      branch: this.filters.branch,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      typeEq: this.filters.typeEq,
      sort: this.filters.sort
    }).then((data: InterFaceReportHire[]) => {
        this.hireList = data;
        this.sortedData = this.hireList.slice();
      },
      (error) => {
        console.log('Ошибка при получении списка оборудования: ', error);
      });
  }

  changeTypeFilter(data) {
    this.filters.sort = this.filters.typeEq === data && this.filters.sort === 'desc' ? 'asc' : 'desc';

    this.filters.typeEq = data;
    this.getFilterEquipments();
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag;
    }
  }

  // отображение фильтра
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }

  // изменение списка активных полей
  changeFields() {
    this.reportService.changeReportsHireFields({data: this.activeFields}).then(() => {
        this.showActiveFields = false;
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }

  // изменение прав у нового пользователя
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  sortData(sort: Sort) {
    const data = this.hireList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.hireList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'branch':
          return compare(a.branch, b.branch, isAsc);
        case 'demand':
          return compare(a.demand, b.demand, isAsc);
        case 'learned':
          return compare(a.learned, b.learned, isAsc);
        case 'hire':
          return compare(a.hire, b.hire, isAsc);
        case 'all':
          return compare(a.all, b.all, isAsc);
        case 'conversion_a':
          return compare(a.conversion_a, b.conversion_a, isAsc);
        case 'conversion_o':
          return compare(a.conversion_o, b.conversion_o, isAsc);
        case 'refused':
          return compare(a.conversion_o, b.conversion_o, isAsc);
        case 'processed':
          return compare(a.processed, b.processed, isAsc);
        case 'bron':
          return compare(a.bron, b.bron, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
