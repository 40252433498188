import {Component} from '@angular/core';
import {GlobalParamsLesaHistory} from './global-params-lesa-history';
import {ApplicationsService} from '../applications/applications.service';
import {DopParamsService} from '../../services/dopParams.service';

@Component({
  selector: 'app-lesa-history',
  templateUrl: './lesa_history.component.html',
})
export class LesaHistoryComponent {
  data: any;
  listCount = 0;
  p = 1;
  lists: InterFaceLesaHistory[] = [];
  offset = 0;

  stocks: InterFaceDopParams[] = [];
  stocksS = [];
  stocksSTmp = [];
  stocksList = [];

  dropdownSettingsStatusLesa = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };

  constructor(private globalParamsLesaHistory: GlobalParamsLesaHistory,
              private applicationsService: ApplicationsService,
              private dopParamsService: DopParamsService) {
    this.data = globalParamsLesaHistory;

    this.getHistory();

    this.dopParamsService.getStock().then((data: InterFaceDopParams[]) => {
        this.stocks = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
  }


  getHistory() {
    this.applicationsService.getLesaHistory({
      offset: this.offset,
      branch: this.stocksList
    }).then((data: { data: InterFaceLesaHistory[], count: number }) => {
        this.lists = data.data;
        this.listCount = data.count;
      },
      (error) => {
        console.log('Ошибка при добавлении лесов: ', error);
      });
  }

  clear() {
    this.data.show = false;
  }

  changePage(data) {
    this.p = data;
    this.offset = this.p * 50 - 50;
    this.getHistory();
  }

  changeFilterBranch(val) {
    this.stocksList = val;
    this.getHistory();
  }
}
