import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';

@Injectable()
export class MainService {
  checkChangeBranch: EventEmitter<any> = new EventEmitter(false);

  constructor(private httpService: HttpService) {
  }

  // поиск и получение клиентов
  public getData(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-main-info', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении информации для рабочего стола', error);
            reject(error);
          }
        );
    });
  }
   // получение детальних данних по городам
   public getDataToBratchTable(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-main-info-by-branches', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении информации для рабочего стола', error);
            reject(error);
          }
        );
    });
  }

  // получение выручки
  public getRevenue(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-header-revenue', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении выручки', error);
            reject(error);
          }
        );
    });
  }

  // список по горадам по загрузке оборудования
  public getLoadingEq() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-loading-eq', '')
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении', error);
            reject(error);
          }
        );
    });
  }

  // список по горадам по износу оборудования
  public getLoadingWear() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-loading-wear', '')
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении', error);
            reject(error);
          }
        );
    });
  }

  // получение результатов по поиску
  public globalSearch(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-global-search', data)
        .then((result: any) => {
            resolve(result.data);
          },
          (error) => {
            console.log('Ошибка при получении результатов по поиску', error);
            reject(error);
          }
        );
    });
  }

  public changeBranch() {
    this.checkChangeBranch.emit(true);
  }
}
