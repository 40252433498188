import {Component} from '@angular/core';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {Router} from '@angular/router';
import {ApplicationsCreateLesaService} from '../applications-create-lesa/applicationsCreateLesa.service';
import {DopParamsService} from '../../services/dopParams.service';
import {GlobalParams} from '../../storage/global-params';

@Component({
  selector: 'app-applications-create-remote',
  templateUrl: './applicationsCreateRemote.component.html',
})

export class ApplicationsCreateRemoteComponent {
  application: InterFaceNewApplicationRemote = {
    branch: 0,
    comment: '',
    client_phone: '',
  };

  branches: InterFaceDopParams[] = [];

  constructor(private applicationsCreateLesaService: ApplicationsCreateLesaService,
              private globalParamsMessage: GlobalParamsMessage,
              private dopParamsService: DopParamsService,
              public globalParams: GlobalParams,
              private router: Router) {

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

  }

  // создание заявки
  addApplication() {
    if (this.application.branch === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать филиал', type: 'error', body: ''};
      return false;
    }

    if (this.application.comment === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать комментарий', type: 'error', body: ''};
      return false;
    }

    if (this.application.client_phone === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать телефон', type: 'error', body: ''};
      return false;
    }

    this.applicationsCreateLesaService.addApplication({
      comment: this.application.comment,
      branch: this.application.branch,
      client_phone: this.application.client_phone,
      status: 9
    }).then((result: any) => {
        this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
        this.router.navigate(['/application']);
      },
      (error) => {
        console.log('Ошибка при добавлении удаленной заявки: ', error);
      });
  }

  onChanged(increased: any) {
    this.application.branch = increased;
  }
}
