import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SettingsManagerAwardService {
  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение премий менеджера бонуса
  public getManagerAward() {
    return new Promise((resolve, reject) => {
        this.httpService.prepareQuery('get-manager-award', '')
          .then((result: InterFaceManagerAward[]) => {
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении премий менеджера', error);
              reject();
            }
          );
    });
  }

  // обновление премий менеджера
  public updateManagerAward(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-manager-award', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при обновлении премий менеджера', error);
            reject();
          }
        );
    });
  }

}
