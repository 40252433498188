import {Component, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {SessionStorageService} from '../../storage/session-storage.service';
import {AuthService} from '../auth/auth.service';
import {DopParamsService} from '../../services/dopParams.service';
import {MainService} from '../main/main.service';
import {ApplicationsService} from '../applications/applications.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {GlobalParams} from '../../storage/global-params';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent {
    branches: InterFaceDopParams[] = [];
    currentBranches: InterFaceDopParams[] = [{name: 'Все города', val: 0}];
    branch = {name: '', val: 0};
    revenue = '0';
    showBranchSelect = false;
    search = '';
    searchList: { name: string, branch: string, alias: string, photo: string, photo_alias: string }[] = [];
    showSearchList = false;
    checkAuth: Boolean = this.sessionStorageService.pubId !== null;

    constructor(private router: Router,
                private sessionStorageService: SessionStorageService,
                private authService: AuthService,
                private dopParamsService: DopParamsService,
                private mainService: MainService,
                public globalParamsUser: GlobalParamsUser,
                public globalParams: GlobalParams,
                private applicationsService: ApplicationsService) {

        this.authService.refreshAuthClientInfo.subscribe(() => {
            this.getBranch();
        });

        this.applicationsService.refreshInfo.subscribe(() => {
            this.getRevenue();
        });

        this.sessionStorageService.authenticated.subscribe(item => {
            this.checkAuth = item;
        });
    }

    getBranch() {
        this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
                this.branches = this.currentBranches.concat(data);

                if (this.globalParamsUser.type === 1) {
                    this.globalParams.branch = this.branches[0];
                } else {
                    this.globalParams.branch = this.globalParamsUser.branch;
                }

                this.getRevenue();
            },
            (error) => {
                console.log('Ошибка при получении филиалов: ', error);
            });
    }

    // показ выбора
    changeBranch() {
        if (this.globalParamsUser.type !== 1) {
            return false;
        }

        this.showBranchSelect = !this.showBranchSelect;
    }

    globalSearch() {
        if (this.search === '') {
            this.searchList = [];
        } else {
            this.mainService.globalSearch({
                search: this.search
            }).then((data: any) => {
                    this.searchList = data;
                    this.showSearchList = this.searchList.length > 0;
                },
                (error) => {
                    console.log('Ошибка при списка: ', error);
                });
        }
    }

    // переход по выбору
    getItems(alias) {
        this.showSearchList = false;
        this.search = '';
        this.router.navigate([alias]);
    }

    setBranch() {
        this.mainService.changeBranch();
        this.getRevenue();
    }


    // получение выручки по филиалам
    getRevenue() {
        this.showBranchSelect = false;
        this.mainService.getRevenue({
            branch: this.globalParams.branch.val
        }).then((data: any) => {
                this.revenue = data;
            },
            (error) => {
                console.log('Ошибка при получении выручки: ', error);
            });
    }

    /*startTransition() {
        this.router.navigateByUrl('blank').then(() => {
            this.router.navigateByUrl('application-create');
        });
    }*/
}
