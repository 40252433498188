import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class NotificationsService {
  clientNotConfirmationCount = 0;
  applicationProcessed = [];

  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  notifSet(data) {
    if (!('Notification' in window)) {
      alert('Ваш браузер не поддерживает уведомления.');
    } else if ((Notification as any).permission === 'granted') {
      this.notifyMe(data);
    } else if ((Notification as any).permission !== 'denied') {
      const _this = this;
      (Notification as any).requestPermission(function (permission) {
        if (permission === 'granted') {
          _this.notifyMe(data);
        }
      });
    }
  }

  notifyMe(data) {
    const notification = new Notification('Новая заявка!', {
      tag: 'ache-mail',
      body: data,
      icon: 'assets/imgs/users/defU.png'
    });

    notification.onclick = function (event) {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      window.open('https://str-stroika-arenda.ru/application', '_blank');
      notification.close();
    };
  }

  // получение списка заявов
  public getApplicationProcessed(data) {
    return new Promise((resolve, reject) => {
      if (this.applicationProcessed.length === 0) {
        this.httpService.prepareQuery('get-applications-processed', data)
          .then((result: []) => {
              this.applicationProcessed = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении заявок', error);
              reject();
            }
          );
      } else {
        resolve(this.applicationProcessed);
      }
    });
  }

  public getClientNotConfirmationCrm(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-client-not-confirmation-crm', data)
        .then((result: []) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка полей для заявок', error);
            reject();
          }
        );
    });
  }
}
