import {Component} from '@angular/core';
import {AuthService} from './auth.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {Router} from '@angular/router';
import {GlobalParams} from '../../storage/global-params';
import {ShortHireService} from '../short_hire/short_hire.service';
import {DopParamsService} from '../../services/dopParams.service';

@Component({
  selector: 'app-client',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.less']
})
export class AuthComponent {
  phone = '';
  pass = '';
  checkSend = false;
  resetPassword = {
    show: false,
    phone: '',
  };

  constructor(private authService: AuthService,
              private globalParamsMessage: GlobalParamsMessage,
              private globalParams: GlobalParams,
              private shortHireService: ShortHireService,
              private dopParamsService: DopParamsService,
              private router: Router) {
  }

  auth() {
    if (!this.checkSend) {
      if (this.phone === '') {
        this.globalParamsMessage.data = {title: 'Необходимо указать номер телефона', type: 'error', body: ''};
        return false;
      }

      if (this.pass === '') {
        this.globalParamsMessage.data = {title: 'Необходимо указать пароль', type: 'error', body: ''};
        return false;
      }

      const phone = this.phone.replace(/[\),\(,\-,+,\s]/g, '');

      const platform = (typeof (navigator as any).userAgentData !== 'undefined' && typeof (navigator as any).userAgentData.platform !== 'undefined') ? (navigator as any).userAgentData.platform : '';

      this.checkSend = true;
      this.authService.auth({
        phone: phone,
        password: this.pass,
        device: {
          platform: platform,
          userAgent: navigator.userAgent || '',
          appVersion: navigator.appVersion || ''
        }
      }).then(() => {
          console.log('Авторизация прошла');
          this.dopParamsService.getMenu().then((result: InterFaceMenu[]) => {
              // this.router.navigate(['/' + result[0].link]);
              this.router.navigate(['/main']);
            },
            (error) => {
              console.log('Ошибка при получении меню: ', error);
            });
          this.globalParams.getShortHire = 1;
        },
        (error) => {
          this.checkSend = false;
          console.log('Ошибка при получении статусов: ', error);
        });
    }
  }

  showResetPassword() {

  }

  clickResetPassword() {
    this.authService.resetPassword({phone: this.resetPassword.phone}).then(() => {
        console.log('Пароль сброщен');
        this.resetPassword.show = false;
        this.resetPassword.phone = '';
      },
      (error) => {
        this.resetPassword.show = false;
        this.resetPassword.phone = '';
        console.log('Ошибка при сбросе пароля: ', error);
      });
  }
}
