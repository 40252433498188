import {Component, OnInit} from '@angular/core';
import {SchedulerService} from './scheduler.service';
import {Router} from '@angular/router';
import {DopParamsService} from '../../services/dopParams.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
})
export class SchedulerComponent implements OnInit {
  p = 1;
  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список статусов
  statusList: InterFaceSchedulerStatus[] = [];
  // список активных полей
  activeFieldsTables = {};
  // отображение активных полей
  showActiveFields = false;
  // филиалы
  branches: InterFaceDopParams[] = [];
  // список записей
  schedulers: InterFaceSchedulerList[] = [];
  filter = {
    like: '',
    likeName: '',
    likeDesc: '',
    status: 0,
    branch: 0,
    date_create: '',
    user: '',
    term: '',
    date_end: ''
  };

  // новая запись
  newScheduler = {
    show: false,
    name: '',
    description: '',
    branch: null,
    status: 1,
    date_term: ''
  };

  constructor(private schedulerService: SchedulerService,
              private dopParamsService: DopParamsService,
              private globalParamsMessage: GlobalParamsMessage,
              public globalParamsUser: GlobalParamsUser,
              public authService: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    this.schedulerService.getActiveFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении полей: ', error);
      });

    this.schedulerService.getStatus().then((data: InterFaceSchedulerStatus[]) => {
        this.statusList = [{val: 0, name: 'Все'}].concat(data);
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = [{val: 0, name: 'Все'}].concat(data);
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.authService.refreshAuthClientInfo.subscribe(() => {
      this.getSchedulers();
    });

    this.getSchedulers();
  }

  // получение списка
  getSchedulers() {
    // если не Админ, то получаем задачи только по своему филиалу
    this.filter.branch = this.globalParamsUser.type !== 1 ? this.globalParamsUser.branch.val : this.filter.branch;

    this.schedulerService.getSchedulers(this.filter).then((data: InterFaceSchedulerList[]) => {
        this.schedulers = data;
      },
      (error) => {
        console.log('Ошибка при получении списка записей: ', error);
      });
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag;
    }

  }

  // отображение активных полей
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }

  changeStatus(status = {val: 0}) {
    this.filter.status = status.val;
    this.getSchedulers();
  }

  // изменение прав у нового пользователя
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  // изменение списка активных полей
  changeFields() {
    this.schedulerService.changeFields({data: this.activeFields}).then(() => {
        this.showActiveFields = false;
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }

  // переход на конкретный планировщик
  schedulerDetails(scheduler) {
    this.router.navigate(['/scheduler/' + scheduler.id]);
  }

  // закрытие записи
  closeNewScheduler() {
    this.newScheduler = {
      show: false,
      name: '',
      description: '',
      branch: null,
      status: 1,
      date_term: ''
    };
  }

  // добавить новую запись
  addNewScheduler() {
    if (this.newScheduler.name === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать наименование', type: 'error', body: ''};
      return false;
    }

    if (this.newScheduler.branch === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать филиал', type: 'error', body: ''};
      return false;
    }

    if (this.newScheduler.status === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать статус', type: 'error', body: ''};
      return false;
    }

    if (this.newScheduler.date_term === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать срок', type: 'error', body: ''};
      return false;
    }


    this.schedulerService.addNewScheduler(this.newScheduler).then(() => {
        this.getSchedulers();
        this.closeNewScheduler();
      },
      (error) => {
        console.log('Ошибка при добавлении новой записи: ', error);
      });
  }

  onChanged(increased: any, type: string, update = false) {
    this.filter[type] = increased;

    if (update) {
      this.getSchedulers();
    }
  }

  onChangedNewScheduler(increased: any, type: string) {
    this.newScheduler[type] = increased;
  }
}

