import {Component} from '@angular/core';
import {ApplicationsMobileService} from './applicationsMobile.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-applications-mobile',
  templateUrl: './applicationsMobile.component.html',
})
export class ApplicationsMobileComponent {
  p = 1;

  filters = {
    offset: 0,
    limit: 50
  };

  activeFields = [
    'ID',
    'Клиент',
    'Оборудование',
    'Начало аренды',
    'Конец аренды',
    'Кол-во дней',
    'Тип проката',
    'Тип оплаты',
    'Комментарий',
    'Филиал',
    'Дата создания',
  ];
  applicationsCount = 0;
  applications: InterFaceApplicationsMobile[] = [];

  constructor(private applicationsMobileService: ApplicationsMobileService,
              private router: Router,) {
    this.getApplication();
  }

  getApplication() {
    this.applicationsMobileService.getApplicationsMobileForCrm({
      limit: this.filters.limit,
      offset: this.filters.offset,
    })
      .then((data: { data: InterFaceApplicationsMobile[], applicationsCount: number }) => {
          this.applications = data.data;
          this.applicationsCount = data.applicationsCount;
        },
        (error) => {
          console.log('Ошибка при получении списка заявок: ', error);
        });
  }

  applicationDetails(id) {
    this.router.navigateByUrl(`mobile-hire/${id}`);
  }

  changePage(data) {
    this.p = data;
    this.filters.offset = this.p * 50 - 50;
    this.getApplication();
  }
}
