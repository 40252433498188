import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';

@Injectable()
export class ApplicationsService {
  applicationsFieldsList: InterFaceActiveFields[] = [];
  applicationsStatusList: InterFaceDopParams[] = [];
  applicationsDopStatusList: InterFaceDopParams[] = [];
  refreshInfo: EventEmitter<any> = new EventEmitter(false);

  constructor(private httpService: HttpService) {
  }

  // получение списка активных полей
  public getApplicationsFields() {
    return new Promise((resolve, reject) => {
      if (this.applicationsFieldsList.length === 0) {
        this.httpService.prepareQuery('get-applications-field', '')
          .then((result: InterFaceActiveFields[]) => {
              this.applicationsFieldsList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей для заявок', error);
              reject();
            }
          );
      } else {
        resolve(this.applicationsFieldsList);
      }
    });
  }

  // изменение списка активных полей для таблицы
  public changeFields(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-applications-field', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей', error);
            reject();
          }
        );
    });
  }

  // получение списка статусов
  public getApplicationsStatus() {
    return new Promise((resolve, reject) => {
      if (this.applicationsStatusList.length === 0) {
        this.httpService.prepareQuery('get-applications-status', '')
          .then((result: InterFaceDopParams[]) => {
              this.applicationsStatusList = result;
              resolve(this.applicationsStatusList);
            },
            (error) => {
              console.log('Ошибка при получении списка статусов заявки', error);
              reject();
            }
          );
      } else {
        resolve(this.applicationsStatusList);
      }
    });
  }

  // получение списка статусов
  public getApplicationsDopStatus() {
    return new Promise((resolve, reject) => {
      if (this.applicationsDopStatusList.length === 0) {
        this.httpService.prepareQuery('get-applications-dop-status', '')
          .then((result: InterFaceDopParams[]) => {
              this.applicationsDopStatusList = result;
              resolve(this.applicationsDopStatusList);
            },
            (error) => {
              console.log('Ошибка при получении списка доп статусов заявки', error);
              reject();
            }
          );
      } else {
        resolve(this.applicationsDopStatusList);
      }
    });
  }

  // получение списка статусов
  public getApplicationsAllDopStatus() {
    return new Promise((resolve, reject) => {
      if (this.applicationsDopStatusList.length === 0) {
        this.httpService.prepareQuery('get-applications-all-dop-status', '')
          .then((result: InterFaceDopParams[]) => {
              this.applicationsDopStatusList = result;
              resolve(this.applicationsDopStatusList);
            },
            (error) => {
              console.log('Ошибка при получении списка доп статусов заявки', error);
              reject();
            }
          );
      } else {
        resolve(this.applicationsDopStatusList);
      }
    });
  }

  // получение списка заявов
  public getApplication(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-applications', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении заявок', error);
            reject();
          }
        );
    });
  }

  // изменение статуса
  public updateApplicationsStatus(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-applications-status', data)
        .then((result: InterFaceDopParams[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при изменении статуса заявки', error);
            reject();
          }
        );
    });
  }

  // изменение статуса
  public updateDopStatus(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-dop-applications-status', data)
        .then((result: InterFaceDopParams[]) => {
            this.applicationsDopStatusList = [];
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при изменении доп статуса заявки', error);
            reject();
          }
        );
    });
  }

  // добавление платежа
  public addPay(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('add-pay', data)
        .then((result: InterFaceDopParams[]) => {
            this.refreshInfo.emit(true);
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при добавлении нового платежа', error);
            reject();
          }
        );
    });
  }

  // получение информации по заявке
  public getApplicationInfo(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-application-info', data)
        .then((result: InterFaceDopParams[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при изменении статуса заявки', error);
            reject();
          }
        );
    });
  }

  // продлить контракт
  public extendRental(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('extend-rental', data)
        .then(() => {
            this.refreshInfo.emit(true);
            resolve('');
          },
          (error) => {
            console.log('Ошибка при продлении проката', error);
            reject();
          }
        );
    });
  }

  // сократить контракт
  public tameRental(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('tame-rental', data)
        .then(() => {
            this.refreshInfo.emit(true);
            resolve('');
          },
          (error) => {
            console.log('Ошибка при сокращении проката', error);
            reject();
          }
        );
    });
  }

  // приход лесов
  public comingLesa(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('coming-lesa', data)
        .then(() => {
            this.refreshInfo.emit(true);
            resolve('');
          },
          (error) => {
            console.log('Ошибка при добавлении лесов', error);
            reject();
          }
        );
    });
  }

  // история прихода и расхода лесов
  public getLesaHistory(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('lesa-history', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении историb прихода и расхода лесов', error);
            reject();
          }
        );
    });
  }

  // расход лесов
  public consumptionLesa(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('consumption-lesa', data)
        .then(() => {
            this.refreshInfo.emit(true);
            resolve('');
          },
          (error) => {
            console.log('Ошибка при расходе лесов', error);
            reject();
          }
        );
    });
  }

  // расчет суммы
  public hireChangeSum(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('hire-change-sum', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при расходе лесов', error);
            reject();
          }
        );
    });
  }

// удаление заявки
  public deleteApplicationDemand(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-application-demand', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при очистке заявок', error);
            reject();
          }
        );
    });
  }
}
