import {Component, OnInit} from '@angular/core';
import {ClientService} from './client.service';
import {DopParamsService} from '../../services/dopParams.service';
import {Router} from '@angular/router';
import {GlobalParamsClientChangeStatus} from '../client_change_status/global-params-client-change-status';
import {Sort} from '@angular/material/sort';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {GlobalParams} from '../../storage/global-params';
import * as moment from 'moment';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
})
export class ClientComponent implements OnInit {
  clients: IClientsUr[] = [];
  p = 1;
  statusList: InterFaceDopParamsColor[] = [];
  sourceList: InterFaceDopParams[] = [];
  branches: InterFaceDopParams[] = [];
  // отображение фильтра
  showFilters = false;
  // отображение фильтра
  showActiveFields = false;
  // параметры фильтра для поиска
  filters: InterFaceFilter = {
    type: 'all',
    like: '',
    source: 0,
    status: 0,
    branch: 0,
    date_start: '',
    date_end: '',
    rentals_start: '',
    rentals_end: '',
    dohod_start: '',
    dohod_end: '',
    phone: '',
    date_create: '',
    last_contact: '',
    sort: {},
    offset: 0,
    limit: 50
  };
  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};
  // массив с оборудованием для управления списком
  clientListChange = [];

  sortedData: IClientsUr[];
  timerId = null;

  clientsCount = null;
  showDeleteModal = false;
  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  constructor(private clientService: ClientService,
              private dopParamsService: DopParamsService,
              public globalParamsUser: GlobalParamsUser,
              public globalParams: GlobalParams,
              private globalParamsClientChangeStatus: GlobalParamsClientChangeStatus,
              private router: Router) {

    this.clientService.refreshClientInfo.subscribe(() => {
      this.getClients();
    });
  }

  ngOnInit() {
    this.dopParamsService.getStatusClient().then((data: InterFaceDopParamsColor[]) => {
        this.statusList = [{val: 0, name: 'Все', color: ''}].concat(data);
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });

    this.dopParamsService.getSource().then((data: InterFaceDopParams[]) => {
        this.sourceList = [{val: 0, name: 'Все'}].concat(data);
      },
      (error) => {
        console.log('Ошибка при получении источников: ', error);
      });


    this.clientService.getActiveFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении источников: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = [{val: 0, name: 'Все'}].concat(data);
        this.getClients();
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

  }

  getClientsTimeOut() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }


    this.timerId = setTimeout(() => {
      this.filters.offset = 0;
      this.p = 1;
      this.getClients();
    }, 1000);
  }

  getClients() {
    this.clientService.getClient({
      type: this.filters.type,
      like: this.filters.like,
      source: this.filters.source,
      status: this.filters.status,
      branch: this.filters.branch,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      rentals_start: this.filters.rentals_start,
      rentals_end: this.filters.rentals_end,
      dohod_start: this.filters.dohod_start,
      dohod_end: this.filters.dohod_end,
      phone: this.filters.phone,
      date_create: this.filters.date_create !== '' ? moment(this.filters.date_create).format().slice(0, 10) : '',
      last_contact: this.filters.last_contact !== '' ? moment(this.filters.last_contact).format().slice(0, 10) : '',
      sort: this.filters.sort,
      offset: this.filters.offset,
      limit: this.filters.limit
    }).then((data: { data: IClientsUr[], clientsCount: number }) => {
        this.clients = data.data;
        this.sortedData = this.clients.slice();
        this.showFilters = false;
        this.clientsCount = data.clientsCount;

      },
      (error) => {
        console.log('Ошибка при получении списка юр. клиентов: ', error);
      });
  }

  // изменение статуса
  changeStatus(data, client) {
    client.new_status = data;
    this.globalParamsClientChangeStatus.data = {
      show: true,
      old_status: client.old_status,
      new_status: client.new_status,
      text: '',
      client_id: client.id
    };
  }

  // отображение фильтра
  changeShowFilters() {
    this.showFilters = !this.showFilters;
  }

  // отображение активных полей
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }

  // список клиентов
  changeTypeClients(data) {
    this.filters.type = data;
    this.getClients();
  }

  // изменение прав у нового пользователя
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  // изменение списка активных полей
  changeFields() {
    this.clientService.changeFields({data: this.activeFields}).then(() => {
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag;
    }
  }

  // очистка фильтра
  clearFilter() {
    this.filters = {
      type: 'all',
      like: '',
      source: null,
      status: null,
      branch: null,
      date_start: '',
      date_end: '',
      rentals_start: '',
      rentals_end: '',
      dohod_start: '',
      dohod_end: '',
      phone: '',
      date_create: '',
      last_contact: '',
      sort: {},
      offset: 0,
      limit: 50
    };

    this.getClients();
  }

  // управление списком оборудования
  changeListEq(event, eq_id) {
    if (event.target.checked) {
      this.clientListChange.push(eq_id);
    } else {
      const key = this.clientListChange.indexOf(eq_id);
      this.clientListChange.splice(key, 1);
    }
  }

  // удаление клиентов из выделенного списка
  deleteClientList() {
    this.clientService.deleteClientList({
      list: this.clientListChange
    }).then(() => {
        this.getClients();
      },
      (error) => {
        console.log('Ошибка при удалении списка оборудований: ', error);
        this.getClients();
      });
  }

  clientDetails(id) {
    this.router.navigate(['/clients/' + id]);
  }

  sortData(sort: Sort) {
    this.filters.sort = sort;
    this.getClients();
  }
  makeVisible() {
    const item = document.querySelector('.dropdown-list:not([hidden=""])');
    if (item) {
      item.scrollIntoView();
    }
  }
  changePage(data) {
    this.p = data;
    this.filters.offset = this.p * 50 - 50;
    this.getClients();
  }

  checkCheck(client_id) {
    return this.clientListChange.find(item => item === client_id);
  }

  eq_excel() {
    this.clientService.eq_excel({
      type: this.filters.type,
      like: this.filters.like,
      source: this.filters.source,
      status: this.filters.status,
      branch: this.filters.branch,
      date_start: this.filters.date_start,
      date_end: this.filters.date_end,
      rentals_start: this.filters.rentals_start,
      rentals_end: this.filters.rentals_end,
      dohod_start: this.filters.dohod_start,
      dohod_end: this.filters.dohod_end,
      phone: this.filters.phone,
      date_create: this.filters.date_create,
      last_contact: this.filters.last_contact,
    }).then((result: string) => {
        window.open(this.globalParams.path + '/uploads/tmp/' + result, '_blank');
      },
      (error) => {
        console.log('Ошибка при формировании отчета: ', error);
      });
  }

  changeCount(count) {
    this.filters.limit = count;
    this.getClients();
  }

  onChanged(increased: any, type: string, update = false) {
    this.filters[type] = increased;

    if (update) {
      this.getClients();
    }
  }
}
