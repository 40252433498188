import {Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import {ApplicationsProcessedService} from './applicationsProcessed.service';
import {GlobalParams} from '../../storage/global-params';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {DopParamsService} from '../../services/dopParams.service';
import {EquipmentsService} from '../equipments/equipments.service';
import {EquipmentsCreateMiniService} from '../equipments_create_mini/equipmentsCreateMini.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {ApplicationsCreateService} from '../applications-create/applicationsCreate.service';
import * as moment from 'moment';

@Component({
  selector: 'app-applications-processed',
  templateUrl: './applicationsProcessed.component.html',
})
export class ApplicationsProcessedComponent {
  p = 1;
  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};
  // отображение фильтра
  showFilters = false;

  showComments = false;

  filters = {
    branch: '',
    date_start: '',
    date_end: '',
    sort: {},
    offset: 0,
    limit: 50
  };

  branches: InterFaceDopParams[] = [];
  applications: InterFaceApplicationsProcessed[] = [];
  sortedData: InterFaceApplicationsProcessed[];

  hiresCount = null;

  // статусы
  statusList = [
    {id: 1, name: 'Информирование'},
    {id: 2, name: 'Пред. бронь'},
    {id: 3, name: 'Спрос'},
    {id: 4, name: 'Отказ'},
    {id: 5, name: 'Узнали'}
  ];

  // список оборудований
  // отображение окна добавления товара
  showAddEquipments = {
    show: false,
    filter: '',
    equipments: []
  };

  selectApplication: InterFaceApplicationsProcessed = {
    id: null,
    client_phone: '',
    callibri_id: '',
    date_create: '',
    branch: '',
    source: '',
    branch_name: '',
    source_name: '',
    type_callibri: '',
    client_id: '',
    client_fio: '',
    client_email: '',
    comment: '',
    recording: '',
    bg: '',
    status: null,
    direction: '',
    similar: [],
    similarId: []
  };

  timerId = null;

  // выбранное оборудование
  selectEq = [];

  // выбранные поля
  showField = false;

  fields = {srok: '', comment: '', date_start: '', count_eq: '1', reminder_date: null};

  // очистка заявок
  showClearWindow = false;
  clearBranch = [];

  dropdownSettingsStatus = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };

  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  lesaArr = [
    {name: 'Рама проходная ЛРП-40', count: 0},
    {name: 'Рама с лестницей ЛРП-40', count: 0},
    {name: 'Диагональная связь 3,3 м ЛРП-40', count: 0},
    {name: 'Горизонтальная связь 3 м ЛРП-40', count: 0},
    {name: 'Ригель 3 м ЛРП-40', count: 0},
    {name: 'Настил 1х1 м ЛРП-40', count: 0}
  ];

  // оборудование или леса
  eqType = 'eq';

  showSimilar = {
    data: {},
    show: false
  };

  templates = {
    '5': ['Далеко', 'Дорого', 'На будущее', 'Согласовывает', 'Нужна доставка'],
    '4': ['Нет залога и документов'],
    '3': ['В аренде', 'В ремонте', 'Не хватает количества', 'Списан', 'Украден', 'Продан'],
    '2': [],
    '1': ['График работы', 'Реклама', 'По продлению', 'Узнал дорогу', 'Прочие звонки']
  };

  span_demand = false;
  showList = [];

  constructor(private applicationsService: ApplicationsProcessedService,
              public globalParams: GlobalParams,
              public globalParamsUser: GlobalParamsUser,
              public dopParamsService: DopParamsService,
              public globalParamsMessage: GlobalParamsMessage,
              public equipmentsCreateMiniService: EquipmentsCreateMiniService,
              public applicationsCreateService: ApplicationsCreateService,
              private equipmentsService: EquipmentsService) {
    this.applicationsService.getApplicationsFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении списка полей заявки: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
    this.getApplication();
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag;
    }
  }

  changeCommit() {
    this.showList = this.templates[this.selectApplication.status];
  }

  // отображение фильтра
  changeShowFilters() {
    this.showFilters = !this.showFilters;
  }

  // очистка фильтра
  clearFilter() {
    this.filters = {
      branch: '',
      date_start: '',
      date_end: '',
      sort: {},
      offset: 0,
      limit: 50
    };

    this.getApplication();
  }


  getApplication() {
    this.applicationsService.getApplicationProcessed({
      branch: this.filters.branch,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      sort: Object.keys(this.filters.sort).length !== 0,
      offset: this.filters.offset
    }).then((data: { data: InterFaceApplicationsProcessed[], hiresCount: number }) => {
        this.sortedData = data.data.slice();
        this.hiresCount = data.hiresCount;

        if (Object.keys(this.filters.sort).length !== 0) {
          this.applications = data.data;
          this.sortData(this.filters.sort);
        } else {
          this.applications = data.data;
        }
      },
      (error) => {
        console.log('Ошибка при получении списка заявок: ', error);
      });
  }


  sortData(sort) {
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.sort = sort;
      this.getApplication();
    } else {
      const data = this.applications.slice();
      this.applications = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'client_phone':
            return compare(a.client_phone, b.client_phone, isAsc);
          case 'callibri_id':
            return compare(a.callibri_id, b.callibri_id, isAsc);
          case 'branch':
            return compare(a.branch, b.branch, isAsc);
          case 'client_fio':
            return compare(a.client_fio, b.client_fio, isAsc);
          case 'type_callibri':
            return compare(a.type_callibri, b.type_callibri, isAsc);
          case 'date_create':
            const date_create_a = new Date(a.date_create).getTime();
            const date_create_b = new Date(b.date_create).getTime();
            return compare(date_create_a, date_create_b, isAsc);
          default:
            return 0;
        }
      });
    }
  }

  changePage(data) {
    this.p = data;
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.offset = this.p * 50 - 50;
      this.getApplication();
    }
  }

  // запрос оборудования
  getEquipments(filter = '') {
    this.equipmentsService.allEquipmentsProcessed({
      filter: filter,
      branch: this.selectApplication.branch,
      status: this.selectApplication.status
    }).then((data: any) => {
        this.showAddEquipments.equipments = data;
        this.showAddEquipments.show = true;
      },
      (error) => {
        console.log('Ошибка при получении списка оборудования: ', error);
      });
  }

  changeStatus(data) {
    this.selectApplication = data;
    if ([2, 3, 4, 5].indexOf(data.status) !== -1) {
      this.getEquipments();
    } else {
      this.showField = true;
      this.showList = [];
    }
  }

  closeShowAddEquipments() {
    this.selectApplication.status = null;
    this.showAddEquipments.equipments = [];
    this.selectEq = [];
    this.showAddEquipments.show = false;
    this.showField = false;
  }

  getHireTimeOut() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }


    this.timerId = setTimeout(() => {
      this.getEquipments(this.showAddEquipments.filter);
    }, 1000);
  }

  addEquipment() {
    if (this.showAddEquipments.filter === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать наименование', type: 'error', body: ''};
      return false;
    }

    this.equipmentsCreateMiniService.addEquipment({
      model: this.showAddEquipments.filter,
    }).then(() => {
        this.getEquipments(this.showAddEquipments.filter);
      },
      (error) => {
        console.log('Ошибка при добавлении нового пользователей: ', error);
      });
  }

  insertEquipmentsDataStatus(eq) {
    const tmp = {
      id: eq.id,
      name: eq.name,
      in_stock: eq.count,
      count: 1,
      price: eq.price_per_day,
      photo: eq.photo,
      photo_alias: '',
      allCount: eq.count,
      payList: [],
      comment: ''
    };
    this.selectEq.push(tmp);
    this.showAddEquipments.show = false;
    this.showField = true;
    this.showList = [];
  }

  insertEquipmentsLesaStatus() {
    const tmp = {
      id: null,
      name: 'Леса',
      in_stock: null,
      count: 1,
      price: null,
      photo: 'free-icon-equipment.svg',
      photo_alias: '',
      allCount: null,
      payList: [],
      comment: ''
    };
    this.selectEq.push(tmp);
    this.showAddEquipments.show = false;
    this.showField = true;
    this.showList = [];
  }

  addApplication() {

    if (this.fields.srok === '' && this.selectApplication.status === 3) {
      this.globalParamsMessage.data = {title: `Необходимо указать срок`, type: 'error', body: ''};
      return false;
    }

    if (this.selectApplication.status !== 3 && this.selectApplication.status !== 1 && this.selectApplication.status !== 2) {
      if (this.selectEq[0].comment === '' && this.fields.comment === '') {
        this.globalParamsMessage.data = {title: `Необходимо указать комментарий`, type: 'error', body: ''};
        return false;
      }
    }

    if (this.fields.comment === '' && this.selectApplication.status === 1) {
      this.globalParamsMessage.data = {title: `Необходимо указать комментарий`, type: 'error', body: ''};
      return false;
    }

    if (this.fields.date_start === '' && this.selectApplication.status === 2) {
      this.globalParamsMessage.data = {title: `Необходимо указать дату`, type: 'error', body: ''};
      return false;
    }
    let diffInSeconds = null;
    if (this.fields.reminder_date) {
      const startDate = new Date();
      const endDate = new Date(this.fields.reminder_date);
      diffInSeconds = this.dateDiffInSeconds(startDate, endDate);
    }

    this.applicationsCreateService.addMiniApplication({
      equipments: this.eqType === 'lesa' ? this.lesaArr : this.selectEq,
      client_id: this.selectApplication.client_id,
      srok: this.fields.srok,
      count_eq: this.fields.count_eq,
      reminder_date: this.fields.reminder_date,
      comment: this.fields.comment,
      source: this.selectApplication.source,
      branch: this.selectApplication.branch,
      status: this.selectApplication.status,
      client_phone: this.selectApplication.client_phone,
      processed_id: this.selectApplication.id,
      date_start: this.fields.date_start,
      direction: this.selectApplication.direction,
      limit: this.filters.limit,
      lesa: this.eqType === 'lesa',
      similarId: this.selectApplication.similarId,
      span_demand: this.span_demand,
      remind_diff: diffInSeconds

    }).then(() => {
        this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
        this.getApplication();
        this.clear();
      },
      (error) => {
        console.log('Ошибка при добавлении новой заявки: ', error);
      });
  }
  dateDiffInSeconds(date1, date2) {
    const diffInMilliseconds = Math.abs(date2 - date1);
    return Math.floor(diffInMilliseconds / 1000);
  }
  clear() {
    this.showField = false;

    this.selectApplication = {
      id: null,
      client_phone: '',
      callibri_id: '',
      date_create: '',
      branch: '',
      source: '',
      branch_name: '',
      source_name: '',
      type_callibri: '',
      client_id: '',
      client_fio: '',
      client_email: '',
      comment: '',
      recording: '',
      bg: '',
      status: null,
      direction: '',
      similar: [],
      similarId: []
    };

    this.showAddEquipments = {
      show: false,
      filter: '',
      equipments: []
    };

    this.fields = {srok: '', comment: '', date_start: '', count_eq: '1', reminder_date: null};

    this.selectEq = [];

    this.showAddEquipments.filter = '';
  }

  clearApplication() {
    this.applicationsService.clearApplicationsProcessed({
      branch: this.clearBranch.map(item => item.val),
    }).then(() => {
        this.getApplication();
        this.showClearWindow = false;
      },
      (error) => {
        console.log('Ошибка при очистке заявок: ', error);
      });
  }

  changeCount(count) {
    this.filters.limit = count;
    this.getApplication();
  }

  // управление лесами
  insertLesa(i, type) {
    if (type === 'plus') {
      this.lesaArr[i].count += 1;
    } else {
      if (this.lesaArr[i].count !== 0) {
        this.lesaArr[i].count -= 1;
      }
    }
  }

  changeSimilar(data: InterFaceApplicationsProcessed) {
    this.showSimilar = {
      show: true,
      data: data['similar']
    };
  }

  onChanged(increased: any, type: string) {
    this.filters[type] = increased;
  }
}

function compare(a: Date | number | string, b: Date | number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
