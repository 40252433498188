import {Component} from '@angular/core';
import {ProfileService} from './profile.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';
import {GlobalParams} from '../../storage/global-params';
import {MainService} from '../main/main.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent {
  currentTab = 1;
  info: InterProfile = {
    fio: '',
    phone: '',
    email: '',
    data_birth: '',
    current_revenue: '0',
    current_revenue_branch: '0',
    current_level: '0',
    nextLeft: '0',
    title: '',
    description: '',
    photo: '',
    daysLeft: '0',
    turnoverLeft: '0',
    all_turnover: '0',
    ostalos_revenue: '0',
    ratings: [],
    showPresent: false,
    bonus: {
      sum: '0',
      limit: '0'
    },
    second_bonus: {
      sum: '0',
      limit: '0'
    },
    fine: '0',
    all_sum_bonus: '0',
    plan: [{month: '', check: false, sum: '', percent: ''}],
    current_month: '',
    nextLevel: '',
    nextLevelSum: ''
  };

  selectYear = moment().format('YYYY');
  selectYearList = [
    {val: '2022', name: '2022'}
  ];
  currentMonth = {month: '', check: false, sum: '', percent: ''};

  constructor(private profileService: ProfileService,
              private globalParams: GlobalParams,
              private mainService: MainService,
              private globalParamsUser: GlobalParamsUser) {
    this.getProfileInfo();

    this.mainService.checkChangeBranch.subscribe(() => {
      this.getProfileInfo();
    });
  }

  getProfileInfo() {
    this.profileService.getProfileInfo({'branch': this.globalParams.branch.val}).then((result: InterProfile) => {
        this.info = result;
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });
  }

  getProfileInfoPlan(data) {
    this.selectYear = data;
    this.profileService.getProfileInfoPlan({
      'branch': this.globalParams.branch.val,
      'year': this.selectYear
    }).then((result: { month: string, check: boolean, sum: string, percent: string }[]) => {
        this.info.plan = result;
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });
  }

  getPresent() {
    alert('Ждемсссс');
  }
}
