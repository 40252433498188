import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';

@Injectable()
export class SettingsClientBonusService {
  constructor(private httpService: HttpService) {
  }

  public getBonusList() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-bonus-list', '')
        .then((result: InterFaceClientBonus[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка бонусов', error);
            reject();
          }
        );
    });
  }

  public deleteBonusList(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-bonus-list', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при удалении списка бонусов', error);
            reject();
          }
        );
    });
  }

  public addBonusList(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('add-bonus-list', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при добавлениии списка бонусов', error);
            reject();
          }
        );
    });
  }
}
