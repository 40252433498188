import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalParams} from '../../storage/global-params';
import {ApplicationsMobileService} from '../applications_mobile/applicationsMobile.service';

@Component({
  selector: 'app-check-hire-modal',
  templateUrl: './checkHireModal.html',
})
export class CheckHireModalComponent {
  list: InterFaceApplicationsMobile[] = [];
  p = 1;
  count = null;
  offset = 0;

  constructor(private router: Router,
              private applicationsMobileService: ApplicationsMobileService,
              public globalParams: GlobalParams) {
    this.getHires();
  }

  getHires() {
    this.applicationsMobileService.getApplicationsMobileForCrm({
      limit: 4,
      offset: this.offset,
    })
      .then((data: { data: InterFaceApplicationsMobile[], applicationsCount: string }) => {
          this.list = data.data;
          this.count = data.applicationsCount;
        },
        (error) => {
          console.log('Ошибка при получении списка заявок: ', error);
        });
  }

  selectNotifications(id) {
    this.router.navigateByUrl(`/`).then(() => {
      this.globalParams.showNotification = false;
      this.router.navigate([`/mobile-hire/${id}`]);
    });
  }

  changePage(data) {
    this.p = data;
    this.offset = this.p * 4 - 4;
    this.getHires();
  }
}
