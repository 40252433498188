import {Component} from '@angular/core';
import {SettingsFileService} from './settingsFile.service';
import {GlobalParams} from '../../storage/global-params';

@Component({
  selector: 'app-settings-file',
  templateUrl: './settingsFile.component.html',
})
export class SettingsFileComponent {
  list: any = {
    file: '',
    photo: '',
  };
  // загрузка изображения
  load_file: any = [];
  docs: InterFaceSettingsFiles[] = [];

  viewList = [];

  constructor(private settingsFileService: SettingsFileService,
              private globalParams: GlobalParams,
  ) {
    this.getInfo();
  }

  getInfo() {
    this.settingsFileService.getDocumentsFile().then((data: InterFaceSettingsFiles[]) => {
        this.docs = data;
      },
      (error) => {
        console.log('Ошибка при получене настроек сайта: ', error);
      });
  }

  update(type) {
    if (this.list.file === '' || this.list.photo === '') {
      return false;
    }
    this.settingsFileService.updateSiteInfo({
      type: type,
      file: this.list.file,
      photo: this.list.photo,
    }).then(() => {

      },
      (error) => {
        console.log('Ошибка при обновлении плана: ', error);
      });
  }

  // добавить фото
  addPhoto(event) {
    const target = event.target || event.srcElement;

    for (let j = 0; target.files.length > j; j++) {
      this.load_file = target.files[j];
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this, name);
      reader.readAsBinaryString(this.load_file);
    }
  }

  _handleReaderLoaded(data, readerEvt) {
    const binaryString = readerEvt.target.result;
    this.list.photo = btoa(binaryString);
    this.list.file = this.load_file.name;
  }

  changeView(id) {
    const index = this.viewList.indexOf(id);

    if (index === -1) {
      this.viewList.push(id);
    } else {
      this.viewList.splice(index, 1);
    }
  }
}
