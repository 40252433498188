import {EventEmitter, Injectable} from '@angular/core';


import * as io from 'socket.io-client';
import {GlobalParamsUser} from './storage/global-params-user';

@Injectable({
  providedIn: 'root'
})

export class SocketioService {

  socket;
  getNotification: EventEmitter<any> = new EventEmitter(false);
  constructor(  private globalParamsUser: GlobalParamsUser) {   }

  setupSocketConnection() {
    this.socket = io('https://backend-stroika-arenda.ru');

    this.socket.on('notifications', (data: any) => {
      console.log('DA SYKA ', data, this.globalParamsUser.branch.val);
      if (this.globalParamsUser.branch.val === parseInt(data.branch_id) ||
        this.globalParamsUser.type === 1) {
        this.getNotification.emit(data);
      }
    });
  }
}
