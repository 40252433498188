import {Injectable} from '@angular/core';

@Injectable()
export class GlobalParamsTame {
  data: any = {
    show: false,
    date_end: '',
    type_lease: null,
    app_id: null,
    count: null,
  };
}
