import {Component} from '@angular/core';

@Component({
  selector: 'app-hire-bron',
  templateUrl: './hireOrBron.component.html',
})
export class HireOrBronComponent {
  type = 'hire';

  constructor() {
  }
}
