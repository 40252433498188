import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class ReportService {
  equipmentsFieldsList: InterFaceActiveFields[] = [];
  reportHireFieldsList: InterFaceActiveFields[] = [];
  reportMarketingFieldsList: InterFaceActiveFields[] = [];
  reportFinanceFieldsList: InterFaceActiveFields[] = [];

  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение списка оборудования
  public getEquipments(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-report-equipment', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка оборудования', error);
            reject(error);
          }
        );
    });
  }

  // получение списка заявок
  public getReportHire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-report-hire', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка оборудования', error);
            reject(error);
          }
        );
    });
  }

  // получение списка заявок
  public getReportMarketing(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-report-marketing', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка маркетинга', error);
            reject(error);
          }
        );
    });
  }

  // получение списка финансов
  public getReportFinance(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-report-finance', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка заявок', error);
            reject(error);
          }
        );
    });
  }

  // получение списка виджетов
  public getWidgets() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-report-mini-block', '')
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка виджетов', error);
            reject(error);
          }
        );
    });
  }

  // получение списка активных полей
  public getReportsFields() {
    return new Promise((resolve, reject) => {
      if (this.equipmentsFieldsList.length === 0) {
        this.httpService.prepareQuery('get-report-eq-fields', '')
          .then((result: InterFaceActiveFields[]) => {
              this.equipmentsFieldsList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей для отчета', error);
              reject();
            }
          );
      } else {
        resolve(this.equipmentsFieldsList);
      }
    });
  }

  // изменение списка активных полей для таблицы
  public changeFields(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-report-eq-fields', data)
        .then(() => {
            this.equipmentsFieldsList = [];
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей', error);
            reject();
          }
        );
    });
  }

  // получение списка активных полей для отчета по заявкам
  public getReportsHireFields() {
    return new Promise((resolve, reject) => {
      if (this.reportHireFieldsList.length === 0) {
        this.httpService.prepareQuery('get-report-hire-fields', '')
          .then((result: InterFaceActiveFields[]) => {
              this.reportHireFieldsList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей для отчета', error);
              reject();
            }
          );
      } else {
        resolve(this.reportHireFieldsList);
      }
    });
  }

  // изменение списка активных полей для отчета по заявкам
  public changeReportsHireFields(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-report-hire-fields', data)
        .then(() => {
            this.reportHireFieldsList = [];
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей', error);
            reject();
          }
        );
    });
  }

  // получение списка активных полей для отчета по маркетингу
  public getReportsMarketingFields() {
    return new Promise((resolve, reject) => {
      if (this.reportMarketingFieldsList.length === 0) {
        this.httpService.prepareQuery('get-report-marketing-fields', '')
          .then((result: InterFaceActiveFields[]) => {
              this.reportMarketingFieldsList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей для отчета', error);
              reject();
            }
          );
      } else {
        resolve(this.reportMarketingFieldsList);
      }
    });
  }

  // изменение списка активных полей для отчета по маркетингу
  public changeReportsMarketingFields(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-report-marketing-fields', data)
        .then(() => {
            this.reportMarketingFieldsList = [];
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей', error);
            reject();
          }
        );
    });
  }

  // получение списка активных полей для отчета по маркетингу
  public getReportsFinanceFields() {
    return new Promise((resolve, reject) => {
      if (this.reportFinanceFieldsList.length === 0) {
        this.httpService.prepareQuery('get-report-finance-fields', '')
          .then((result: InterFaceActiveFields[]) => {
              this.reportFinanceFieldsList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей для отчета', error);
              reject();
            }
          );
      } else {
        resolve(this.reportFinanceFieldsList);
      }
    });
  }

  // изменение списка активных полей для отчета по маркетингу
  public changeReportsFinanceFields(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-report-finance-fields', data)
        .then(() => {
            this.reportFinanceFieldsList = [];
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей', error);
            reject();
          }
        );
    });
  }

  // отправка отчета в телеграмм
  public sendReportTelegram(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('send-report-telegram', data)
        .then(() => {
            this.globalParamsMessage.data = {title: 'Отчет успешно отправлен', type: 'error', body: ''};
            resolve('');
          },
          (error) => {
            this.globalParamsMessage.data = {title: 'Ошибка при отправке отчета', type: 'error', body: ''};
            console.log('Ошибка при отправке отчета', error);
            reject();
          }
        );
    });
  }
}
