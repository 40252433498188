import {Component, OnInit} from '@angular/core';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {AuthService} from '../auth/auth.service';
import {GlobalParams} from '../../storage/global-params';
import {DopParamsService} from '../../services/dopParams.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  menuList: InterFaceMenu[] = [];
  showMenuList: InterFaceMenu[];

  constructor(public globalParamsUser: GlobalParamsUser,
              public globalParams: GlobalParams,
              public dopParamsService: DopParamsService,
              public authService: AuthService) {
  }

  ngOnInit() {
    this.dopParamsService.getMenu().then((result: InterFaceMenu[]) => {
        this.menuList = result;
        if (this.globalParamsUser.type !== 3) {
          this.showMenuList = this.menuList.filter(item => item.type.indexOf(this.globalParamsUser.type) !== -1);
        } else {
          this.showMenuList = this.menuList;
        }

      },
      (error) => {
        console.log('Ошибка при получении меню: ', error);
      });

    this.authService.refreshAuthClientInfo.subscribe(() => {
      this.showMenuList = this.menuList.filter(item => item.type.indexOf(this.globalParamsUser.type) !== -1);
    });
  }

  red_current() {
    this.globalParams.showLeftMenu = false;
  }

  exit() {
    this.authService.exit().then(() => {
        console.log('Выход успешный');
      },
      (error) => {
        console.log('Ошибка при выходе: ', error);
      });
  }
}
