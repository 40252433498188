import {Component, DoCheck, OnChanges} from '@angular/core';
import {GlobalParamsMessage} from './global-params-message';

@Component({
  selector: 'app-message-alert',
  templateUrl: './message_alert.component.html',
  styleUrls: ['./message_alert.scss']
})
export class MessageAlertComponent implements DoCheck {
  data: any;
  timerId = null;

  constructor(private globalParamsMessage: GlobalParamsMessage) {
    this.data = globalParamsMessage;
  }

  ngDoCheck() {
    if (this.data.data.type !== '') {
      if (this.timerId !== null) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.clear();
      }, 5000);
    }
  }


  clear() {
    this.data.data.type = '';
    this.data.data.title = '';
    this.data.data.body = '';
  }
}
