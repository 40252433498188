import {Component} from '@angular/core';
import {SettingsSaleDaysService} from './settingsSaleDays.service';

@Component({
  selector: 'app-settings-sale-days',
  templateUrl: './settingsSaleDays.component.html',
})
export class SettingsSaleDaysComponent {
  lists: InterFaceSettingSaleDays[] = [];
  newList: InterFaceSettingSaleDays = {
    id: null,
    min_day: '',
    max_day: '',
    sale_sum: '',
    sale_percent: '',
  };

  constructor(private settingsSaleDaysService: SettingsSaleDaysService) {
    this.getInfo();
  }

  getInfo() {
    this.settingsSaleDaysService.getSaleDays().then((data: InterFaceSettingSaleDays[]) => {
        this.lists = data;
      },
      (error) => {
        console.log('Ошибка при получене скидок: ', error);
      });
  }

  update(data) {
    this.settingsSaleDaysService.addSaleDays({
      id: data.id,
      min_day: data.min_day,
      max_day: data.max_day,
      sale_sum: data.sale_sum,
      sale_percent: data.sale_percent,
    }).then(() => {
        this.newList = {
          id: null,
          min_day: '',
          max_day: '',
          sale_sum: '',
          sale_percent: '',
        };
        this.getInfo();
      },
      (error) => {
        console.log('Ошибка при обновлении скидки: ', error);
      });
  }

  deleteSaleDays(id) {
    this.settingsSaleDaysService.deleteSaleDays({
      id: id,
    }).then(() => {
        this.getInfo();
      },
      (error) => {
        console.log('Ошибка при обновлении скидки: ', error);
      });
  }
}
