import {Component} from '@angular/core';
import {GlobalParamsRental} from './global-params-rental';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {ApplicationsService} from '../applications/applications.service';
import * as moment from 'moment';

@Component({
  selector: 'app-rental',
  templateUrl: './rental.component.html',
})
export class RentalComponent {
  data: any;
  tmpDate = '';

  constructor(private globalParamsRental: GlobalParamsRental,
              private applicationsService: ApplicationsService,
              private globalParamsMessage: GlobalParamsMessage) {
    this.data = globalParamsRental;
  }

  clear() {
    this.data.data = {
      show: false,
      date_end: '',
      type_lease: null,
      app_id: null,
      count: ''
    };
  }

  send() {
    if (this.data.data.count === '' || typeof this.data.data.count === 'undefined') {
      this.globalParamsMessage.data = {title: 'Не указан количество дней/месяцев для продления', type: 'error', body: ''};
      return false;
    }

    if (this.data.data.count === null) {
      this.globalParamsMessage.data = {
        title: 'Произошла ошибка при продлении проката. Просьба переоткрыть модальное окно продления',
        type: 'error',
        body: ''
      };
      return false;
    }

    this.data.data.show = false;
    if (this.data.data.eq_id !== null) {
      this.applicationsService.extendRental({
        count: this.data.data.count,
        app_id: this.data.data.app_id
      }).then(() => {
          this.globalParamsMessage.data = {title: 'Аренда успешно обновлена', type: 'success', body: ''};
        },
        (error) => {
          console.log('Ошибка при добавлении нового платежа: ', error);
        });
    }
  }

  // выбор даты в календаре
  changeCalendar(type) {
    const const_date_end_format = moment(this.data.data.const_date_end_format);
    if (type === 'calendar') {
      const ll2 = moment(this.data.data.date_end_format);
      this.data.data.count = ll2.diff(const_date_end_format, 'day');
    } else if (type === 'day') {
      this.data.data.date_end_format = const_date_end_format.add(this.data.data.count, 'day').format().slice(0, 16);
    } else if (type === 'months') {
      this.data.data.date_end_format = const_date_end_format.add(this.data.data.count, 'months').format().slice(0, 16);
    }
  }
}
