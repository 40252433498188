import {Component} from '@angular/core';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
})
export class BlankComponent {

  constructor() {
  }
}
