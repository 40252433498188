import {Component, OnInit} from '@angular/core';
import {GlobalParamsConsumptionLesa} from './global-params-consumption_lesa';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {ApplicationsService} from '../applications/applications.service';
import {DopParamsService} from '../../services/dopParams.service';

@Component({
  selector: 'app-consumption-lesa',
  templateUrl: './consumption_lesa.component.html',
})
export class ConsumptionLesaComponent implements OnInit {
  data: any;
  lesa = {
    rama_proh: 0,
    rama_lest: 0,
    diag: 0,
    gor_zvyaz: 0,
    rigel: 0,
    nastil: 0,
    branch: 1
  };

  branches: InterFaceBranch[] = [];

  constructor(private globalParamsConsumptionLesa: GlobalParamsConsumptionLesa,
              private applicationsService: ApplicationsService,
              private dopParamsService: DopParamsService,
              private globalParamsMessage: GlobalParamsMessage) {
    this.data = globalParamsConsumptionLesa;
  }

  ngOnInit() {
    this.dopParamsService.getBranch().then((data: InterFaceBranch[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
  }

  clear() {
    this.data.show = false;
  }

  // выбор леса
  insertLesa(data, key) {
    this.lesa[key] = data === 'minus' ? this.lesa[key] - 1 : this.lesa[key] + 1;
  }

  send() {
    this.data.show = false;
      this.applicationsService.consumptionLesa({
        rama_proh: this.lesa.rama_proh,
        rama_lest: this.lesa.rama_lest,
        diag: this.lesa.diag,
        gor_zvyaz: this.lesa.gor_zvyaz,
        rigel: this.lesa.rigel,
        nastil: this.lesa.nastil,
        branch: this.lesa.branch
      }).then(() => {
          this.globalParamsMessage.data = {title: 'Леса успешно списаны', type: 'success', body: ''};
        },
        (error) => {
          console.log('Ошибка при списании лесов: ', error);
        });
  }

  onChanged(increased: any, type: string, update = false) {
    this.lesa[type] = increased;
  }
}
