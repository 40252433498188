import {Component} from '@angular/core';
import {SettingsSiteService} from './settingsSite.service';
import {GlobalParams} from '../../storage/global-params';
import {AuthService} from '../auth/auth.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-settings-site',
  templateUrl: './settingsSite.component.html',
})
export class SettingsSiteComponent {
  list: any = {
    file: '',
    photo: '',
  };
  // загрузка изображения
  load_file: any = [];
  fileString = '';
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(private settingsSiteService: SettingsSiteService,
              private authService: AuthService,
              private titleService: Title,
  ) {
    this.getInfo();
  }

  getInfo() {
    this.authService.getInfoSite().then((data: any) => {
        this.titleService.setTitle(data.title);
        this.list.title = data.title;
        this.list.favicon = data.favicon;
      },
      (error) => {
        console.log('Ошибка при получене настроек сайта: ', error);
      });
  }

  update() {
    this.settingsSiteService.updateSiteInfo({
      title: this.list.title,
      favicon: this.list.favicon,
      file: this.list.file,
      photo: this.list.photo,
    }).then(() => {
      setTimeout(() => this.getInfo(), 500);
      },
      (error) => {
        console.log('Ошибка при обновлении плана: ', error);
      });
  }

  // добавить фото
  addPhoto(event) {
    const target = event.target || event.srcElement;

    for (let j = 0; target.files.length > j; j++) {
      this.load_file = target.files[j];
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this, name);
      reader.readAsBinaryString(this.load_file);
    }
  }

  _handleReaderLoaded(data, readerEvt) {
    const binaryString = readerEvt.target.result;
    this.list.photo = btoa(binaryString);
    this.list.file = this.load_file.name;
    this.update();
  }
}
