import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SettingsSiteService {
  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

    // обновление премий менеджера
  public updateSiteInfo(data) {
      return new Promise((resolve, reject) => {
        this.httpService.prepareQuery('update-info-site', data)
          .then((result: any) => {
              this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
              resolve('');
            },
            (error) => {
              console.log('Ошибка при обновлении информации', error);
              reject();
            }
          );
      });
    }
}
