import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SettingsFileService {
  constructor(private httpService: HttpService) {
  }

  // получение документов
  public getDocumentsFile() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-documents-file', '')
        .then((result: InterFaceSettingsFiles[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении файло', error);
            reject();
          }
        );
    });
  }

  // обновление документа
  public updateSiteInfo(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('set-documents-file', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при обновлении документа', error);
            reject();
          }
        );
    });
  }
}
