import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class FaqService {
  
    /*
  activeFields: InterFaceActiveFields[] = [];
  category: InterFaceDopParams[] = [];
  type: InterFaceDopParams[] = [];
  checkBox: InterFaceFinanceCashBox[] = [];
  refreshCashBox: EventEmitter<any> = new EventEmitter(false);
    */
  category: InterFaceDopParams[] = [];
  
  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение категорий
  public getFaq() {
    return new Promise((resolve, reject) => {
        this.httpService.prepareQuery('get-faq', '')
          .then((result: InterFaceDopParams[]) => {
              this.category = result;
              resolve(this.category);
            },
            (error) => {
              console.log('Ошибка при получении списка вопрос-ответ', error);
              reject();
            }
          );
    });
  }
  // добавление категории
  public addCategory(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('add-faq-category', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при добавлении категории', error);
            reject(error);
          }
        );
    });
  }
  public deleteCategory(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-faq-category', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при удалении категории', error);
            reject(error);
          }
        );
    });
  }
  public deleteQuestion(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-faq-question', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при удалении вопроса', error);
            reject(error);
          }
        );
    });
  }
  public addQuestion(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('add-faq-question', data)
        .then((result: any) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при добавлении вопроса ответа', error);
            reject(error);
          }
        );
    });
  }
 
}
