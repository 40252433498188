import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalParams} from '../../storage/global-params';
import {NotificationsService} from '../notifications/notifications.service';

@Component({
  selector: 'app-check-client-modal',
  templateUrl: './checkClientModal.html',
})
export class CheckClientModalComponent {
  list: {
    id: number;
    client_fio: string;
    branch: string;
    client_phone: string;
    date_create: string;
  }[] = [];
  p = 1;
  count = 0;
  offset = 0;

  constructor(private router: Router,
              public globalParams: GlobalParams,
              private notificationsService: NotificationsService,) {
    this.getClients();
  }

  selectNotifications(id) {
    this.router.navigateByUrl(`/`).then(() => {
      this.globalParams.showNotification = false;
      this.router.navigate([`/clients/${id}`]);
    });
  }

  changePage(data) {
    this.p = data;
    this.offset = this.p * 4 - 4;
    this.getClients();
  }

  getClients() {
    this.notificationsService.getClientNotConfirmationCrm({
      offset: this.offset,
      limit: 4
    }).then((data: { data: [], count: number }) => {
        this.list = data.data;
        this.count = data.count;
      },
      (error) => {
        console.log('Ошибка при получении списка заявок: ', error);
      });
  }
}
