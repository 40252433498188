import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {SchedulerService} from '../scheduler/scheduler.service';
import {DopParamsService} from '../../services/dopParams.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Component({
  selector: 'app-scheduler-info',
  templateUrl: './schedulerInfo.component.html',
})
export class SchedulerInfoComponent implements OnInit {
  currentScheduler: InterFaceSchedulerList = {
    id: null,
    name: '',
    description: '',
    status: '',
    status_id: null,
    user: '',
    branch: '',
    branch_id: null,
    term: '',
    date_create: '',
    date_end: ''
  };
  scheduler_id = null;

  // филиалы
  branches: InterFaceDopParams[] = [];
  // список статусов
  statusList: InterFaceSchedulerStatus[] = [];

  constructor(private schedulerService: SchedulerService,
              public globalParamsUser: GlobalParamsUser,
              private activatedRoute: ActivatedRoute,
              private globalParamsMessage: GlobalParamsMessage,
              public dopParamsService: DopParamsService,
              private router: Router) {
    this.activatedRoute.params.subscribe(
      (params: Params): void => {
        this.scheduler_id = params.id;
      }
    );
  }

  ngOnInit() {
    this.schedulerService.getStatus().then((data: InterFaceSchedulerStatus[]) => {
        this.statusList = data;
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.schedulerService.getCurrentScheduler({id: this.scheduler_id}).then((data: InterFaceSchedulerList) => {
        this.currentScheduler = data;
      },
      () => {
        console.log('Ошибка при получении данных');
      });
  }

  // отправляем на согласование
  changeClientStatus(data) {
    this.currentScheduler.status_id = data;
    this.updateScheduler();
  }

  // обновление записи
  updateScheduler() {
    if (this.currentScheduler.name === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать наименование', type: 'error', body: ''};
      return false;
    }

    if (this.currentScheduler.description === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать описание', type: 'error', body: ''};
      return false;
    }

    if (this.currentScheduler.branch === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать филиал', type: 'error', body: ''};
      return false;
    }

    if (this.currentScheduler.status === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать статус', type: 'error', body: ''};
      return false;
    }

    if (this.currentScheduler.term === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать срок', type: 'error', body: ''};
      return false;
    }

    this.schedulerService.updateScheduler(this.currentScheduler).then(() => {
        this.router.navigate(['/scheduler']);
      },
      () => {
        console.log('Ошибка при получении данных');
      });
  }

  onChanged(increased: any, type: string, update = false) {
    this.currentScheduler[type] = increased;
  }
}

