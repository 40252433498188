import {Component, OnInit} from '@angular/core';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {FaqService} from './faq.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  category_name = null;
  title = null;
  question = null;
  answer = null;
  faq = [];
  tmp_id = null;
  video = null;
  showDeleteCategoryModal = false;
  showDeleteQuestionModal = false;
  /*
  faq = [{
    'title' : 'Тестовая категория 1',
    'questions' : [
      {'question': 'Вопрос 1', 'answer' : 'Ответ 1 '},
      {'question': 'Вопрос 2', 'answer' : 'Ответ 2 '},
    ]
  },
  {
    'title' : 'Тестовая категория 2',
    'questions' : [
      {'question': 'Вопрос 1', 'answer' : 'Ответ 1 '},
      {'question': 'Вопрос 2', 'answer' : 'Ответ 2 '},
    ]
  }];
  */

  currentTab = 0;

  viewList = [];

  showCategoryModal = false;
  showQuestionModal = false;

  showFaqCategoryModal() {
    this.showCategoryModal = true;
  }

  hideFaqCategoryModal() {
    this.showCategoryModal = false;
  }

  showFaqQuestionModal() {
    this.showQuestionModal = true;
  }

  hideFaqQuestionModal() {
    this.showQuestionModal = false;
  }

  changeTab(i) {
    this.currentTab = i;
    this.viewList = [];
  }

  constructor(
    private router: Router,
    private FaqService: FaqService,
    private globalParamsMessage: GlobalParamsMessage) {

    this.getFaq();

  }

  ngOnInit() {
  }

  getFaq() {
    this.FaqService.getFaq().then((result: InterFaceDopParams[]) => {
        this.faq = result;
      },
      (error) => {
        console.log('Ошибка при получении категорий: ', error);
      });
  }

  addCategory() {
    if (this.category_name === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать назначение', type: 'error', body: ''};
      return false;
    }
    this.FaqService.addCategory({
      title: this.category_name,

    }).then(() => {
        this.hideFaqCategoryModal();
        this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
        this.getFaq();
        this.category_name = null;
      },
      (error) => {
        console.log('Ошибка при добавлении новой записи: ', error);
      });
  }

  deleteCategory(id) {
    this.FaqService.deleteCategory({
      id: id,

    }).then(() => {
        this.globalParamsMessage.data = {title: 'Категория успешно удалена', type: 'success', body: ''};
        this.getFaq();
      },
      (error) => {
        console.log('Ошибка при удаление категории: ', error);
      });
  }

  deleteQuestion(id) {
    this.FaqService.deleteQuestion({
      id: id,

    }).then(() => {
        this.globalParamsMessage.data = {title: 'Вопрос успешно удален', type: 'success', body: ''};
        this.getFaq();
      },
      (error) => {
        console.log('Ошибка при удаление вопроса: ', error);
      });
  }

  addQuestion() {
    if (this.title === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать назначение категория', type: 'error', body: ''};
      return false;
    }
    if (this.answer === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать назначение ответ', type: 'error', body: ''};
      return false;
    }
    if (this.question === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать назначение вопрос', type: 'error', body: ''};
      return false;
    }
    this.FaqService.addQuestion({
      title: this.title,
      question: this.question,
      asnwer: this.answer,
      video: this.video,

    }).then(() => {
        this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
        this.getFaq();
        this.category_name = null;
        this.video = null;
        this.hideFaqQuestionModal();
      },
      (error) => {
        console.log('Ошибка при добавлении новой записи: ', error);
      });
  }

  getSnapshotUrl(video) {
    return 'background-image: url(http://img.youtube.com/vi/' + video + '/maxresdefault.jpg)';
  }

  changeView(id) {
    const index = this.viewList.indexOf(id);
    if (index === -1) {
      this.viewList.push(id);
    } else {
      this.viewList.splice(index, 1);
    }
    console.log(this.viewList);
  }

  onChanged(increased: any) {
    this.title = increased;
  }
}
