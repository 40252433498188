import {Component, OnInit, ViewChild} from '@angular/core';
import {DopParamsService} from '../../services/dopParams.service';
import {EquipmentsService} from '../equipments/equipments.service';
import {MainService} from './main.service';
import {OwlCarousel} from 'ngx-owl-carousel';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {StockService} from '../stock/stock.service';
import {GlobalParams} from '../../storage/global-params';
import {ProfileService} from '../profile/profile.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  @ViewChild('owlElement', {static: false}) owlElement: OwlCarousel;
  date = new Date().toISOString().split('T')[0];
  date_create_month = '0';
  date_create_year = 0;
  date_create_day = '0';
  date_end_month = '0';
  date_end_year = 0;
  date_end_day = '0';

  years: { val: number, name: string }[] = [{val: 0, name: 'Год'}];
  selectYears = [
    {val: '2022', name: '2022'},
    {val: '2023', name: '2023'},
  ];
  days: { val: string, name: string }[] = [{val: '0', name: 'Число'}];
  typeFilter = 1;
  months = [
    {val: '0', name: 'Месяц'},
    {val: '01', name: 'Январь'},
    {val: '02', name: 'Февраль'},
    {val: '03', name: 'Март'},
    {val: '04', name: 'Апрель'},
    {val: '05', name: 'Май'},
    {val: '06', name: 'Июнь'},
    {val: '07', name: 'Июль'},
    {val: '08', name: 'Август'},
    {val: '09', name: 'Сентябрь'},
    {val: '10', name: 'Октябрь'},
    {val: '11', name: 'Ноябрь'},
    {val: '12', name: 'Декабрь'},
  ];

  customOptions = {
    margin: 15,
    responsive: {
      0: {items: 1},
      426: {items: 2},
      577: {items: 3},
      671: {items: 4},
      993: {items: 6},
      1201: {items: 7},
      1601: {items: 8},
    },
    dots: false,
    nav: true,
    navText: [
      '<svg class="pos-abs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.404 199.404"><path d="M135.412 0L35.709 99.702l99.703 99.702 28.283-28.285-71.418-71.417 71.418-71.417z"/></svg>',
      '<svg class="pos-abs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.404 199.404"><path d="M63.993 199.404l99.702-99.702L63.993 0 35.709 28.285l71.418 71.417-71.418 71.417z"/></svg>',
    ],
  };

  branches: InterFaceDopParams[] = [];
  filter: InterFaceMainFilter = {
    branch: 0,
    date_start: '',
    date_end: '',
  };
  incomes: InterFaceMainIncomes[] = [];
  incomesS: InterFaceMainIncomes[] = [];
  equipments: InterFaceMainEquipments[] = [];
  manager_plan: {
    all_turnover: string,
    ostalos_revenue: string,
    delimiter: { month: '', check: false, sum: '', percent: '' }[],
    ostatok: string,
    plan_sum: string,
    ostatok_day: string,
    ostatok_background: string
    forecast: string
  } = {
    delimiter: [],
    all_turnover: '0',
    ostalos_revenue: '0',
    ostatok: '0',
    plan_sum: '0',
    ostatok_day: '0',
    ostatok_background: '0',
    forecast: '0'
  };
  sortMoreInfoDesc = true;
  sortMoreInfoDescNumber = true;
  sortWearEq = true;
  sortDebtsDefault = true;
  sortDebtsCourt = true;
  sortDebtors = true;
  sortDebtsHopeless = true;
  sortDebtsAmount = true;
  ratings: { branch_name: '', revenue: '', img: '', conversion_a: '', zayavki: '' }[] = [];
  loadingEqListSum = 0;
  // долги клиентов
  debtorSumBranch: { branch: '', sum1: '' ,sum2: '' ,sum3: '' ,sum4: '' ,sum: '' }[] = [];
  // загрузка оборудования
  loadingEqList: { branch: '', sum: 0, percent: 0 }[] = [];
  // износ оборудования
  wearEqList: { branch: '', sum: 0 }[] = [];
  debtsShow = false;
  loadingEqShow = false;
  wearEqShow = false;
  dashboardMoreInfoData = {
    status: '',
    data: [],
  };
  dashboardMoreInfoDataFeedbackModal = false;
  dashboardMoreInfoDataFeedback = {
    status: '',
    data: [],
  };
  dashboardShowMoreInfo = false;

  selectYear = moment().format('YYYY');
  currentMonth = {month: '', check: false, sum: '', percent: ''};

  constructor(private dopParamsService: DopParamsService,
              private equipmentsService: EquipmentsService,
              public globalParamsUser: GlobalParamsUser,
              public globalParams: GlobalParams,
              private mainService: MainService,
              private stockService: StockService,
              private profileService: ProfileService,
              private router: Router) {
    this.createYears();
    this.clearDates();
  }

  ngOnInit() {
    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = [];
        this.branches = [{'val': 0, 'name': 'Все'}].concat(data);
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
  }


  getData() {
    this.mainService.getData({
      branch: this.filter.branch,
      date_start: moment(this.filter.date_start).format().slice(0, 10),
      date_end: moment(this.filter.date_end).format().slice(0, 10)
    }).then((data: any) => {
        const incomeF = data.income.first;
        const incomeS = data.income.second;
        this.incomes = Object.keys(incomeF).map(i => incomeF[i]);
        this.incomesS = Object.keys(incomeS).map(i => incomeS[i]);
        this.equipments = data.equipments;
        this.manager_plan.delimiter = data.manager_plan;
        this.manager_plan.all_turnover = data.all_turnover;
        this.manager_plan.forecast = data.forecast;
        this.manager_plan.ostalos_revenue = data.ostalos_revenue;
        this.ratings = data.ratings;
        this.debtorSumBranch = data.income.debtorSumBranch;
        this.loadingEqList = data.income.loadingEqList;
      },
      (error) => {
        console.log('Ошибка при получении информации для рабочего стола: ', error);
      });
  }

  clearDates() {
    this.typeFilter = 1;
    const newDate = new Date();
    const currentDateYear = newDate.getFullYear();
    let currentDateMonth: any = newDate.getMonth() + 1;
    let currentDateDay: any = (newDate.getDate());

    currentDateMonth = (currentDateMonth < 10 ? '0' + currentDateMonth : currentDateMonth).toString();
    currentDateDay = currentDateDay < 10 ? '0' + currentDateDay : currentDateDay;

    this.date_create_month = currentDateMonth.toString();
    this.date_create_day = currentDateDay.toString();
    this.date_create_year = currentDateYear;
    this.date_end_day = currentDateDay.toString();
    this.date_end_month = currentDateMonth.toString();
    this.date_end_year = currentDateYear;

    this.filter.date_start = this.date_create_year + '-' + this.date_create_month + '-' + this.date_create_day;
    this.filter.date_end = this.date_end_year + '-' + this.date_end_month + '-' + this.date_end_day;
    this.getData();
  }

  createYears() {
    const cDate = new Date();
    const cDateYear = cDate.getFullYear();
    let last5Years = new Date(cDate.setFullYear(cDateYear - 5)).getFullYear();
    let i = 1;
    while (last5Years <= cDateYear) {
      this.years.push({val: last5Years, name: last5Years.toString()});
      last5Years++;
    }

    while (i <= 31) {
      const day = i < 10 ? '0' + i : i;
      this.days.push({val: day.toString(), name: i.toString()});
      i++;
    }
  }

  // изменение типа фильтра
  changeTypeFilter(type) {

    this.filter.date_end = moment().format();
    if (type === 1) {
      // сутки
      this.filter.date_start = this.filter.date_end;
    } else if (type === 2) {
      // неделя
      this.filter.date_start = moment().subtract(7, 'days');
    } else if (type === 3) {
      // месяц
      this.filter.date_start = moment().startOf('month');
    } else if (type === 4) {
      // квартал
      this.filter.date_start = moment().subtract(90, 'days');
    } else if (type === 5) {
      // год
      this.filter.date_start = moment().startOf('year');
    }

    this.date_create_day = moment(this.filter.date_start).format('DD');
    this.date_create_month = moment(this.filter.date_start).format('MM');
    this.date_create_year = +moment(this.filter.date_start).format('YYYY');

    this.date_end_day = moment(this.filter.date_end).format('DD');
    this.date_end_month = moment(this.filter.date_end).format('MM');
    this.date_end_year = +moment(this.filter.date_end).format('YYYY');

    this.filter.date_start = moment(this.filter.date_start).format('YYYY-MM-DD');
    this.filter.date_end = moment(this.filter.date_end).format('YYYY-MM-DD');
    this.typeFilter = type;

    this.getData();
  }

  changeBranch(increased: any, type: string, update = false) {
    this.filter[type] = increased;
    this.getData();
  }

  // изменение даты
  dateChange(data) {
    this.typeFilter = 0;

    if (data === 'start') {
      if (this.date_create_day !== '0' && this.date_create_month !== '0' && this.date_create_year !== 0) {
        this.filter.date_start = this.date_create_year + '-' + this.date_create_month + '-' + this.date_create_day;
      }
    } else {
      if (this.date_end_day !== '0' && this.date_end_month !== '0' && this.date_end_year !== 0) {
        this.filter.date_end = this.date_end_year + '-' + this.date_end_month + '-' + this.date_end_day;
      }
    }
    this.getData();
  }


  getProfileInfoPlan(increased) {
    this.selectYear = increased;
    this.profileService.getProfileInfoPlan({
      'branch': this.globalParams.branch.val,
      'year': this.selectYear
    }).then((result: any) => {
        this.manager_plan.delimiter = result;
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });
  }

  routLinkCurrent(data) {

    if (this.globalParamsUser.type === 2) {
      return;
    }
    let showMoreCategories = [
      'debt3',
      'debt4',
      'count_bron',
      'count_demand',
      'count_learned',
      'count_refusal',
      'pay_clients',
      'average_amount',
      'hire',
      'renewals',
      'new_customers',
      'regular_customers',
      'fiz_customers',
      'pay_clients_ur',
      'ur_customers',
      'pay_clients_fiz',
      'application_information',

    ];
    if (data.id === 'eq_rem') {
      this.stockService.status = [2];
      this.stockService.stock = this.filter.branch;
      this.router.navigate(['/stock']);
    }
    if (showMoreCategories.includes(data.id)) {
      this.mainService.getDataToBratchTable({
        date_start: moment(this.filter.date_start).format().slice(0, 10),
        date_end: moment(this.filter.date_end).format().slice(0, 10),
        type: data.id
      }).then((data: any) => {
        this.dashboardMoreInfoData = data;
        this.dashboardShowMoreInfo = true;
        console.log(data);
      },
      (error) => {
        console.log('Ошибка при получении данных: ', error);
      });



    }
    if (data.id === 'debt') {
      this.debtsShow = true;
    }

    if (data.id === 'loading_eq') {
      this.getLoadingEq();
    }
    if (data.id === 'feedback_statistic') {
      this.mainService.getDataToBratchTable({
        date_start: moment(this.filter.date_start).format().slice(0, 10),
        date_end: moment(this.filter.date_end).format().slice(0, 10),
        type: data.id
      }).then((data: any) => {
          this.dashboardMoreInfoDataFeedback = data;
          this.dashboardMoreInfoDataFeedbackModal = true;
          console.log(data);
        },
        (error) => {
          console.log('Ошибка при получении данных: ', error);
        });
    }

    if (data.id === 'wear') {
      this.getWearEq();
    }
  }

  getLoadingEq() {
    this.mainService.getLoadingEq().then((result: any) => {
        this.loadingEqShow = true;
        this.loadingEqListSum = 0;
        this.loadingEqList = result;
        result.forEach(data => {
          this.loadingEqListSum += data.sum;
        });
      },
      (error) => {
        console.log('Ошибка при получении: ', error);
      });
  }
  changeSortDebts(type, value) {
    if (this[type]) {
      this.debtorSumBranch.sort(function(a, b) { return a[value] - b[value]; });
    } else {
      this.debtorSumBranch.sort(function (a, b) {
        return b[value] - a[value];
      });
    }

    this[type] = !this[type];
  }
  changeSortWearEq() {
    if (this.sortWearEq) {
      this.wearEqList.sort(function(a, b) { return a.sum - b.sum; });
    } else {
      this.wearEqList.sort(function (a, b) {
        return b.sum - a.sum;
      });
    }
    this.sortWearEq = !this.sortWearEq;
  }
  changeSortLoadingModal() {
    if (this.sortMoreInfoDescNumber) {
      this.loadingEqList.sort(function(a, b) { return a.sum - b.sum; });
    } else {
      this.loadingEqList.sort(function (a, b) {
        return b.sum - a.sum;
      });
    }
    this.sortMoreInfoDescNumber = !this.sortMoreInfoDescNumber;
  }
  changeSortLoadingNumber() {
    if (this.sortMoreInfoDescNumber) {
      this.loadingEqList.sort(function(a, b) { return a.percent - b.percent; });
    } else {
      this.loadingEqList.sort(function (a, b) {
        return b.percent - a.percent;
      });
    }
    this.sortMoreInfoDescNumber = !this.sortMoreInfoDescNumber;
  }
  changeSortMoreInfoNumber() {
    if (this.sortMoreInfoDesc) {
      this.dashboardMoreInfoData.data.sort(function(a, b) { return a.percent - b.counter; });
    } else {
      this.dashboardMoreInfoData.data.sort(function (a, b) {
        return b.counter - a.counter;
      });
    }
    this.sortMoreInfoDesc = !this.sortMoreInfoDesc;
  }
  changeSortMoreInfo() {
    console.log('sort by procent - counter', this.dashboardMoreInfoData.data);
    if (this.sortMoreInfoDesc) {
      console.log('step 1 ', this.dashboardMoreInfoData.data[0].percent);
      if (this.dashboardMoreInfoData.data[0].percent) {
        this.dashboardMoreInfoData.data.sort(function(a, b) { return a.percent - b.percent; });
      } else {
        this.dashboardMoreInfoData.data.sort(function(a, b) { return a.counter - b.counter; });
      }

    } else {
      if (this.dashboardMoreInfoData.data[0].percent >= 0 ) {
        console.log('else by percent');
        this.dashboardMoreInfoData.data.sort(function (a, b) {
          return b.percent - a.percent;
        });
      } else {
        console.log('else byu counter', this.dashboardMoreInfoData.data[0].percent);
        this.dashboardMoreInfoData.data.sort(function (a, b) {
          return b.counter - a.counter;
        });
      }
    }
    this.sortMoreInfoDesc = !this.sortMoreInfoDesc;
  }
  getWearEq() {
    this.mainService.getLoadingWear().then((result: any) => {
        this.wearEqShow = true;
        this.wearEqList = result;
      },
      (error) => {
        console.log('Ошибка при получении: ', error);
      });
  }
}
