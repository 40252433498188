import {Component} from '@angular/core';
import {GlobalParams} from '../../storage/global-params';
import {ShortHireService} from '../short_hire/short_hire.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {AuthService} from '../auth/auth.service';
import {NotificationsService} from './notifications.service';
import {ApplicationsMobileService} from '../applications_mobile/applicationsMobile.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.scss']
})
export class NotificationsComponent {
  count = 0;
  currenTab = 'call';

  counts = {
    'call': '',
    'hire': '',
    'client': ''
  };

  lists = [
    /*{name: 'Звонки', val: 'call'},*/
    {name: 'На прокат', val: 'hire'},
    {name: 'Подтверждение личности', val: 'client'},
  ];

  constructor(public globalParams: GlobalParams,
              public shortHireService: ShortHireService,
              public authService: AuthService,
              private notificationsService: NotificationsService,
              private applicationsMobileService: ApplicationsMobileService,
              public globalParamsUser: GlobalParamsUser) {

    this.authService.refreshAuthClientInfo.subscribe(() => {
      const _this = this;
      /*this.globalParams.timerId = setInterval(function () {
        _this.getShort();
      }, 6000);*/
      _this.getNotifications();
    });
  }

  getNotifications() {
    this.count = 0;
    this.notificationsService.getClientNotConfirmationCrm({
      offset: 0,
    }).then((data: { count: string }) => {
        this.count += parseInt(data.count, 10);
        this.counts.client = data.count;
      },
      (error) => {
        console.log('Ошибка при получении списка заявок: ', error);
      });

    /*this.notificationsService.getApplicationProcessed({
      branch: '',
      date_start: '',
      date_end: '',
      sort: {},
      offset: 0,
    }).then((data: { data: InterFaceApplicationsProcessed[], hiresCount: string }) => {
        this.count += parseInt(data.hiresCount);
        this.counts.call = data.hiresCount;
      },
      (error) => {
        console.log('Ошибка при получении списка заявок: ', error);
      });*/

    this.applicationsMobileService.getApplicationsMobileForCrm({
      limit: 0,
      offset: 0,
    })
      .then((data: { data: InterFaceApplicationsMobile[], applicationsCount: string }) => {
          this.count += parseInt(data.applicationsCount);
          this.counts.hire = data.applicationsCount;
        },
        (error) => {
          console.log('Ошибка при получении списка заявок: ', error);
        });
  }
}
