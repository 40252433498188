import {Component} from '@angular/core';
import {SettingsCarouselService} from './settingsCarousel.service';

@Component({
  selector: 'app-settings-carousel',
  templateUrl: './settingsCarousel.component.html',
})
export class SettingsCarouselComponent {
  list: {
    id: number;
    imageUrl: any;
    text: string;
    link: string;
    photo: any;
  }[] = [];
  // загрузка изображения
  load_file: any = [];

  newList: {
    id: number;
    imageUrl: string;
    text: string;
    link: string;
    photo: any;
  } = {
    id: null,
    imageUrl: '',
    text: '',
    link: '',
    photo: ''
  };

  constructor(private settingsCarouselService: SettingsCarouselService) {
    this.getInfo();
  }

  getInfo() {
    this.settingsCarouselService.getSiteCarousel().then((data: any) => {
        this.list = data;
      },
      (error) => {
        console.log('Ошибка при получене настроек сайта: ', error);
      });
  }

  update() {
    this.settingsCarouselService.updateSiteCarousel({
      id: this.newList.id,
      text: this.newList.text,
      image: this.newList.imageUrl,
      link: this.newList.link,
      photo: this.newList.photo,
    }).then(() => {

        this.newList = {
          id: null,
          imageUrl: '',
          text: '',
          link: '',
          photo: ''
        };
        this.getInfo();
      },
      (error) => {
        console.log('Ошибка при обновлении плана: ', error);
      });
  }

  deleteSiteCarousel(index) {
    this.settingsCarouselService.deleteSiteCarousel({
      id: index,
    }).then(() => {

        this.clear();
        this.getInfo();
      },
      (error) => {
        console.log('Ошибка при обновлении плана: ', error);
      });
  }

  deleteImageSiteCarousel(index) {
    this.settingsCarouselService.deleteImageSiteCarousel({
      id: index,
    }).then(() => {

        this.clear();
        this.getInfo();
      },
      (error) => {
        console.log('Ошибка при обновлении плана: ', error);
      });
  }

  clear() {
    this.newList = {
      id: null,
      imageUrl: '',
      text: '',
      link: '',
      photo: ''
    };
  }

  addNotNewPhoto(value, event) {
    this.newList = {
      id: value.id,
      text: value.text,
      link: value.link,
      photo: '',
      imageUrl: ''
    };

    this.addPhoto(event);

    setTimeout(() => {
      this.update();
    }, 2000);
  }

  // добавить фото
  addPhoto(event) {
    const target = event.target || event.srcElement;

    for (let j = 0; target.files.length > j; j++) {
      this.load_file = target.files[j];
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this, name);
      reader.readAsBinaryString(this.load_file);
    }
  }

  _handleReaderLoaded(data, readerEvt) {
    const binaryString = readerEvt.target.result;
    this.newList.photo = btoa(binaryString);
    this.newList.imageUrl = this.load_file.name;
  }

  showButton() {
    return this.newList.id === null ? 'Добавить' : 'Изменить';
  }

  changeItems(data) {
    this.newList = {
      id: data.id,
      text: data.text,
      link: data.link,
      photo: '',
      imageUrl: ''
    };
  }
}
