import {Component} from '@angular/core';
import {ApplicationsMobileService} from '../applications_mobile/applicationsMobile.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {ApplicationsService} from '../applications/applications.service';
import {GlobalParams} from '../../storage/global-params';
import * as moment from 'moment';
import {ApplicationsCreateService} from '../applications-create/applicationsCreate.service';
import {DocumentService} from '../../services/document.service';
import {ClientInfoService} from '../client_info/clientInfo.service';
import {FinanceService} from '../finance/finance.service';
import {DopParamsService} from '../../services/dopParams.service';
import {ApplicationsMobileInfoService} from './applicationsMobileInfo.service';

@Component({
  selector: 'app-applications-mobile-info',
  templateUrl: './applicationsMobileInfo.component.html',
})
export class ApplicationsMobileInfoComponent {
  applicationsDelivery: InterFaceDopParams[] = [];
  applicationsTypeLeases: InterFaceDopParams[] = [];
  delivery: InterFaceDopParams[] = [];
  application: InterFaceApplicationsMobileInfo = {
    id: null,
    typeLease: null,
    payment_type: '',
    client_id: null,
    month_sum: '',
    square: '',
    address: '',
    delivery: null,
    delivery_address: '',
    branch: '',
    branch_id: null,
    eq_state: '',
    rent_start: '',
    rent_end: '',
    countDay: '',
    sum_zalog: '',
    sale_in_sum: '',
    delivery_sum: '',
    sum: '',
    sale: '',
    name_spare: '',
    phone_spare: '',
    email_spare: '',
    dop_status: null,
    source: '',
    client_fio: '',
    client_phone: '',
    equipments: [],
    payments: [],
    client: {
      type: ''
    }
  };

  allSum = 0;
  // список доп статусов для заявок
  applicationDopStatusList: InterFaceDopParamsColor[] = [];

  sms = {
    show: false,
    phone: '',
    templateId: '',
    txt: '',
  };

  hireId = null;

  // добавление платежа
  addPay = {
    equipment_id: null,
    show: false,
    sum: '',
    cashBox: null,
    revertSum: false,
    currentDate: '',
    branch: null
  } as any;

  showFinanceCashBox: InterFaceFinanceCashBox[];
  financeCashBox: InterFaceFinanceCashBox[];
  branches: InterFaceDopParams[] = [];
  checkPledge = false;
  checkDelivery = false;

  constructor(private applicationsMobileService: ApplicationsMobileService,
              private router: Router,
              public globalParamsUser: GlobalParamsUser,
              public applicationsMobileInfoService: ApplicationsMobileInfoService,
              public globalParams: GlobalParams,
              private activatedRoute: ActivatedRoute,
              public applicationsCreateService: ApplicationsCreateService,
              private applicationsService: ApplicationsService,
              private documentService: DocumentService,
              private clientInfoService: ClientInfoService,
              private financeService: FinanceService,
              private dopParamsService: DopParamsService,
              private globalParamsMessage: GlobalParamsMessage) {

    this.activatedRoute.params.subscribe(
      (params: Params): void => {
        this.hireId = params.id;
      }
    );

    this.applicationsCreateService.getApplicationsDelivery().then((data: InterFaceDopParams[]) => {
        this.applicationsDelivery = data;
        this.application.delivery = this.applicationsDelivery[1].val;
      },
      (error) => {
        console.log('Ошибка при получении статусов доставки: ', error);
      });

    this.applicationsCreateService.getApplicationsTypeLease().then((data: InterFaceDopParams[]) => {
        this.applicationsTypeLeases = data;
        this.application.typeLease = data[0].val;
        this.changeTypeLease();
      },
      (error) => {
        console.log('Ошибка при получении типов аренды: ', error);
      });

    this.applicationsService.getApplicationsDopStatus().then((data: InterFaceDopParamsColor[]) => {
        this.applicationDopStatusList = data;
      },
      (error) => {
        console.log('Ошибка при получении статусов заявок: ', error);
      });

    this.applicationsCreateService.getApplicationsDelivery().then((data: InterFaceDopParams[]) => {
        this.delivery = data;
      },
      (error) => {
        console.log('Ошибка при получении статусов доставки: ', error);
      });

    this.financeService.getFinanceCashBOx().then((data: InterFaceFinanceCashBox[]) => {
        this.financeCashBox = data;
        this.showFinanceCashBox = this.financeCashBox.filter(item => item.zalog === '0' && item.delivery === '0');
      },
      (error) => {
        console.log('Ошибка при получении касс: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.getApplication();
  }

  getApplication() {
    this.applicationsMobileService.getApplicationsInfoMobileForCrm({
      id: this.hireId
    })
      .then((data: InterFaceApplicationsMobileInfo) => {
          this.application = data;
          this.changeSum();
        },
        (error) => {
          console.log('Ошибка при получении заявки: ', error);
        });
  }

  // создание заявки
  addApplication(checkSaveWorld) {
    this.applicationsCreateService.addApplication({
      equipments: this.application.equipments,
      typeLease: this.application.typeLease,
      sale: this.application.sale,
      sale_in_sum: this.application.sale_in_sum,
      delivery: this.application.delivery,
      client_id: this.application.client_id,
      sum: this.application.sum,
      srok: '',
      delivery_sum: this.application.delivery_sum,
      comment: '',
      branch: this.application.branch_id,
      status: 1,
      lesa: false,
      month_sum: this.application.month_sum,
      square: this.application.square || 0,
      address: this.application.address,
      client_phone: '',
      id_callibri: null,
      callibri_type: '',
      delivery_address: this.application.delivery_address,
      span_demand: '',
      from_client_id: this.application.id,
    }).then((result: any) => {
        this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
        this.globalParams.getShortHire = 1;

        this.applicationsMobileInfoService.deleteApplicationsInfoMobileForCrm({
          id: this.application.id,
        }).then(() => {
            if (checkSaveWorld && result.length > 0) {
              const _this = this;
              _this.documentService.getWord({hireInfoId: result[0], lesa: 0, check: 1, multiple: true}).then((resultDoc: any) => {
                  window.open(_this.globalParams.path + '/uploads/tmp/' + resultDoc, '_blank');
                },
                (error) => {
                  console.log('Ошибка при скачивании документа: ', error);
                });
            }

            if (!checkSaveWorld) {
              result.forEach(function (value) {
                window.open('https://str-stroika-arenda.ru/hire/' + value);
              });
            }

            this.redirectTo('/application');
            this.globalParams.mobileInfo = '';
          },
          (error) => {
            console.log('Ошибка при получении статусов: ', error);
          });
      },
      (error) => {
        console.log('Ошибка при добавлении новой заявки: ', error);
      });
  }

  changeSum() {
    if (this.application.equipments.length === 0) {
      return true;
    }

    if (+this.application.sale_in_sum > +this.globalParamsUser.settings.sale_rub_limit) {
      this.application.sale_in_sum = this.globalParamsUser.settings.sale_rub_limit;
      this.globalParamsMessage.data = {
        title: `Максимальная скидка ` + this.globalParamsUser.settings.sale_rub_limit,
        type: 'error',
        body: ''
      };
    }
    if (+this.application.sale > +this.globalParamsUser.settings.sale_percent_limit) {
      this.application.sale = this.globalParamsUser.settings.sale_percent_limit;
      this.globalParamsMessage.data = {
        title: `Максимальная скидка ` + this.globalParamsUser.settings.sale_percent_limit,
        type: 'error',
        body: ''
      };
    }
    this.application.delivery_sum = this.application.delivery === 1 ? this.application.delivery_sum : '0';

    this.applicationsService.hireChangeSum({
      equipments: this.application.equipments,
      sale: this.application.sale,
      sale_in_sum: this.application.sale_in_sum,
      typeLease: this.application.typeLease,
      lesa: 0,
      month_sum: 0
    }).then((result: any) => {
        this.application.sum = result.sum;
        this.application.equipments = result.equipments;
        this.allSum = parseFloat(this.application.sum) + parseFloat(this.application.delivery_sum);
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });
  }

  changeTypeLease(dt = null) {
    if (dt !== null) {
      this.application.typeLease = dt;
    }
    const time_diff = 0;
    const _this = this;
    this.application.equipments.forEach(function (value) {
      if (value.rent_start === null) {
        value.rent_start = moment().format().slice(0, 16);
      } else {
        value.rent_start = moment(value.rent_start).format().slice(0, 16);
      }

      const tmpDate = moment(value.rent_start);

      if (time_diff === 0) {
        if (_this.application.typeLease === 1) {
          value.rent_end = tmpDate.add(24, 'hours').format().slice(0, 10);
        } else {
          value.rent_end = tmpDate.add(30, 'day').format().slice(0, 10);
        }
      } else {
        const currentDate = moment().add(time_diff, 'hours').format();
        value.rent_start = currentDate.slice(0, 16);

        if (_this.application.typeLease === 1) {
          value.rent_end = tmpDate.add(24 + time_diff, 'hours').format().slice(0, 10);
        } else {
          value.rent_end = tmpDate.add(30 + time_diff, 'day').format().slice(0, 10);
        }
      }

      value.countDay = 1;
    });

    this.changeSum();
  }

  changeCountDayOne(type, index) {
    const equipments = this.application.equipments[index];

    if (type === 'plus') {
      if (this.application.typeLease === 1) {
        equipments.rent_end = moment(equipments.rent_end).add(24, 'hours').format().slice(0, 10);
      } else {
        equipments.rent_end = moment(equipments.rent_end).add(30, 'day').format().slice(0, 10);
      }
    } else {
      if (this.application.typeLease === 1) {
        equipments.rent_end = moment(equipments.rent_end).subtract(24, 'hours').format().slice(0, 10);
      } else {
        equipments.rent_end = moment(equipments.rent_end).subtract(30, 'day').format().slice(0, 10);
      }
    }

    this.changeSum();
  }

  changeCountDay(index) {
    const equipments = this.application.equipments[index];
    const tmpDate = moment(equipments.rent_start);

    if (this.application.typeLease === 1) {
      equipments.rent_end = tmpDate.add(24 * equipments.countDay, 'hours').format().slice(0, 10);
    } else {
      equipments.rent_end = tmpDate.add(30 * equipments.countDay, 'day').format().slice(0, 10);
    }

    this.changeSum();
  }

  onChangedCreate(data) {
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
  }

  deleteApplicationsInfoMobileForCrm() {
    this.applicationsMobileInfoService.deleteApplicationsInfoMobileForCrm({
      id: this.application.id,
    }).then(() => {
        this.router.navigateByUrl('application');
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });
  }


  onChanged(increased: any, type: string) {
    this.application[type] = increased;
  }

  changeSms(phone) {
    this.sms = {
      show: true,
      phone: phone,
      templateId: '',
      txt: ''
    };
  }

  // показ модального окна для добавления платежа
  showInsertSum(equipment_id) {
    this.addPay = {
      equipment_id,
      show: true,
      sum: '',
      cashBox: null,
      revertSum: false,
      currentDate: moment().format().slice(0, 16),
      branch: this.application.branch
    };
  }

  clear() {
    this.addPay = {equipment_id: null, show: false, sum: '', cashBox: null, revertSum: false, currentDate: '', branch: null};
  }

  onChangedAddPay(increased: any, type: string) {
    this.addPay[type] = increased;
  }

  // возврат платежа
  changeRevert() {
    this.addPay.revertSum = !this.addPay.revertSum;
  }

  // добавляем платеж
  addPayList() {
    if (this.addPay.sum === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать сумму', type: 'error', body: ''};
      return false;
    }

    if (this.addPay.cashBox === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }

    if (this.checkDelivery && this.checkPledge) {
      this.globalParamsMessage.data = {title: 'Нельзя выбрать и "залог" и "доставка"!', type: 'error', body: ''};
      return false;
    }

    const fCashBox = this.financeCashBox.filter(item => item.val === this.addPay.cashBox)[0];
    const fCashBoxTxt = fCashBox.name.split(' - ');

    let fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.zalog === '0' && item.delivery === '0');

    if (this.checkDelivery) {
      fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.delivery === '1');
    }

    if (this.checkPledge) {
      fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.zalog === '1');
    }

    if (fCashBoxCurrent.length === 0) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }
    this.addPay.cashBox = fCashBoxCurrent[0].val;

    const eq = this.application.equipments.filter(item => item.id === this.addPay.equipment_id);

    eq[0].payList.push({
      sum: this.addPay.sum,
      cashBox: this.addPay.cashBox,
      revertSum: this.addPay.revertSum,
      currentDate: this.addPay.currentDate,
      branch: this.addPay.branch
    });
    this.clear();
  }
}
