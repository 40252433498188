import {Component} from '@angular/core';
import {FinanceService} from '../finance/finance.service';
import {DopParamsService} from '../../services/dopParams.service';

@Component({
  selector: 'app-finance-zalog',
  templateUrl: './financeZalog.component.html',
})
export class FinanceZalogComponent {
  financeZalog: InterFaceFinanceZalog[];
  branches: InterFaceDopParams[] = [];
  cashBoxListFront: InterFaceFinanceZalogCashBox[] = [];
  totalList: InterFaceFinanceZalogTotal;

  constructor(private financeService: FinanceService,
              private dopParamsService: DopParamsService) {
    this.financeService.getFinanceZalog().then((data: { data: InterFaceFinanceZalog[], cashBoxListFront: InterFaceFinanceZalogCashBox[], totalList: InterFaceFinanceZalogTotal }) => {
        this.financeZalog = data.data;
        this.cashBoxListFront = data.cashBoxListFront;
        this.totalList = data.totalList;
      },
      (error) => {
        console.log('Ошибка при получении категорий: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });
  }
}
