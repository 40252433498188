import {Component} from '@angular/core';
import {SettingsLevelService} from './settingsLevel.service';
import {GlobalParams} from '../../storage/global-params';

@Component({
  selector: 'app-settings-level',
  templateUrl: './settingsLevel.component.html',
})
export class SettingsLevelComponent {
  list: InterFaceSettingsLevel[] = [];
  // загрузка изображения
  load_file: any = [];
  fileString = '';

  level = {file: '', photo: ''};

  showChangeDetails = false;
  detailsInfo: InterFaceSettingsLevel = {
    id: 0,
    level: '',
    sum: '',
    title: '',
    description: '',
    photo: '',
    file: ''
  };

  constructor(private settingsLevelService: SettingsLevelService,
              public globalParams: GlobalParams) {
    this.getSettingsLevel();
  }

  getSettingsLevel() {
    this.settingsLevelService.getSettingsLevel().then((data: InterFaceSettingsLevel[]) => {
        this.list = data;
      },
      (error) => {
        console.log('Ошибка при получене настроек уровней: ', error);
      });
  }

  change() {
    this.showChangeDetails = false;
    this.settingsLevelService.setSettingsLevel(this.detailsInfo).then(() => {
        this.getSettingsLevel();
      },
      (error) => {
        console.log('Ошибка при обновлении плана: ', error);
      });
  }

  // добавить фото
  addPhoto(event) {
    const target = event.target || event.srcElement;
    for (let j = 0; target.files.length > j; j++) {
      this.load_file = target.files[j];
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this, name);
      reader.readAsBinaryString(this.load_file);
    }

    this.detailsInfo.file = this.level.file;
    this.detailsInfo.photo = this.level.photo;
  }

  _handleReaderLoaded(data, readerEvt) {
    const binaryString = readerEvt.target.result;
    this.fileString = btoa(binaryString);

    this.detailsInfo.file = this.load_file.name;
    this.detailsInfo.photo = this.fileString;
  }

  changeDetails(item) {
    this.showChangeDetails = true;
    this.detailsInfo = item;
    this.detailsInfo.photo = '';
  }
}
