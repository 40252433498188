import {Injectable} from '@angular/core';

@Injectable()
export class GlobalParamsPay {
  data: any = {
    show: false,
    sum: '',
    cashBox: null,
    app_id: null,
    revertSum: false,
    branch: null
  };
}
