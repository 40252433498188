import {Component, OnInit} from '@angular/core';
import {DopParamsService} from '../../services/dopParams.service';
import {StockService} from './stock.service';
import {EquipmentsService} from '../equipments/equipments.service';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {GlobalParamsComingLesa} from '../coming_lesa/global-params-coming-lesa';
import {GlobalParamsConsumptionLesa} from '../consumption_lesa/global-params-consumption_lesa';
import {GlobalParamsLesaHistory} from '../lesa_history/global-params-lesa-history';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {ApplicationsCreateService} from '../applications-create/applicationsCreate.service';
import {FinanceService} from '../finance/finance.service';
import {GlobalParams} from '../../storage/global-params';
import * as moment from 'moment';
import {count} from 'rxjs/operators';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
})
export class StockComponent implements OnInit {
  p = 1;
  stocks: InterFaceStocks[] = [];
  equipmentsTypeList: InterFaceDopParams[] = [];
  equipmentsStatusList: InterFaceDopParamsColor[] = [];
  equipmentsCategoryList: InterEquipmentsCategory[] = [];

  // отображение фильтра
  showFilters = false;
  // отображение фильтра
  showActiveFields = false;

  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};
  // массив с оборудованием для управления списком
  eqListChange = [];
  equipmentId = {show: false, id: null};
  showEquipmentModal = false;
  equipmentsMarkList: InterFaceDopParams[] = [];

  eqList = {
    show: false,
    listId: [],
    category: '',
    model: '',
    mark: '',
    type: '',
    stok: '',
    price_per_day: '',
    selling: '',
    selling_price: ''
  };

  equipmentsList: InterFaceEquipmentsStock[] = [{
    id: '',
    name: '',
    category: '',
    stock: '',
    type: '',
    status: '',
    selling_price: '',
    price_per_day: '',
    rentals: '',
    repairs: '',
    repairs_sum: '',
    tool_number: '',
    revenue: '',
    profit: '',
    degree_wear: '',
    payback_ratio: '',
    comment: '',
    dop_status: '',
    count: '',
    count_hire: '',
    count_left: '',
    eq_sum: '',
    date_create: '',
    status_id: null,
    branch_id: '',
    old_status: null,
    date_write_off: ''
  }];

  statusS = [];

  filters: InterFaceFilterEquipments = {
    status: this.stockService.status,
    like: '',
    stock: 0,
    equipmentsType: 0,
    equipmentsCategory: 0,
    count_start: null,
    count_end: null,
    selling_price_start: '',
    selling_price_end: '',
    price_per_day_start: '',
    price_per_day_end: '',
    rentals_start: null,
    rentals_end: null,
    repairs_start: null,
    repairs_end: null,
    repairs_sum_start: '',
    repairs_sum_end: '',
    revenue_start: '',
    revenue_end: '',
    profit_start: '',
    profit_end: '',
    degree_wear_start: null,
    degree_wear_end: null,
    lesa: false,
    selling_price: '',
    price_per_day: '',
    is_not_active: 2,
    rental: '',
    repairs: '',
    repairs_sum: '',
    tool_number: '',
    revenue: '',
    profit: '',
    degree_wear: '',
    payback_ratio: '',
    dop_status: '',
    count: '',
    count_hire: '',
    eq_sum: '',
    comment: '',
    sort: {},
    offset: 0,
    limit: 50
  };

  // всплывающее окно
  tooltip = {
    show: false,
    data: {}
  };

  sortedData: InterFaceEquipmentsStock[];

  sum = {sum_selling: 0, selling_price: 0, price_per_day_procent: 0};

  eqCount = null;

  dropdownSettingsStatus = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };
  timerId = null;

  currentEqIndex = null;

  // выбранное оборудование
  selectEq = [];

  // выбранные поля
  showField = false;

  fields = {
    'phone': '',
    'comment': '',
    'date_start': '',
    'reason_change_status': moment().format('YYYY-MM-DD'),
    'sale_amount': 0,
    'cash_box': '',
    'amount_repair': 0
  };

  // кассы
  financeCashBox: InterFaceFinanceCashBox[];

  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  public closeEqChangeTmp: Function;

  constructor(private stockService: StockService,
              private dopParamsService: DopParamsService,
              private equipmentsService: EquipmentsService,
              public globalParamsUser: GlobalParamsUser,
              public globalParamsLesaHistory: GlobalParamsLesaHistory,
              public globalParamsComingLesa: GlobalParamsComingLesa,
              public globalParamsConsumptionLesa: GlobalParamsConsumptionLesa,
              public globalParamsMessage: GlobalParamsMessage,
              public globalParams: GlobalParams,
              public applicationsCreateService: ApplicationsCreateService,
              public financeService: FinanceService,
              private router: Router) {
  }

  ngOnInit() {
    this.closeEqChangeTmp = this.closeEqChange.bind(this);

    this.equipmentsService.getEquipmentsCategory().then((data: InterEquipmentsCategory[]) => {
        this.equipmentsCategoryList = [{val: 0, name: 'Все', type: []}].concat(data);
        this.equipmentsTypeList = [{val: 0, name: 'Все'}].concat(this.equipmentsCategoryList[0].type);
      },
      (error) => {
        console.log('Ошибка при получении списка категорий оборудования: ', error);
      });
    this.dopParamsService.getStock().then((data: InterFaceStocks[]) => {
        this.stocks = [{val: 0, name: 'Все', branch_id: null}].concat(data);

        if (this.stockService.stock !== 0) {
          this.filters.stock = this.stocks.filter(item => item.branch_id === this.stockService.stock)[0].val;
          this.getEquipments();
          this.stockService.stock = 0;
          this.stockService.status = [];
        } else {
          this.getEquipments();
        }
      },
      (error) => {
        console.log('Ошибка при получении складов: ', error);
      });

    this.equipmentsService.getEquipmentsMark().then((data: InterFaceDopParams[]) => {
        this.equipmentsMarkList = data;
      },
      (error) => {
        console.log('Ошибка при получении списка статусов оборудования: ', error);
      });

    this.equipmentsService.getEquipmentsStatus().then((data: InterFaceDopParamsColor[]) => {
        this.equipmentsStatusList = data;
      },
      (error) => {
        console.log('Ошибка при получении списка статусов оборудования: ', error);
      });

    this.equipmentsService.getEquipmentsFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении списка полей оборудования: ', error);
      });

    this.financeService.getFinanceCashBOx().then((data: InterFaceFinanceCashBox[]) => {
        this.financeCashBox = data;
      },
      (error) => {
        console.log('Ошибка при получении касс: ', error);
      });
  }

  // отображение фильтра
  changeShowFilters() {
    this.showFilters = !this.showFilters;
  }

  // отображение фильтра
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }

  // изменение прав у нового пользователя
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag;

      if (['count', 'count_hire', 'count_repairs', 'count_left'].indexOf(value.code) !== -1) {
        this.activeFieldsTables[value.code] = value.flag = !this.filters.lesa ? 0 : 1;
      }
    }
  }

  // изменение списка активных полей
  changeFields() {
    this.equipmentsService.changeFields({data: this.activeFields}).then(() => {
        this.showActiveFields = false;
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }

  changeStatus(data) {
    this.filters.is_not_active = data;
    this.getEquipments();
  }

  // получение списка оборудования
  getEquipments() {
    this.equipmentsService.getEquipments({
      like: this.filters.like,
      stock: this.filters.stock,
      equipmentsType: this.filters.equipmentsType,
      equipmentsCategory: this.filters.equipmentsCategory,
      status: this.filters.status,
      count_start: this.filters.count_start,
      count_end: this.filters.count_end,
      selling_price_start: this.filters.selling_price_start,
      selling_price_end: this.filters.selling_price_end,
      price_per_day_start: this.filters.price_per_day_start,
      price_per_day_end: this.filters.price_per_day_end,
      rentals_start: this.filters.rentals_start,
      rentals_end: this.filters.rentals_end,
      repairs_start: this.filters.repairs_start,
      repairs_end: this.filters.repairs_end,
      repairs_sum_start: this.filters.repairs_sum_start,
      repairs_sum_end: this.filters.repairs_sum_end,
      revenue_start: this.filters.revenue_start,
      revenue_end: this.filters.revenue_end,
      profit_start: this.filters.profit_start,
      profit_end: this.filters.profit_end,
      degree_wear_start: this.filters.degree_wear_start,
      degree_wear_end: this.filters.degree_wear_end,
      lesa: this.filters.lesa,
      selling_price: this.filters.selling_price,
      price_per_day: this.filters.price_per_day,
      is_not_active: this.filters.is_not_active,
      rental: this.filters.rental,
      repairs: this.filters.repairs,
      repairs_sum: this.filters.repairs_sum,
      tool_number: this.filters.tool_number,
      revenue: this.filters.revenue,
      profit: this.filters.profit,
      degree_wear: this.filters.degree_wear,
      payback_ratio: this.filters.payback_ratio,
      dop_status: this.filters.dop_status,
      count: this.filters.count,
      count_hire: this.filters.count_hire,
      eq_sum: this.filters.eq_sum,
      sort: Object.keys(this.filters.sort).length !== 0,
      offset: this.filters.offset,
      limit: this.filters.limit,
      comment: this.filters.comment,
    }).then((data: { data: InterFaceEquipmentsStock[], sum_selling: number, selling_price: number, count: number, price_per_day: number }) => {
        this.sum.sum_selling = data.sum_selling;
        this.sum.selling_price = data.selling_price;
        this.sum.price_per_day_procent = data.price_per_day;
        this.equipmentsList = data.data;
        this.sortedData = this.equipmentsList.slice();
        this.showFilters = false;
        this.eqCount = data.count;


        if (Object.keys(this.filters.sort).length !== 0) {
          this.sortData(this.filters.sort);
        }
      },
      (error) => {
        console.log('Ошибка при получении списка оборудований: ', error);
      });
  }

  // очистка фильтра
  clearFilter() {
    this.filters = {
      like: '',
      stock: 0,
      equipmentsType: 0,
      equipmentsCategory: 0,
      status: [],
      count_start: null,
      count_end: null,
      selling_price_start: '',
      selling_price_end: '',
      price_per_day_start: '',
      price_per_day_end: '',
      rentals_start: null,
      rentals_end: null,
      repairs_start: null,
      repairs_end: null,
      repairs_sum_start: '',
      repairs_sum_end: '',
      revenue_start: '',
      revenue_end: '',
      profit_start: '',
      profit_end: '',
      degree_wear_start: null,
      degree_wear_end: null,
      lesa: false,
      selling_price: '',
      price_per_day: '',
      is_not_active: 2,
      rental: '',
      repairs: '',
      repairs_sum: '',
      tool_number: '',
      revenue: '',
      profit: '',
      degree_wear: '',
      payback_ratio: '',
      dop_status: '',
      count: '',
      count_hire: '',
      eq_sum: '',
      comment: '',
      sort: {},
      offset: 0,
      limit: 50
    };

    this.getEquipments();
  }

  mouseEnter(data) {
    let txt = '';
    if (data.status_id === 1 || data.status_id === 5) {
      txt = 'В аренде';
    }

    if (data.comment !== null) {
      txt += '\n' + data.comment;
    }

    return txt;
  }

  // управление списком оборудования
  changeListEq(event, eq_id) {
    const key = this.eqListChange.indexOf(eq_id);

    if (key === -1) {
      this.eqListChange.push(eq_id);
    } else {
      this.eqListChange.splice(key, 1);
    }
  }

  checkCheck(equipment_id) {
    return this.eqListChange.find(item => item === equipment_id);
  }

  // удаление оборудования из выделенного списка
  deleteEqList() {
    this.equipmentsService.deleteEqList({
      list: this.eqListChange
    }).then(() => {
        this.getEquipments();
      },
      (error) => {
        console.log('Ошибка при удалении списка оборудований: ', error);
        this.getEquipments();
      });
  }

  // копирование оборудования из выделенного списка
  copyEqList() {
    this.equipmentsService.copyEqList({
      list: this.eqListChange
    }).then(() => {
        this.getEquipments();
      },
      (error) => {
        console.log('Ошибка при копировании списка оборудований: ', error);
        this.getEquipments();
      });
  }

  sortData(sort: Sort) {
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.sort = sort;
      this.getEquipments();
    } else {
      const data = this.equipmentsList.slice();

      this.equipmentsList = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name':
            return compare(a.name, b.name, isAsc);
          case 'category':
            return compare(a.category, b.category, isAsc);
          case 'stock':
            return compare(a.stock, b.stock, isAsc);
          case 'type':
            return compare(a.type, b.type, isAsc);
          case 'status':
            return compare(a.status, b.status, isAsc);
          case 'selling_price':
            return compare(a.selling_price, b.selling_price, isAsc);
          case 'price_per_day':
            return compare(a.price_per_day, b.price_per_day, isAsc);
          case 'rentals':
            return compare(a.rentals, b.rentals, isAsc);
          case 'repairs':
            return compare(a.repairs, b.repairs, isAsc);
          case 'repairs_sum':
            return compare(a.repairs_sum, b.repairs_sum, isAsc);
          case 'tool_number':
            return compare(a.tool_number, b.tool_number, isAsc);
          case 'revenue':
            return compare(a.revenue, b.revenue, isAsc);
          case 'profit':
            return compare(a.profit, b.profit, isAsc);
          case 'degree_wear':
            return compare(a.degree_wear, b.degree_wear, isAsc);
          case 'payback_ratio':
            return compare(a.payback_ratio, b.payback_ratio, isAsc);
          case 'date_create':
            return compare(a.date_create, b.date_create, isAsc);
          default:
            return 0;
        }
      });
    }
  }

  // показаывать все оборудования или леса
  changeEquipments(data) {
    this.filters.lesa = data;
    this.changeShowFields();
    this.getEquipments();
  }

  // приход леса
  coming_lesa() {
    this.globalParamsComingLesa.show = true;
  }

  // расход леса
  consumption_lesa() {
    this.globalParamsConsumptionLesa.show = true;
  }

  // расход леса
  consumption_lesa_history() {
    this.globalParamsLesaHistory.show = true;
  }

  changePage(data) {
    this.p = data;

    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.offset = this.p * this.filters.limit - this.filters.limit;
      this.getEquipments();
    }
  }

  eq_excel() {
    this.stockService.eq_excel({
      like: this.filters.like,
      stock: this.filters.stock,
      equipmentsType: this.filters.equipmentsType,
      equipmentsCategory: this.filters.equipmentsCategory,
      status: this.filters.status,
      count_start: this.filters.count_start,
      count_end: this.filters.count_end,
      selling_price_start: this.filters.selling_price_start,
      selling_price_end: this.filters.selling_price_end,
      price_per_day_start: this.filters.price_per_day_start,
      price_per_day_end: this.filters.price_per_day_end,
      rentals_start: this.filters.rentals_start,
      rentals_end: this.filters.rentals_end,
      repairs_start: this.filters.repairs_start,
      repairs_end: this.filters.repairs_end,
      repairs_sum_start: this.filters.repairs_sum_start,
      repairs_sum_end: this.filters.repairs_sum_end,
      revenue_start: this.filters.revenue_start,
      revenue_end: this.filters.revenue_end,
      profit_start: this.filters.profit_start,
      profit_end: this.filters.profit_end,
      degree_wear_start: this.filters.degree_wear_start,
      degree_wear_end: this.filters.degree_wear_end,
      lesa: this.filters.lesa,
      selling_price: this.filters.selling_price,
      price_per_day: this.filters.price_per_day,
      is_not_active: this.filters.is_not_active,
      rental: this.filters.rental,
      repairs: this.filters.repairs,
      repairs_sum: this.filters.repairs_sum,
      tool_number: this.filters.tool_number,
      revenue: this.filters.revenue,
      profit: this.filters.profit,
      degree_wear: this.filters.degree_wear,
      payback_ratio: this.filters.payback_ratio,
      dop_status: this.filters.dop_status,
      count: this.filters.count,
      count_hire: this.filters.count_hire,
      eq_sum: this.filters.eq_sum,
    }).then((result: string) => {
        window.open(this.globalParams.path + '/uploads/tmp/' + result, '_blank');
      },
      (error) => {
        console.log('Ошибка при формировании отчета: ', error);
      });
  }

  changeTypeEquipmentsFilter(id) {
    const index = this.filters.status.indexOf(+id);

    if (index === -1) {
      this.filters.status.push(+id);
    } else {
      this.filters.status.splice(index, 1);
    }

    this.getEquipments();
  }

  getHireTimeOut() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }


    this.timerId = setTimeout(() => {
      this.filters.offset = 0;
      this.p = 1;
      this.getEquipments();
    }, 1000);
  }

  changeCurrentStatus(index) {
    if (this.equipmentsList[index].status_id === 3 && this.globalParamsUser.type !== 1) {
        alert('У вас нет прав на выполнение этой операции');
      return;
    }
    this.currentEqIndex = index;

    const eq = Object.assign({}, this.equipmentsList[index]);

    const tmp = {
      id: eq.id,
      name: eq.name,
      in_stock: eq.count,
      count: 1,
      price: eq.price_per_day,
      status_id: this.equipmentsList[index].status_id,
      old_status: this.equipmentsList[index].old_status,
      photo: '',
      photo_alias: '',
      allCount: eq.count,
      payList: [],
      comment: ''
    };
    this.selectEq.push(tmp);
    this.showField = true;
  }

  closeShowAddEquipments() {
    this.showField = false;
    this.equipmentsList[this.currentEqIndex].status_id = this.selectEq[0].old_status;
    this.fields = {
      'phone': '',
      'comment': '',
      'date_start': '',
      'reason_change_status': moment().format('DD.MM.YYYY'),
      'sale_amount': 0,
      'cash_box': '',
      'amount_repair': 0
    };
    this.selectEq = [];
  }

  addApplication() {

    if (this.selectEq[0].status_id === 99) {
      if (this.fields.comment === '') {
        this.globalParamsMessage.data = {title: `Необходимо указать комментарий`, type: 'error', body: ''};
        return false;
      }

      if (this.fields.phone === '') {
        this.globalParamsMessage.data = {title: `Необходимо указать телефон`, type: 'error', body: ''};
        return false;
      }

      this.applicationsCreateService.addMiniApplication({
        client_id: '',
        equipments: this.selectEq,
        comment: this.fields.comment,
        branch: this.equipmentsList[this.currentEqIndex].branch_id,
        status: 2,
        client_phone: this.fields.phone,
        processed_id: null,
        date_start: this.fields.date_start,
        span_demand: false
      }).then(() => {
          this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
          this.closeShowAddEquipments();
        },
        (error) => {
          console.log('Ошибка при добавлении новой заявки: ', error);
        });
    } else {
      if (this.selectEq[0].status_id !== this.selectEq[0].old_status) {
        if (this.selectEq[0].status_id === 4 && this.selectEq[0].old_status === 2) {
          if (this.fields.amount_repair === null) {
            this.globalParamsMessage.data = {title: 'Необходимо указать сумму ремонта', type: 'error', body: ''};
            return false;
          }

          if (this.fields.cash_box === null) {
            this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
            return false;
          }
        }

        if (this.selectEq[0].status_id !== 6) {
          if (this.fields.reason_change_status === '') {
            this.globalParamsMessage.data = {title: 'Необходимо указать причину изменения статуса', type: 'error', body: ''};
            return false;
          }
        } else {
          if (this.fields.sale_amount === 0) {
            this.globalParamsMessage.data = {title: 'Необходимо указать сумму продажи', type: 'error', body: ''};
            return false;
          }

          if (this.fields.cash_box === null) {
            this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
            return false;
          }
        }
      }

      this.equipmentsService.changeEquipmentsStatus({
        id: this.equipmentsList[this.currentEqIndex].id,
        new_status: this.equipmentsList[this.currentEqIndex].status_id,
        old_status: this.equipmentsList[this.currentEqIndex].old_status,
        reason_change_status: this.fields.reason_change_status,
        amount_repair: this.fields.amount_repair,
        cash_box: this.fields.cash_box,
        sale_amount: this.fields.sale_amount,
      }).then(() => {
          this.globalParamsMessage.data = {title: 'Изменения успешно внесены', type: 'success', body: ''};
          this.closeShowAddEquipments();
        },
        (error) => {
          console.log('Ошибка при изменении оборудования: ', error);
        });
    }
  }

  // статусы
  showEquipmentsStatusList(status) {
    let tmpStatus = Object.assign([], this.equipmentsStatusList);

    if (status === 3) {
      tmpStatus = tmpStatus.filter(item => item.val === 3 || item.val === 6 || item.val === 4);
    } else if (status === 2) {
      tmpStatus = tmpStatus.filter(item => item.val === 2 || item.val === 4 || item.val === 3 || item.val === 6);
    } else if (status === 4 || status === 7) {
      tmpStatus = tmpStatus.filter(item => item.val !== 1 && item.val !== 5);
    } else if (status === 6) {
      tmpStatus = tmpStatus.filter(item => item.val === 6);
    } else if (status === 1) {
      tmpStatus = tmpStatus.filter(item => item.val === 1);
    } else if (status === 5) {
      tmpStatus = tmpStatus.filter(item => item.val === 5);
    }

    tmpStatus.push({val: 99, name: 'Пред. бронирование'});

    return tmpStatus;
  }

  changeCount(count) {
    this.filters.limit = count;
    this.getEquipments();
  }

  checkViewLesa() {
    return document.domain === 'localhost' ||
      document.domain === 'str-stroika-arenda.ru' ||
      document.domain === '2022-test.str-stroika-arenda.ru';
  }

  changeEquipmentDegreeWear(eq_id, degree_wear) {
    if (String(degree_wear).substr(0, 1) === '0') {
      degree_wear = null;
    }

    if (degree_wear > 100) {
      degree_wear = 100;
    }

    this.stockService.change_equipment_degree_wear({
      eq_id: eq_id,
      degree_wear: degree_wear,
    }).then(() => {
        this.getEquipments();
        this.globalParamsMessage.data = {title: 'Износ оборудования успешно изменен', type: 'success', body: ''};
      },
      (error) => {
        console.log('Ошибка при изменении износа оборудования: ', error);
      });
  }

  closeEqChange() {
    this.equipmentId = {show: false, id: null};
  }

  showEq(id) {
    this.equipmentId = {id: id, show: true};
  }

  // очистка данных для массового изменения
  clearEqList() {
    this.eqList = {
      show: false,
      listId: [],
      price_per_day: '',
      type: '',
      category: '',
      stok: '',
      selling: '',
      selling_price: '',
      model: '',
      mark: '',
    };
  }

  // массовое изменение характеристик товаров
  changeEqList() {
    this.equipmentsService.changeEqList({
      list: this.eqListChange,
      category: this.eqList.category,
      type: this.eqList.type,
      stok: this.eqList.stok,
      price_per_day: this.eqList.price_per_day,
      selling: this.eqList.selling,
      selling_price: this.eqList.selling_price,
      model: this.eqList.model,
      mark: this.eqList.mark,
    }).then(() => {
        this.clearEqList();
        this.getEquipments();
        this.eqListChange = [];
      },
      (error) => {
        console.log('Ошибка при копировании списка оборудований: ', error);
        this.getEquipments();
      });
  }

  // выбрать все оборудования
  checkAllEq() {
    const _this = this;
    this.equipmentsList.forEach(function (value) {
      _this.eqListChange.push(value.id);
    });
  }

  // убрать все оборудования
  unCheckAllEq() {
    this.eqListChange = [];
  }

  changeFilter(data) {
    this.statusS = data;
    this.filters.status = this.statusS;
    this.getEquipments();
  }

  changeCategory(increased: any, update = false) {
    this.filters.equipmentsCategory = increased;
    const arr = this.equipmentsCategoryList.filter(item => item.val === increased);
    this.equipmentsTypeList = [{val: 0, name: 'Все'}].concat(arr[0].type);

    if (update) {
      this.getEquipments();
    }
  }

  onChanged(increased: any, type: string, update = false) {
    this.filters[type] = increased;

    if (update) {
      this.getEquipments();
    }
  }

  onChangedEqList(increased: any, type: string, update = false) {
    this.filters[type] = increased;

    if (update) {
      this.getEquipments();
    }
  }

  onChangedEqCategory(increased: any, type: string, update = false) {
    this.eqList[type] = increased;
    const arr = this.equipmentsCategoryList.filter(item => item.val === increased);
    this.equipmentsTypeList = [{val: 0, name: 'Все'}].concat(arr[0].type);

    if (update) {
      this.getEquipments();
    }
  }

  onChangedFields(increased: any, type: string) {
    this.eqList[type] = increased;
  }
  onChangedFieldCashbox(increased: any) {
    this.fields['cash_box'] = increased;
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
