import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class HireService {
  hireFieldsList: InterFaceActiveFields[] = [];
  hireFieldsLesaList: InterFaceActiveFields[] = [];
  hireStatusList: InterFaceDopParams[] = [];

  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение списка активных полей
  public getHireFields() {
    return new Promise((resolve, reject) => {
      if (this.hireFieldsList.length === 0) {
        this.httpService.prepareQuery('get-hire-field', '')
          .then((result: InterFaceActiveFields[]) => {
              this.hireFieldsList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей для проката', error);
              reject();
            }
          );
      } else {
        resolve(this.hireFieldsList);
      }
    });
  }


  // получение списка активных полей лесов
  public getHireLesaFields() {
    return new Promise((resolve, reject) => {
      if (this.hireFieldsLesaList.length === 0) {
        this.httpService.prepareQuery('get-hire-lesa-field', '')
          .then((result: InterFaceActiveFields[]) => {
              this.hireFieldsLesaList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей лесов для проката', error);
              reject();
            }
          );
      } else {
        resolve(this.hireFieldsLesaList);
      }
    });
  }

  // изменение списка активных полей для таблицы
  public changeFields(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-hire-field', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей', error);
            reject();
          }
        );
    });
  }

  // изменение списка активных полей для таблицы
  public sendStatFeedback(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('hire-feedback-status', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей', error);
            reject();
          }
        );
    });
  }

  // получение списка статусов
  public getHireState() {
    return new Promise((resolve, reject) => {
      if (this.hireStatusList.length === 0) {
        this.httpService.prepareQuery('get-hire-state', '')
          .then((result: InterFaceDopParams[]) => {
              this.hireStatusList = result;
              resolve(this.hireStatusList);
            },
            (error) => {
              console.log('Ошибка при получении списка статусов проката', error);
              reject();
            }
          );
      } else {
        resolve(this.hireStatusList);
      }
    });
  }

  // получение списка заявов
  public getHire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-hire', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка проката', error);
            reject();
          }
        );
    });
  }

  // получение информации по заявке
  public getHireInfo(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-hire-info', data)
        .then((result: InterFaceDopParams[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при изменении статуса проката', error);
            reject();
          }
        );
    });
  }

  // возврат проката
  public returnHire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('return-hire', data)
        .then((result: InterFaceDopParams[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при возврате проката', error);
            reject();
          }
        );
    });
  }

  // обновлении информации по заявке
  public updateHire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-hire', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменении информации по заявке', error);
            reject();
          }
        );
    });
  }

  // удаление проката
  public deleteHire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-hire', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при удалении проекта', error);
            reject();
          }
        );
    });
  }

  // отмена брони
  public canceledHire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('canceled-reservation', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при отмене проката', error);
            reject();
          }
        );
    });
  }

  // закрытие проката
  public closeHire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('close-hire', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при закрытии проекта', error);
            reject();
          }
        );
    });
  }

  // возврат товара на склад
  public equipmentReturn(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('equipment-return', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при возврате товара на склад', error);
            reject();
          }
        );
    });
  }

  // возврат лесов на склад
  public equipmentReturnLesa(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('equipment-lesa-return', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при возврате товара на склад', error);
            reject();
          }
        );
    });
  }

  // смена дыт платежа
  public changeDate(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-pay-date', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменении даты платежа', error);
            reject();
          }
        );
    });
  }

  // смена дыт платежа
  public revertSum(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('revert-sum', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменении даты платежа', error);
            reject();
          }
        );
    });
  }

  // сортировка колонок
  public sortColumn(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('sort-column-hire', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при сортировке колонок', error);
            reject();
          }
        );
    });
  }

  // сортировка колонок для лесов
  public sortColumnLesa(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('sort-column-lesa', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при сортировке колонок', error);
            reject();
          }
        );
    });
  }

  // загрузка документа проката/клиента
  public setHireDoc(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('set-hire-doc', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при загрузке изображения', error);
            reject();
          }
        );
    });
  }

  // удаление документа проката/клиента
  public deleteHireDoc(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-hire-doc', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при удалении изображения', error);
            reject();
          }
        );
    });
  }

  // получение документов по заявке
  public getHireInfoDocs(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-hire-info-docs', data)
        .then((result: InterFaceDopParams[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при изменении статуса проката', error);
            reject();
          }
        );
    });
  }
}
