import {Component, HostListener} from '@angular/core';
import {ApplicationsCreateLesaService} from './applicationsCreateLesa.service';
import {DopParamsService} from '../../services/dopParams.service';
import {EquipmentsService} from '../equipments/equipments.service';
import {ClientService} from '../client/client.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {Router} from '@angular/router';
import {GlobalParams} from '../../storage/global-params';
import {ApplicationsService} from '../applications/applications.service';
import {FinanceService} from '../finance/finance.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';
import {DocumentService} from '../../services/document.service';
import {ShortHireService} from '../short_hire/short_hire.service';
import {ApplicationsCreateService} from '../applications-create/applicationsCreate.service';

@Component({
  selector: 'app-applications-create-lesa',
  templateUrl: './applicationsCreateLesa.component.html',
})

export class ApplicationsCreateLesaComponent {
  // для выхода без предупреждения
  applicationsStatus: InterFaceDopParams[] = [];
  showApplicationsStatus: InterFaceDopParams[] = [];
  applicationsDelivery: InterFaceDopParams[] = [];
  applicationsTypeLeases: InterFaceDopParams[] = [];
  equipmentsTypeList: InterFaceDopParams[] = [];
  // отображение окна поиска клиента из бд
  showSearchClient = {
    show: false,
    filter: '',
    clients: []
  };
  showExitModal = false;
  changeStatus = true;

  // отображение окна добавления товара
  showAddEquipments = {
    filter: '',
    equipments: []
  };

  // добавление платежа
  addPay = {
    equipment_id: null,
    show: false,
    sum: '',
    cashBox: null,
    revertSum: false,
    currentDate: '',
    branch: null
  } as any;

  // список касс
  financeCashBox: InterFaceFinanceCashBox[];
  // общая сумма
  allSum = 0;

  application: InterFaceNewApplicationNew = {
    client_id: null,
    equipments: [],
    sum: '0',
    count_eq: '',
    delivery_sum: '0',
    typeLease: {val: null, required: true, name: 'тип аренды'},
    sale: '0',
    srok: '',
    sale_in_sum: '0',
    status: {val: null, required: true, name: 'статус'},
    branch: {val: 0, required: true, name: 'филиал'},
    delivery: {val: null, required: true, name: 'тип доставки'},
    client_fio: {val: '', required: false, name: 'фио клиента'},
    client_email: {val: '', required: false, name: ''},
    comment: '',
    client_phone: {val: '', required: true, name: 'телефон клиента'},
    client_type: null,
    client_birth: '',
    month_sum: {val: '', required: false, name: 'Цена за месяц'},
    square: {val: null, required: false, name: 'Площадь лесов'},
    address: {val: '', required: false, name: 'Адрес размещения'},
    id_callibri: null,
    callibri_type: '',
    client_branch: null,
    client_status: '',
    client_comment: '',
    delivery_address: '',
    client_doc_type: '',
    reminder_date: null,
  };

  // вывод кнопки в зависимости от статуса
  buttonText = '';

  // короткая заявка
  shotHire: InterFaceNotification = {
    id: null,
    branch: null,
    source: null,
    client_phone: '',
    client_id: null,
    client_fio: '',
    client_email: '',
    comment: '',
    type: '',
  };

  keyCount = {
    1: {count: 0},
    2: {count: 0},
    3: {count: 0},
    4: {count: 0},
    5: {count: 0},
    6: {count: 0}
  };

  // переменная для опредления во время запроса типа оборудования (eq,demand,'')
  typeEquipments = '';

  // работа с приставами
  debts: InterFaceDebts = {
    show: false,
    data: [{
      bailiff: '',
      department: '',
      details: '',
      exe_production: '',
      ip_end: '',
      name: '',
      subject: ''
    }]
  };

  countDay: any;
  branches: InterFaceDopParams[] = [];
  insertClient: any;
  checkSaveWorld;
  checkSaveWorldCount = 0;
  showFinanceCashBox: InterFaceFinanceCashBox[];
  checkPledge = false;
  checkDelivery = false;

  rent_start = moment().format().slice(0, 16);
  rent_end = null;

  constructor(private applicationsCreateLesaService: ApplicationsCreateLesaService,
              private applicationsCreateService: ApplicationsCreateService,
              private applicationsService: ApplicationsService,
              private dopParamsService: DopParamsService,
              private equipmentsService: EquipmentsService,
              private clientService: ClientService,
              private globalParamsMessage: GlobalParamsMessage,
              private shortHireService: ShortHireService,
              public globalParams: GlobalParams,
              private router: Router,
              public globalParamsUser: GlobalParamsUser,
              private documentService: DocumentService,
              public financeService: FinanceService) {


    if (this.globalParamsUser.type !== 1) {
      this.getEquipments();
    }

    // получаем информацию по новому созданному клиенту
    this.clientService.refreshClientInfo.subscribe(() => {
      this.application.client_id = this.clientService.miniClientInfo.client_id;
      this.application.client_fio.val = this.clientService.miniClientInfo.client_fio;
      this.application.client_email.val = this.clientService.miniClientInfo.client_email;
      this.application.client_phone.val = this.clientService.miniClientInfo.client_phone;
      this.application.client_doc_type = this.clientService.miniClientInfo.client_doc_type;
      this.application.client_type = this.clientService.miniClientInfo.client_type;
      this.changeStatus = this.clientService.miniClientInfo.changeStatus;

      if (typeof this.clientService.miniClientInfo.app_status !== 'undefined' && this.clientService.miniClientInfo.app_status !== '') {
        this.changeStatusApplications(this.clientService.miniClientInfo.app_status);
      }
    });

    if (this.globalParams.getShortHire === 2) {
      this.shortHireService.getCurrentList().then((data: any) => {
          this.shotHire = data;
          this.application.id_callibri = data.id;
          this.application.callibri_type = data.type;
          this.application.branch.val = data.branch;
          this.application.client_phone.val = data.client_phone;
          this.application.client_id = data.client_id;
          this.application.client_fio.val = data.client_fio;
          this.application.client_email.val = data.client_email;
          this.application.comment = data.comment;
          this.globalParams.getShortHire = 1;
        },
        (error) => {
          console.log('Ошибка при получении касс: ', error);
        });
    }

    this.financeService.getFinanceCashBOx().then((data: InterFaceFinanceCashBox[]) => {
        this.financeCashBox = data;
        this.showFinanceCashBox = this.financeCashBox.filter(item => item.zalog === '0' && item.delivery === '0');
      },
      (error) => {
        console.log('Ошибка при получении касс: ', error);
      });

    this.applicationsService.getApplicationsStatus().then((data: InterFaceDopParams[]) => {
        this.showApplicationsStatus = this.applicationsStatus = data;
        if (this.application.status.val === null) {
          this.changeStatusApplications(this.applicationsStatus[0].val);
        }
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });

    this.applicationsCreateService.getApplicationsDelivery().then((data: InterFaceDopParams[]) => {
        this.applicationsDelivery = data;
        this.application.delivery.val = this.applicationsDelivery[1].val;
      },
      (error) => {
        console.log('Ошибка при получении статусов доставки: ', error);
      });

    this.applicationsCreateLesaService.getApplicationsTypeLease().then((data: InterFaceDopParams[]) => {
        this.applicationsTypeLeases = data;
        this.application.typeLease.val = data[0].val;
        this.changeTypeLease();
      },
      (error) => {
        console.log('Ошибка при получении типов аренды: ', error);
      });

    this.equipmentsService.getEquipmentsType().then((data: InterFaceDopParams[]) => {
        this.equipmentsTypeList = data;
      },
      (error) => {
        console.log('Ошибка при получении списка типов оборудования: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.searchAllClientBranch('');
  }

  changeDateEnd() {
    const _this = this;
    this.application.equipments.forEach(function (value) {
      value.rent_end = moment(_this.rent_end).format().slice(0, 10);
    });
    this.changeSum();
  }

  // изменение типа доставки
  changeTypeLease(dt = null) {
    if (dt !== null) {
      this.application.typeLease.val = dt;
    }

    const _this = this;

    this.application.equipments.forEach(function (value) {
      value.rent_start = moment(_this.rent_start).format().slice(0, 16);

      const tmpDate = moment(value.rent_start);

      if (_this.application.typeLease.val === 1) {
        value.rent_end = tmpDate.add(24, 'hours').format().slice(0, 10);
      } else {
        value.rent_end = tmpDate.add(30, 'day').format().slice(0, 10);
      }

      value.countDay = 1;
      _this.rent_end = value.rent_end;
    });

    this.changeSum();
  }

  // запрос оборудования
  getEquipments(filter = '') {
    this.equipmentsService.allEquipmentsLesaBranch({
      filter: filter,
      branch: this.application.branch.val,
      applicationStatus: this.typeEquipments
    }).then((data: any) => {
        if (data.length > 0) {
          this.showAddEquipments.equipments = data;
          this.application.branch.name = data[0].branch_name;
          this.application.branch.val = data[0].branch_id;
          this.setEquipmentDefault();
        }
      },
      (error) => {
        console.log('Ошибка при получении списка оборудования: ', error);
      });
  }
  setEquipmentDefault() {
    this.showAddEquipments.equipments.forEach((eq, index) => {
      this.insertEquipmentsData(index, 0);
    });
  }
  // вычесляем сумму аренды
  changeSum() {
    if (+this.application.sale_in_sum > +this.globalParamsUser.settings.sale_rub_limit) {
      this.application.sale_in_sum = this.globalParamsUser.settings.sale_rub_limit;
      this.globalParamsMessage.data = {
        title: `Максимальная скидка ` + this.globalParamsUser.settings.sale_rub_limit,
        type: 'error',
        body: ''
      };
    }
    if (+this.application.sale > +this.globalParamsUser.settings.sale_percent_limit) {
      this.application.sale = this.globalParamsUser.settings.sale_percent_limit;
      this.globalParamsMessage.data = {
        title: `Максимальная скидка ` + this.globalParamsUser.settings.sale_percent_limit,
        type: 'error',
        body: ''
      };
    }
    this.application.delivery_sum = this.application.delivery.val === 1 ? this.application.delivery_sum : '0';
    this.applicationsService.hireChangeSum({
      equipments: this.application.equipments,
      sale: this.application.sale,
      sale_in_sum: this.application.sale_in_sum,
      typeLease: this.application.typeLease.val,
      lesa: 1,
      month_sum: this.application.month_sum.val
    }).then((result: any) => {
        this.application.sum = result.sum;
        this.application.equipments = result.equipments;
        this.allSum = parseFloat(this.application.sum) + parseFloat(this.application.delivery_sum);
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });
  }

  // смена статуса заявки
  changeStatusApplications(val) {
    if (this.application.status.val === 3 || val === 3) {
      this.application.equipments = [];
    }

    this.application.status.val = val;

    if (val === 1) {
      this.buttonText = 'Создание проката';
    }
    if (val === 2) {
      this.buttonText = 'Создание брони';
    }
    if (val === 3) {
      this.buttonText = 'Создание спроса';
    }
    if (val === 4) {
      this.buttonText = 'Создание отказа';
    }
    if (val === 5) {
      this.buttonText = 'Создание "узнали"';
    }
    if (val === 9) {
      this.buttonText = 'Создание "удаленный"';
    }
  }

  // поиск клиентов из бд
  searchAllClientBranch(filter) {
    this.clientService.searchAllClientBranch({
      branch: this.application.branch.val,
      filter: filter
    }).then((data: InterFaceSearchClient[]) => {
        this.showSearchClient.clients = data;
      },
      (error) => {
        console.log('Ошибка при получении списка сотрудников: ', error);
      });
  }

  // показ модального окна для добавления платежа
  showInsertSum(equipment_id) {
    this.addPay = {
      equipment_id,
      show: true,
      sum: '',
      cashBox: null,
      revertSum: false,
      currentDate: moment().format().slice(0, 16),
      branch: this.application.branch.val
    };
  }

  // заполнение данными из справочника
  insertClientData(index) {
    const tmpClient = this.showSearchClient.clients[index];

    this.application.client_id = tmpClient.id;
    this.application.client_phone.val = tmpClient.phone;
    this.insertClient = tmpClient;

    this.showSearchClient.show = false;

    this.showSearchClient = {
      show: false,
      filter: '',
      clients: []
    };
  }

  // возврат платежа
  changeRevert() {
    this.addPay.revertSum = !this.addPay.revertSum;
  }

  // выбор леса
  insertLesaS(index, key) {
    this.keyCount[key].count = this.keyCount[key].count > this.showAddEquipments.equipments[index].count ?
      this.showAddEquipments.equipments[index].count :
      this.keyCount[key].count;
    const id = this.showAddEquipments.equipments[index].id;
    let check = false;

    const _this = this;
    this.application.equipments.forEach(function (value) {
      if (value.id === id) {
        check = true;
        value.count = _this.keyCount[key].count;
      }
    });

    if (!check) {
      this.insertEquipmentsDataStatus(index, this.keyCount[key].count);
    }
  }

  // выбор леса
  insertLesa(index, key, data, check1, check2) {
    if (check1 || check2 || (data === 'minus' && this.keyCount[key].count - 1 < 0)) {
      return false;
    }

    this.keyCount[key].count = data === 'minus' ? this.keyCount[key].count - 1 : this.keyCount[key].count + 1;
    this.keyCount[key].count = this.keyCount[key].count > this.showAddEquipments.equipments[index].count ?
      this.showAddEquipments.equipments[index].count : this.keyCount[key].count;
    const id = this.showAddEquipments.equipments[index].id;
    let check = false;

    const _this = this;
    this.application.equipments.forEach(function (value) {
      if (value.id === id) {
        check = true;
        value.count = _this.keyCount[key].count;
      }
    });

    if (!check) {
      this.insertEquipmentsDataStatus(index, this.keyCount[key].count);
    }
  }

  // выбор оборудования
  insertEquipmentsDataStatus(index, count = 1) {
    this.insertEquipmentsData(index, count);
    const eq = this.showAddEquipments.equipments[index];
    // зачищать или нет список оборудования
    this.showAddEquipments.filter = '';

    // определяем тип оборудования и меняем его статус
    if (eq.type === 'eq') {
      this.typeEquipments = 'eq';
      this.application.branch.val = eq.branch_id;
      this.application.branch.name = eq.branch_name;
      if (eq.check_click === '0') {
        this.changeStatusApplications(2);
        this.showApplicationsStatus = this.applicationsStatus.filter(item => item.val !== 3 && item.val !== 1);
      } else {
        this.showApplicationsStatus = this.applicationsStatus.filter(item => item.val !== 3);
      }

    } else {
      this.typeEquipments = 'demand';
      this.showApplicationsStatus = this.applicationsStatus.filter(item => item.val === 3);
      this.application.status.val = 3;
    }

    this.changeSum();
  }

  // выбор оборудования
  insertEquipmentsData(index, count = 1) {
    const eq = this.showAddEquipments.equipments[index];
    const rent_start = moment().format().slice(0, 16);

    const tmp = {
      id: eq.id,
      name: eq.name,
      in_stock: eq.count,
      type: eq.type,
      count: count,
      price: eq.price_per_day,
      photo: eq.photo,
      photo_alias: eq.photo_alias,
      allCount: eq.count,
      check_click: eq.check_click,
      payList: [],
      comment: '',
      rent_end: '',
      countDay: 1,
      rent_start: rent_start,
      discount: 0,
    };
    this.application.equipments.push(tmp);
    this.changeTypeLease();
  }

  // проверяем есть клиент или нет, если есть - создаем просто заявку, если нет - то отправляем команду сначала на создание клиента
  addApplication(checkSaveWorld) {
    this.checkSaveWorld = checkSaveWorld;

    if (this.application.client_id === null) {
      this.checkSaveWorldCount++;
    } else {
      this.addApplicationSecond(checkSaveWorld);
    }
  }

  // вызов создания завяки с компонента создания клиента
  onChangedCreate(data) {
    this.application.client_id = data.client_id;
    this.application.client_phone = data.client_phone;
    this.addApplication(this.checkSaveWorld);
  }

  // создание заявки
  addApplicationSecond(checkSaveWorld) {
    if ([3, 9].indexOf(this.application.status.val)) {
      for (const value in this.application) {
        if (value === 'client_id') {
          continue;
        }
        if (this.application.status.val === 2 || this.application.status.val === 1) {
          if (this.application.hasOwnProperty(value) && typeof this.application[value] !== 'undefined') {

            // проверка при создании заявки лесов
            if (value === 'month_sum' || value === 'square' || value === 'address') {
              this.application[value].required = true;
            }
            if (this.application[value] !== null) {
              if (this.application[value].required &&
                (this.application[value].val === '' || this.application[value].val === null)) {
                this.globalParamsMessage.data = {
                  title: `Необходимо заполнить поле "${this.application[value].name}"`,
                  type: 'error',
                  body: ''
                };
                return false;
              }
            }
          }
        }
      }

      if ([4, 5].indexOf(this.application.status.val) !== -1 && this.application.equipments.length === 0) {
        this.insertEquipmentsData(1, 1);
      }
    }

    if ((this.application.status.val === 4 || this.application.status.val === 5) && this.application.comment === '') {
      this.globalParamsMessage.data = {title: `Необходимо указать комментарий`, type: 'error', body: ''};
      return false;
    }

    if (this.application.status.val !== 4 && this.application.status.val !== 5 && this.application.status.val !== 3) {
      if (this.application.address.val === null || this.application.address.val === '') {
        this.globalParamsMessage.data = {title: 'Необходимо указать адрес', type: 'error', body: ''};
        return false;
      }

      if (this.application.month_sum.val === null || this.application.month_sum.val === '') {
        this.globalParamsMessage.data = {title: 'Необходимо указать месячную сумму', type: 'error', body: ''};
        return false;
      }

      if (this.application.square.val === null) {
        this.globalParamsMessage.data = {title: 'Необходимо указать площадь', type: 'error', body: ''};
        return false;
      }
    }

    this.applicationsCreateLesaService.addApplication({
      equipments: this.application.equipments,
      typeLease: this.application.typeLease.val,
      sale: this.application.sale,
      sale_in_sum: this.application.sale_in_sum,
      delivery: this.application.delivery.val,
      client_id: this.application.client_id,
      sum: this.application.sum,
      lesa: true,
      delivery_sum: this.application.delivery_sum,
      comment: this.application.comment,
      branch: this.application.branch.val,
      status: this.application.status.val,
      month_sum: this.application.month_sum.val,
      square: this.application.square.val || 0,
      address: this.application.address.val,
      client_phone: this.application.client_phone.val,
      id_callibri: this.application.id_callibri,
      callibri_type: this.application.callibri_type,
      delivery_address: this.application.delivery_address
    }).then((result: any) => {
        this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
        this.globalParams.getShortHire = 1;

        if (checkSaveWorld && result.length > 0) {
          this.documentService.getWord({hireInfoId: result[0], lesa: 0, check: 1}).then((resultDoc: any) => {
              window.open(this.globalParams.path + '/uploads/tmp/' + resultDoc, '_blank');
            },
            (error) => {
              console.log('Ошибка при скачивании документа: ', error);
            });
        }

        if (this.application.status.val === 3) {
          this.router.navigate(['/demand']);
        } else if (this.application.status.val === 4 || this.application.status.val === 5) {
          this.router.navigate(['/application']);
        } else {
          this.router.navigate(['/hire-lesa']);

          result.forEach(function (value) {
            window.open('/hire/' + value);
          });
        }

        this.globalParams.createAppLesa = false;
      },
      (error) => {
        console.log('Ошибка при добавлении новой заявки: ', error);
      });
  }

  // проверка через приставов
  getBailiffs() {
    this.clientService.getBailiffs({
      fio: this.application.client_fio.val,
      region: this.application.client_branch,
      type: this.application.client_type
    }).then((data: any) => {
        if (data.length === 0) {
          this.globalParamsMessage.data = {title: 'У клиента нет долгов', type: 'success', body: ''};
        }

        if (data.length !== 0) {
          this.debts = {show: true, data: data};
        }

      },
      (error) => {
        console.log('Ошибка при получении данных от приставов: ', error);
      });
  }

  // добавляем платеж
  addPayList() {
    if (this.addPay.sum === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать сумму', type: 'error', body: ''};
      return false;
    }

    if (this.addPay.cashBox === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }

    if (this.checkDelivery && this.checkPledge) {
      this.globalParamsMessage.data = {title: 'Нельзя выбрать и "залог" и "доставка"!', type: 'error', body: ''};
      return false;
    }

    const fCashBox = this.financeCashBox.filter(item => item.val === this.addPay.cashBox)[0];
    const fCashBoxTxt = fCashBox.name.split(' - ');

    let fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.zalog === '0' && item.delivery === '0');

    if (this.checkDelivery) {
      fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.delivery === '1');
    }

    if (this.checkPledge) {
      fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.zalog === '1');
    }

    if (fCashBoxCurrent.length === 0) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }
    this.addPay.cashBox = fCashBoxCurrent[0].val;

    const eq = this.application.equipments.filter(item => item.id === this.addPay.equipment_id);

    eq[0].payList.push({
      sum: this.addPay.sum,
      cashBox: this.addPay.cashBox,
      revertSum: this.addPay.revertSum,
      currentDate: this.addPay.currentDate,
      branch: this.addPay.branch
    });
    this.clear();
  }

  // удаление платежа
  deletePayList(equipment, index) {
    equipment.payList.splice(index, 1);
  }

  clear() {
    this.addPay = {equipment_id: null, show: false, sum: '', cashBox: null, revertSum: false, currentDate: '', branch: null};
  }

  checkChangeDate() {
    if (this.globalParamsUser.rights.indexOf(3) !== -1) {
      return false;
    } else if (this.application.status.val === 1) {
      return true;
    }
    return false;
  }

  changeCountDay() {
    const _this = this;
    this.application.equipments.forEach(function (value) {
      const tmpDate = moment(value.rent_start);

      if (_this.application.typeLease.val === 1) {
        value.rent_end = tmpDate.add(24 * _this.countDay, 'hours').format().slice(0, 10);
      } else {
        value.rent_end = tmpDate.add(30 * _this.countDay, 'day').format().slice(0, 10);
      }
      _this.rent_end = value.rent_end;
    });

    this.changeSum();
  }

  getStatus() {
    return this.application.status.val !== 3 &&
      this.application.status.val !== 4 &&
      this.application.status.val !== 5 &&
      this.application.status.val !== 9;
  }

  onChangedPay(increased: any, data: any, type: string) {
    data[type] = increased;
  }

  onChanged(increased: any) {
    this.application.branch.val = increased;
    this.getEquipments();
  }

  onChangedApplication(increased: any) {
    this.application.delivery.val = increased;
    this.changeSum();
  }

  onChangedAddPay(increased: any, type: string) {
    this.addPay[type] = increased;
  }
}
