// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ws: 'wss://strojkaarenda.moizvonki.ru/wsapi/',
  apiKey: 'nfy1qmeakhphq2jt4a3ep3eydx1uej2j',
  authLogin: 'auth.login',
  appName: 'strojkaarenda',
  apiVersion: 1,
  SMS_URL: 'https://smsc.ru/sys/send.php?login=rentcrm&psw=slaid123&sender=798445',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
