
import {throwError as observableThrowError, Observable} from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/internal/operators';
import {GlobalParamsMessage} from '../../components/message_alert/global-params-message';
import {SessionStorageService} from '../../storage/session-storage.service';
import {Base64} from 'js-base64';

@Injectable()
export class HttpService {
  loadGif: EventEmitter<any> = new EventEmitter(false);

  static handlerError(error: any = {}) {
    console.log('Ошибка после http-запроса', error);

    let text_error: string;
    if (typeof error.status !== 'undefined') {
      if (error.status === 0) {
        text_error = 'Неизвестная ошибка';
      } else if (error.status === 200) {
        text_error = error.body.json();
      } else if (error.status === 404) {
        text_error = 'Страница не найдена';
      } else if (error.status === 300) {
        text_error = 'Доступ запрещен';
      } else if (error.status === 500) {
        text_error = 'временно не доступен';
        throw {name: 'CRM', message: 'CRM временно не доступен'};
      } else {
        text_error = '';
      }
    } else {
      text_error = '';
    }

    return observableThrowError(text_error);
  }

  constructor(private http: HttpClient,
              private sessionStorage: SessionStorageService,
              private  globalParamsMessage: GlobalParamsMessage) {
  }

  public prepareQuery(url: string = 'noUrl', data = '') {
    if (data !== '') {
      console.log('url: ', url);
      console.log('Отправляем данные: ', data);
      data = JSON.stringify(data);
      data = Base64.encode(data);
    }

    if (url !== 'api/get-short-application' && url !== 'get-applications-processed' && url !== 'get-profile-info') {
      this.loadGif.emit(true);
    }

    return new Promise((resolve, reject) => {

      this.sendPostQuery(url, data).subscribe((result: { status: string, msg: string, session_id: string, data: string, code: string }) => {
          console.log('Url: ' + url + '; HttpService Ответ: ', result);
          if (result.status === 'OK') {
            if (typeof result.data !== 'undefined' && result.data !== '') {
              let rez = atob(result.data);
              rez = JSON.parse(rez);
              console.log('Результат ответа: ', rez);
              resolve(rez);
            } else {
              resolve(result);
            }

            if (typeof result.session_id !== 'undefined') {
              this.sessionStorage.pubId = result.session_id;
            }

          } else if (result.status === 'ERROR') {
            if (typeof result.code !== 'undefined' && result.code === 'NEED SESSION') {
              this.globalParamsMessage.data = {title: 'Ошибка', body: 'Истек срок сессии', type: 'error'};
              this.sessionStorage.exit();
            } else if (typeof result.code !== 'undefined' && result.code === 'DENIED') {
              this.globalParamsMessage.data = {title: 'Ошибка', body: 'Доступ запрещен', type: 'error'};
            } else {
              this.globalParamsMessage.data = {title: 'Ошибка', body: result.msg, type: 'error'};
            }
            reject();
          } else {
            this.globalParamsMessage.data = {title: 'Ошибка', body: 'Система временно недоступна', type: 'error'};
            reject();
          }

          if (url !== 'api/get-short-application' && url !== 'get-applications-processed') {
            this.loadGif.emit(false);
          }
        },
        (error) => {
          console.log('Ошибка после отправки запроса в CRM', error);
          this.globalParamsMessage.data = {title: 'Ошибка', body: 'Система временно недоступна', type: 'error'};

          if (url !== 'api/get-short-application' && url !== 'get-applications-processed' && url !== 'get-profile-info') {
            this.loadGif.emit(false);
          }

          reject(error);
        });
    });
  }

  private sendPostQuery(api, data: any) {
    const request = {
      sessionId: this.sessionStorage.pubId,
      prBlock: data
    };
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');

    let url = 'https://2022-bk.str-stroika-arenda.ru/';
    if (window.location.href.match(/http:\/\/localhost:4200/i)) {
      url = 'http://localhost:8005/';
    } else if (window.location.href.match(/https:\/\/str-stroika-arenda.ru/i)) {
      url = 'https://backend-stroika-arenda.ru/';
    }

    url += 'api/' + api;

    return this.http.post(url, request, {headers: headers})
      .pipe(
        catchError(HttpService.handlerError)
      );
  }
}
