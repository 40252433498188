import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SettingsOrganizationService {
  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение информации об организации
  public getOrganizationInfo() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-org-info', '')
        .then((result: InterFaceSettingsLevel[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении информации', error);
            reject();
          }
        );
    });
  }

  // обновление информации об организации
  public setOrganizationInfo(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-org-info', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при обновлении', error);
            reject();
          }
        );
    });
  }
}
