import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SettingsLevelService {
  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение уровней
  public getSettingsLevel() {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-settings-level', '')
        .then((result: InterFaceSettingsLevel[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении плана менеджера', error);
            reject();
          }
        );
    });
  }

  // получение плана менеджера
  public setSettingsLevel(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('set-settings-level', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при  обновлении плана менеджера', error);
            reject();
          }
        );
    });
  }
}
