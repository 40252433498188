import {Injectable} from '@angular/core';

@Injectable()
export class GlobalParamsRental {
  data: any = {
    show: false,
    date_end: '',
    date_end_format: '',
    const_date_end_format: '',
    type_lease: null,
    app_id: null,
    count: null
  };
}
