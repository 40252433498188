import {Component, OnInit} from '@angular/core';
import {ClientInfoService} from './clientInfo.service';
import {DopParamsService} from '../../services/dopParams.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GlobalPayListClient} from '../pay_list_client/global-pay-list-client';
import {GlobalApplicationListClient} from '../application_list_client/global-application-list-client';
import {GlobalClientChangeStatusList} from '../client_change_status_list/global-client_change_status_list';
import {ClientService} from '../client/client.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';

@Component({
  selector: 'app-client-info',
  templateUrl: './clientInfo.component.html',
})
export class ClientInfoComponent implements OnInit {
  // список статусов
  statusList: InterFaceDopParams[] = [];
  // список филиалов
  branches: InterFaceDopParams[] = [];
  // список скидок
  discounts: InterFaceDopParams[] = [];
  // список исчтоников
  sources: InterFaceDopParams[] = [];
  // список документов
  typeDocs: InterFaceDopParams[] = [];

  recordingList: {
    id: number;
    date: string;
    rating: string;
    rec: string;
    comment: string;
  }[] = [];
  client: InterFaceClient = {
    sale: null,
    branch: null,
    new_status: null,
    old_status: null,
    reason_change_status: '',
    source: null,
    name: '',
    inn: null,
    kpp: null,
    name_chief: '',
    bonus: '',
    phone_1: '',
    phone_2: '',
    phone_3: '',
    email: '',
    date_birth: '',
    bonus_account: '',
    count_application: null,
    all_total_paid: null,
    bonus_name: '',
    bonus_percent: '',

    application_list: [{
      id: '',
      rent_start: '',
      rent_end: '',
      sum: '',
      total_paid: '',
      equipments: '',
    }],
    pay_list: [{
      sum: '',
      date: '',
      equipments: '',
      cashBox: ''
    }],
    change_status_list: [{
      date: '',
      old_status: '',
      new_status: '',
      text: '',
      user: '',
    }],
    comment: '',
    name1: '',
    name2: '',
    name3: '',
    typeDoc: null,
    doc_series: '',
    doc_number: '',
    check_create_app: '0',
    type: null,
    korSchet: '',
    rasSchet: '',
    bik: '',
    bank: '',
    ogrn: '',
    confirmation: '',
    passport_photos: []
  };

  debts: InterFaceDebts = {
    show: false,
    data: [{
      bailiff: '',
      department: '',
      details: '',
      exe_production: '',
      ip_end: '',
      name: '',
      subject: ''
    }]
  };
  currentTab = 1;

  // идентификатор клиента
  clientId: null;
  showDeleteModal = false;
  clientType = [
    {'val': 1, 'name': 'Физ. лицо'},
    {'val': 2, 'name': 'OOO'},
    {'val': 3, 'name': 'ИП'},
  ];

  recordingModal = {
    show: false,
    id: null,
    comment: '',
    rating: '',
  };

  constructor(private clientInfoService: ClientInfoService,
              private dopParamsService: DopParamsService,
              private globalParamsMessage: GlobalParamsMessage,
              private router: ActivatedRoute,
              private routerCurrent: Router,
              private globalPayListClient: GlobalPayListClient,
              private globalApplicationListClient: GlobalApplicationListClient,
              private globalClientChangeStatusList: GlobalClientChangeStatusList,
              private clientService: ClientService,
              public globalParamsUser: GlobalParamsUser) {
    this.router.params.subscribe(
      (params: Params): void => {
        this.clientId = params.id;
      }
    );

    this.clientService.refreshClientInfo.subscribe(() => {
      this.getClients();
    });
  }

  ngOnInit() {
    this.dopParamsService.getStatusClient().then((data: InterFaceDopParams[]) => {
        this.statusList = data;
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.dopParamsService.getDiscount().then((data: InterFaceDopParams[]) => {
        this.discounts = data;
      },
      (error) => {
        console.log('Ошибка при получении списка скидок: ', error);
      });

    this.dopParamsService.getSource().then((data: InterFaceDopParams[]) => {
        this.sources = data;
      },
      (error) => {
        console.log('Ошибка при получении источников: ', error);
      });

    this.dopParamsService.getClientTypeDoc().then((data: InterFaceDopParams[]) => {
        this.typeDocs = data;
      },
      (error) => {
        console.log('Ошибка при получении типов документов: ', error);
      });

    this.getClients();
  }

  getClients() {
    this.clientInfoService.getClientInfo({clientId: this.clientId}).then((data: InterFaceClient) => {
        this.client = data;
        this.client.date_birth = this.client.date_birth !== '' ? moment(this.client.date_birth).format('YYYY-MM-DD') : '';

        if (this.client.confirmation === '2') {
          this.currentTab = 4;
        }
      },
      (error) => {
        console.log('Ошибка при получении детальной информации по клиенту: ', error);
      });
  }

  callMoiZvonki(phone) {
    this.clientInfoService.callMoiZvonki({phone: phone}).then(() => {
      },
      (error) => {
        console.log('Ошибка при звонке: ', error);
      });
  }

  getRecordingList() {
    this.clientInfoService.getRecording({clientId: this.clientId}).then((data: []) => {
        this.recordingList = data;
      },
      (error) => {
        console.log('Ошибка при получение списка звонков: ', error);
      });
  }

  changeClientCreateApp() {
    this.clientInfoService.changeClientCreateApp({clientId: this.clientId}).then(() => {
        this.client.check_create_app = '0';
      },
      (error) => {
        console.log('Ошибка при звонке: ', error);
      });
  }

  confirmationCheck() {
    this.clientInfoService.confirmationCheck({clientId: this.clientId}).then(() => {
        this.client.confirmation = '1';
      },
      (error) => {
        console.log('Ошибка при звонке: ', error);
      });
  }

  changeClient() {
    if (this.client.sale === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать скидку', type: 'error', body: ''};
      return false;
    }

    if (this.client.branch === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать город аренды', type: 'error', body: ''};
      return false;
    }

    if (this.client.new_status === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать статус', type: 'error', body: ''};
      return false;
    }

    if (this.client.phone_1 === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать хотя бы 1 номер телефона', type: 'error', body: ''};
      return false;
    }

    if (this.client.new_status !== this.client.old_status && (this.client.reason_change_status === '' || typeof this.client.reason_change_status === 'undefined')) {
      this.globalParamsMessage.data = {title: 'Необходимо указать причиниу смены статуса', type: 'error', body: ''};
      return false;
    }

    this.clientService.addClient({
      sale: this.client.sale,
      branch: this.client.branch,
      new_status: this.client.new_status,
      old_status: this.client.old_status,
      source: this.client.source,
      comment: this.client.comment,
      name: this.client.name,
      inn: this.client.inn,
      kpp: this.client.kpp,
      name_chief: this.client.name_chief,
      email: this.client.email,
      clientId: this.clientId,
      reason_change_status: this.client.reason_change_status,
      date_birth: this.client.date_birth !== '' ? moment(this.client.date_birth).format().slice(0, 10) : '',
      phone_1: this.client.phone_1.replace(/[\),\(,\-,+,\s]/g, ''),
      phone_2: this.client.phone_2 !== null ? this.client.phone_2.replace(/[\),\(,\-,+,\s]/g, '') : '',
      phone_3: this.client.phone_3 !== null ? this.client.phone_3.replace(/[\),\(,\-,+,\s]/g, '') : '',
      name1: this.client.name1,
      name2: this.client.name2,
      name3: this.client.name3,
      typeDoc: this.client.typeDoc,
      doc_series: this.client.doc_series,
      doc_number: this.client.doc_number,
      type: this.client.type,
      korSchet: this.client.korSchet,
      rasSchet: this.client.rasSchet,
      bik: this.client.bik,
      bank: this.client.bank,
      ogrn: this.client.ogrn,
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Пользователь успешно изменен', type: 'success', body: ''};
      },
      (error) => {
        console.log('Ошибка при изменении пользователей: ', error);
      });
  }

  // история оплат
  open_pay_history() {
    this.globalPayListClient.data = {
      show: true,
      data: this.client.pay_list
    };
  }

  // история заявок
  open_application_history() {
    this.globalApplicationListClient.data = {
      show: true,
      data: this.client.application_list
    };
  }

  // история смены статусов
  open_change_status_history() {
    this.globalClientChangeStatusList.data = {
      show: true,
      data: this.client.change_status_list
    };
  }

  // проверка через приставов
  getBailiffs() {
    this.clientService.getBailiffs({fio: this.client.name, region: this.client.branch, type: this.client.type}).then((data: any) => {
        if (data.length === 0) {
          this.globalParamsMessage.data = {title: 'У клиента нет долгов', type: 'success', body: ''};
        }
        if (data.length !== 0) {
          this.debts = {show: true, data: data};
        }
      },
      (error) => {
        console.log('Ошибка при получении данных от приставов: ', error);
      });
  }

  deleteClient() {
    this.clientService.deleteClient({id: this.clientId}).then(() => {
        this.globalParamsMessage.data = {title: 'Пользователь успешно удален', type: 'success', body: ''};
        this.routerCurrent.navigate(['/clients/']);
      },
      (error) => {
        console.log('Ошибка при удалении пользователя: ', error);
      });
  }

  onChanged(increased: any, type: string, update = false) {
    this.client[type] = increased;
  }

  changeTab(data) {
    this.currentTab = data;
    if (this.currentTab === 5) {
      this.getRecordingList();
    }
  }

  changeRecording(data) {
    this.recordingModal = {
      show: true,
      id: data.id,
      comment: data.comment,
      rating: data.rating,
    };
  }

  closeRecordingModal() {
    this.recordingModal = {
      show: false,
      id: null,
      comment: '',
      rating: '',
    };
  }

  saveRecording() {
    this.clientInfoService.saveRecording({
      id: this.recordingModal.id,
      rating: this.recordingModal.rating,
      comment: this.recordingModal.comment,
    }).then(() => {
        this.closeRecordingModal();
        this.getRecordingList();
      },
      (error) => {
        console.log('Ошибка: ', error);
      });
  }
}
