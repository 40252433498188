import {Directive} from '@angular/core';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';


const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY, HH:mm',
  },
  display: {
    dateInput: 'DD.MM.YYYY, HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

const MY_FORMATS_NO_TIME = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'M',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Directive({
  selector: '[appDateFormat1]',
  providers: [
    {provide: NGX_MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CustomDateFormat1Directive {
}

@Directive({
  selector: '[appDateFormat2]',
  providers: [
    {provide: NGX_MAT_DATE_FORMATS, useValue: MY_FORMATS_NO_TIME},
  ],
})
export class CustomDateFormat2Directive {
}
