import {Component} from '@angular/core';
import {ProfileService} from '../profile/profile.service';
import {GlobalParams} from '../../storage/global-params';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Component({
  selector: 'app-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.css']
})
export class ProfileDataComponent {
  data: any;
  info: InterProfileData = {
    fio: '',
    branch: '',
    phone: '',
    email: '',
    date_birth: '',
    photo: ''
  };

  files = [];
  currentTab = 1;

  // загрузка изображения
  load_file: any = [];
  fileString = '';

  client = {photo_content: '', photo_name: 'Загрузить фото'};

  birthday: string = new Date().toLocaleDateString();

  passwords = {
    old_password: '',
    new_password: '',
    repeat_password: '',
  };

  constructor(private profileService: ProfileService,
              public globalParams: GlobalParams,
              private globalParamsMessage: GlobalParamsMessage) {
    this.getData();
  }

  getData() {
    this.profileService.getProfileInfo({'branch': this.globalParams.branch.val}).then((result: InterProfileData) => {
        this.info = result;
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });
  }

  // добавить фото
  addPhoto(event) {
    const target = event.target || event.srcElement;
    for (let j = 0; target.files.length > j; j++) {
      this.load_file = target.files[j];
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this, name);
      reader.readAsBinaryString(this.load_file);
    }
  }

  _handleReaderLoaded(data, readerEvt) {
    const binaryString = readerEvt.target.result;
    this.fileString = btoa(binaryString);

    this.client.photo_name = this.load_file.name;
    this.client.photo_content = this.fileString;
  }

  updateInfo() {
    this.profileService.updateProfile({
      fio: this.info.fio,
      date_birth: this.info.date_birth,
      phone: this.info.phone,
      email: this.info.email,
    }).then(() => {
      this.info.photo = '';
      if (this.client.photo_content !== '') {
        this.onUpload();
      }
      this.getData();
    });
  }

  updatePassword() {
    if (this.passwords.old_password === '') {
      this.globalParamsMessage.data = {title: 'Укажите старый пароль', type: 'success', body: ''};
      return false;
    }

    if (this.passwords.new_password === '') {
      this.globalParamsMessage.data = {title: 'Укажите новый пароль', type: 'success', body: ''};
      return false;
    }

    if (this.passwords.repeat_password === '') {
      this.globalParamsMessage.data = {title: 'Повторите новый пароль', type: 'success', body: ''};
      return false;
    }

    this.profileService.updatePassword({
      old_password: this.passwords.old_password,
      new_password: this.passwords.new_password,
      repeat_password: this.passwords.repeat_password
    }).then(() => {
      this.info.photo = '';
      if (this.client.photo_content !== '') {
        this.onUpload();
      }
      this.getData();
    }, (error) => {
      console.log('Ошибка при смене пароля: ', error);
    });
  }

  onUpload() {
    const formData = new FormData();
    for (const file of this.files) {
      formData.append(name, file, file.name);
    }

    this.profileService.setProfilePhoto({
      file_name: this.client.photo_name,
      files: this.client.photo_content
    }).then(() => {
      },
      () => {
        console.log('Ошибка при загрузке изображения');
      });
  }
}