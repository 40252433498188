import {Component} from '@angular/core';
import {ApplicationsInformingService} from './applicationsInforming.service';
import {DopParamsService} from '../../services/dopParams.service';
import * as moment from 'moment';
import {GlobalParams} from '../../storage/global-params';

@Component({
  selector: 'app-applications-informing',
  templateUrl: './applicationsInforming.component.html',
})
export class ApplicationsInformingComponent {
  p = 1;
  applications: InterFaceApplicationsInforming[] = [];
  sortedData: InterFaceApplicationsInforming[];
  hiresCount = null;
  branches: InterFaceDopParams[] = [];

  filters = {
    sort: {},
    offset: 0,
    limit: 50,
    branch: [],
    date_start: '',
    date_end: '',
    phone: '',
  };

  activeFields = [
    {code: 'id', name: 'ID'},
    {code: 'branch', name: 'Филиал'},
    {code: 'phone', name: 'Телефон'},
    {code: 'recording', name: 'Звонок'},
    {code: 'client_fio', name: 'ФИО'},
    {code: 'comment', name: 'Комментарий'},
    {code: 'date_create', name: 'Дата создания'},
    {code: 'type_call', name: 'Тип'},
  ];

  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  showFilters = false;
  branchS = [];
  branchTmp = [];

  dropdownSettingsStatus = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };

  constructor(private applicationsInformingService: ApplicationsInformingService,
              private dopParamsService: DopParamsService,
              private globalParams: GlobalParams) {

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.getApplication();
  }

  getApplication() {
    this.applicationsInformingService.getApplicationInforming({
      sort: Object.keys(this.filters.sort).length !== 0,
      offset: this.filters.offset,
      limit: this.filters.limit,
      branch: this.filters.branch,
      phone: this.filters.phone,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
    }).then((data: { data: InterFaceApplicationsInforming[], hiresCount: number }) => {
        this.sortedData = data.data.slice();
        this.hiresCount = data.hiresCount;
        this.applications = data.data;
      },
      (error) => {
        console.log('Ошибка при получении списка заявок: ', error);
      });
  }

  // отображение фильтра
  changeShowFilters() {
    this.showFilters = !this.showFilters;
  }

  sortData(sort) {
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.sort = sort;
      this.getApplication();
    } else {
      const data = this.applications.slice();
      this.applications = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'client_fio':
            return compare(a.client_fio, b.client_fio, isAsc);
          case 'phone':
            return compare(a.phone, b.phone, isAsc);
          case 'type_call':
            return compare(a.type_call, b.type_call, isAsc);
          case 'date_create':
            const date_create_a = new Date(a.date_create).getTime();
            const date_create_b = new Date(b.date_create).getTime();
            return compare(date_create_a, date_create_b, isAsc);
          case 'branch':
            return compare(a.branch, b.branch, isAsc);
          default:
            return 0;
        }
      });
    }
  }

  changePage(data) {
    this.p = data;
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.offset = this.p * 50 - 50;
      this.getApplication();
    }
  }

  changeCount(count) {
    this.filters.limit = count;
    this.getApplication();
  }

  /*changeFilterBranch() {
    if (JSON.stringify(this.branchS) !== JSON.stringify(this.branchTmp)) {
      this.branchTmp = this.branchS;
      this.filters.branch = this.branchS.map(item => item.val);
      this.getApplication();
    }
  }*/
  changeFilterBranch(data) {
    this.branchS = data;
    this.filters.branch = this.branchS;
    this.getApplication();
  }

  // очистка фильтра
  clearFilter() {
    this.filters = {
      sort: {},
      offset: 0,
      limit: 50,
      branch: [],
      date_start: '',
      date_end: '',
      phone: '',
    };

    this.getApplication();
  }

}

function compare(a: Date | number | string, b: Date | number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
