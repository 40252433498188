import {Component} from '@angular/core';
import {SettingsManagerPlanService} from './settingsManagerPlan.service';

@Component({
  selector: 'app-settings-manager-plan',
  templateUrl: './settingsManagerPlan.component.html',
})
export class SettingsManagerPlanComponent {
  list: InterFaceSettingsLevel[] = [];

  yearList: { val: number, name: string }[] = [];
  currentYear = null;

  constructor(private settingsManagerPlanService: SettingsManagerPlanService) {
    this.settingsManagerPlanService.getManagerPlanYear().then((data: any) => {
        this.yearList = data;
        this.currentYear = this.yearList[this.yearList.length - 1].val;
        this.getManagerPlan();
      },
      (error) => {
        console.log('Ошибка при плана: ', error);
      });
  }

  getManagerPlan() {
    this.settingsManagerPlanService.getManagerPlan(
      {year: this.currentYear}
    ).then((data: InterFaceSettingsLevel[]) => {
        this.list = data;
      },
      (error) => {
        console.log('Ошибка при плана: ', error);
      });
  }

  // изменение значения
  changeItem(item, month) {
    const data = {year: item.year, branch: item.branch_id, month: month, val: item[month]};
    this.settingsManagerPlanService.updateManagerPlan(data).then(() => {
      },
      (error) => {
        console.log('Ошибка при плана: ', error);
      });
  }

  onChanged(increased: any) {
    this.currentYear = increased;
    this.getManagerPlan();
  }
}
