import {Component} from '@angular/core';
import {SettingsClientBonusService} from './settingsClientBonus.service';

@Component({
  selector: 'app-settings-client-bonus',
  templateUrl: './settingsClientBonus.component.html',
})
export class SettingsClientBonusComponent {
  list: InterFaceClientBonus[] = [];
  newList: InterFaceClientBonus = {
    id: null,
    name: '',
    max: '',
    min: ''
  };

  constructor(private settingsClientBonusService: SettingsClientBonusService) {
    this.getBonusList();
  }

  getBonusList() {
    this.settingsClientBonusService.getBonusList().then((data: InterFaceClientBonus[]) => {
        this.list = data;
      },
      (error) => {
        console.log('Ошибка', error);
      });
  }

  deleteBonusList(id) {
    this.settingsClientBonusService.deleteBonusList({'id': id}).then(() => {
        this.getBonusList();
      },
      (error) => {
        console.log('Ошибка', error);
      });
  }

  addBonusList() {
    this.settingsClientBonusService.addBonusList({
      'id': this.newList.id,
      'name': this.newList.name,
      'max': this.newList.max,
      'min': this.newList.min,
    }).then(() => {
        this.clear();
        this.getBonusList();
      },
      (error) => {
        console.log('Ошибка', error);
      });
  }

  changeItems(data) {
    this.newList = {
      id: data.id,
      name: data.name,
      max: data.max,
      min: data.min,
    };
  }

  clear() {
    this.newList = {
      id: null,
      name: '',
      max: '',
      min: ''
    };
  }

  showButton() {
    return this.newList.id === null ? 'Добавить' : 'Изменить';
  }
}
