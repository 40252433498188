import {Component} from '@angular/core';
import {EquipmentsService} from '../equipments/equipments.service';
import {Sort} from '@angular/material/sort';
import {DopParamsService} from '../../services/dopParams.service';
import * as moment from 'moment';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {GlobalParamsUser} from '../../storage/global-params-user';
import 'hammerjs';

@Component({
  selector: 'app-demand-report',
  templateUrl: './demand-report.component.html',
})
export class DemandReportComponent {
  p = 1;
  equipmentsList: InterFaceEquipmentsDemandReport[] = [];
  sortedData: InterFaceEquipmentsDemandReport[];
  stocks: InterFaceStocks[] = [];
  timerId = null;
  filters = {
    stock: [],
    date_start: '',
    date_end: '',
    like: '',
  };

  branches = [];
  branchesTmp = [];

  dropdownSettings = {
    idField: 'branch_id',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };

  showModal = '';

  constructor(private equipmentsService: EquipmentsService,
              private globalParamsMessage: GlobalParamsMessage,
              public globalParamsUser: GlobalParamsUser,
              private dopParamsService: DopParamsService) {
    //not upload without filter this.getEquipments();

    this.dopParamsService.getStock().then((data: InterFaceStocks[]) => {
        this.stocks = data;
      },
      (error) => {
        console.log('Ошибка при получении складов: ', error);
      });
  }

  changeFilter() {
    if (JSON.stringify(this.branches) !== JSON.stringify(this.branchesTmp)) {
      this.branchesTmp = this.branches;
      this.filters.stock = this.branches.map(item => item.branch_id);
      this.getEquipments();
    }
  }

  changeDateFilter(period) {
    this.filters.date_end = moment().format().slice(0, 10);
    const tmpDate = moment(this.filters.date_end);

    if (period === 'day') {
      this.filters.date_start = this.filters.date_end;
    }

    if (period === 'month') {
      this.filters.date_start = moment().startOf('month').format().slice(0, 10);
    }

    if (period === 'years') {
      this.filters.date_start = moment().startOf('year').format().slice(0, 10);
    }

    this.getEquipments();
  }

  // получение списка оборудования
  getEquipments() {
    const date1 = new Date(this.filters.date_start);
    const date2 = new Date(this.filters.date_end);

    if (date2 < date1) {
      this.globalParamsMessage.data = {title: `Период аренды указан некорректно`, type: 'error', body: ''};
      return false;
    }

    this.equipmentsService.getEquipmentsDemandReport({
      stock: this.filters.stock,
      like: this.filters.like,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
    }).then((data: InterFaceEquipmentsDemandReport[]) => {
        this.equipmentsList = data;
      },
      (error) => {
        console.log('Ошибка при получении списка оборудований: ', error);
      });
  }

  sortData(sort: Sort) {
    const data = this.equipmentsList.slice();

    this.equipmentsList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'count_demand':
          return compare(a.count_demand, b.count_demand, isAsc);
        case 'srok':
          return compare(a.srok, b.srok, isAsc);
        default:
          return 0;
      }
    });
  }

  mouseEnter(data) {
    return data.txt;
  }
  getHireTimeOut() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }


    this.timerId = setTimeout(() => {
      this.getEquipments();
    }, 1000);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
