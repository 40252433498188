import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';

@Injectable()
export class ApplicationsInformingService {

  constructor(private httpService: HttpService) {
  }


  // получение списка заявов
  public getApplicationInforming(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-applications-informing', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении заявок информирования', error);
            reject();
          }
        );
    });
  }
}
