import {Injectable} from '@angular/core';

@Injectable()
export class GlobalParamsUser {
  branch = {val: null, name: ''};
  type = null;
  rights = [];
  photo = '';
  settings = {
    sale_rub_limit: '',
    sale_percent_limit: '',
  };
}
