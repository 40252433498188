import {Component} from '@angular/core';
import {ApplicationsCreateNewService} from './applicationsCreateNew.service';
import {DopParamsService} from '../../services/dopParams.service';
import {EquipmentsService} from '../equipments/equipments.service';
import {ClientService} from '../client/client.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {Router} from '@angular/router';
import {GlobalParams} from '../../storage/global-params';
import {ApplicationsService} from '../applications/applications.service';
import {FinanceService} from '../finance/finance.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {EquipmentsCreateMiniService} from '../equipments_create_mini/equipmentsCreateMini.service';
import * as moment from 'moment';
import {DocumentService} from '../../services/document.service';
import {ShortHireService} from '../short_hire/short_hire.service';

@Component({
  selector: 'app-applications-create-new',
  templateUrl: './applicationsCreateNew.component.html',
})

export class ApplicationsCreateNewComponent {
  applicationsStatus: InterFaceDopParams[] = [];
  showApplicationsStatus: InterFaceDopParams[] = [];
  applicationsDelivery: InterFaceDopParams[] = [];
  applicationsTypeLeases: InterFaceDopParams[] = [];
  equipmentsTypeList: InterFaceDopParams[] = [];
  // отображение окна поиска клиента из бд
  showSearchClient = {
    show: false,
    filter: '',
    clients: []
  };

  changeStatus = true;
  showComments = false;
  showCatalogModal = false;

  // отображение окна добавления товара
  showAddEquipments = {
    show: false,
    filter: '',
    equipments: []
  };

  // добавление платежа
  addPay = {
    equipment_id: null,
    show: false,
    sum: '',
    cashBox: null,
    revertSum: false,
    currentDate: '',
    branch: null
  } as any;

  // список касс
  financeCashBox: InterFaceFinanceCashBox[];
  showFinanceCashBox: InterFaceFinanceCashBox[];
  // общая сумма
  allSum = 0;

  application: InterFaceNewApplicationNew = {
    client_id: null,
    equipments: [],
    sum: '0',
    count_eq: '',
    delivery_sum: '0',
    typeLease: {val: null, required: true, name: 'тип аренды'},
    sale: '0',
    srok: '',
    sale_in_sum: '0',
    status: {val: null, required: true, name: 'статус'},
    branch: {val: 0, required: true, name: 'филиал'},
    delivery: {val: null, required: true, name: 'тип доставки'},
    client_fio: {val: '', required: false, name: 'фио клиента'},
    client_email: {val: '', required: false, name: ''},
    comment: '',
    client_phone: {val: '', required: true, name: 'телефон клиента'},
    client_type: null,
    client_birth: '',
    month_sum: {val: '', required: false, name: 'Цена за месяц'},
    square: {val: null, required: false, name: 'Площадь лесов'},
    address: {val: '', required: false, name: 'Адрес размещения'},
    id_callibri: null,
    callibri_type: '',
    client_branch: null,
    client_status: '',
    client_comment: '',
    delivery_address: '',
    client_doc_type: '',
    reminder_date: null
  };

  insertClient: {};

  eqCategory: any = [];
  eqCategoryType: any = [];

  catalog = {
    category: '',
    type: ''
  };

  // вывод кнопки в зависимости от статуса
  buttonText = '';

  // короткая заявка
  shotHire: InterFaceNotification = {
    id: null,
    branch: null,
    source: null,
    client_phone: '',
    client_id: null,
    client_fio: '',
    client_email: '',
    comment: '',
    type: '',
  };
  // комментарий для отмены короткой заявки
  showCancelComment = false;
  showExitModal = false;
  cancelComment = '';

  keyCount = {
    1: {count: 0},
    2: {count: 0},
    3: {count: 0},
    4: {count: 0},
    5: {count: 0},
    6: {count: 0}
  };

  // переменная для опредления во время запроса типа оборудования (eq,demand,'')
  typeEquipments = '';

  // работа с приставами
  debts: InterFaceDebts = {
    show: false,
    data: [{
      bailiff: '',
      department: '',
      details: '',
      exe_production: '',
      ip_end: '',
      name: '',
      subject: ''
    }]
  };

  timerId = null;
  span_demand = false;

  list = {
    '5': ['Далеко', 'Дорого', 'На будущее', 'Согласовывает', 'Нужна доставка'],
    '4': ['Нет залога и документов'],
    '3': ['В аренде', 'В ремонте', 'Не хватает количества', 'Списан', 'Украден', 'Продан'],
    '2': [],
    '1': ['График работы', 'Реклама', 'По продлению', 'Узнал дорогу', 'Прочие звонки']
  };

  showList = [];
  branches: InterFaceDopParams[] = [];

  checkPledge = false;
  checkDelivery = false;
  checkSaveWorld;
  checkSaveWorldCount = 0;

  constructor(private applicationsCreateService: ApplicationsCreateNewService,
              private applicationsService: ApplicationsService,
              private dopParamsService: DopParamsService,
              private equipmentsService: EquipmentsService,
              private clientService: ClientService,
              private globalParamsMessage: GlobalParamsMessage,
              private shortHireService: ShortHireService,
              public globalParams: GlobalParams,
              private router: Router,
              public globalParamsUser: GlobalParamsUser,
              private documentService: DocumentService,
              private equipmentsCreateMiniService: EquipmentsCreateMiniService,
              public financeService: FinanceService) {
    // получаем информацию по новому созданному клиенту
    this.clientService.refreshClientInfo.subscribe(() => {
      this.application.client_id = this.clientService.miniClientInfo.client_id;
      this.application.client_fio.val = this.clientService.miniClientInfo.client_fio;
      this.application.client_email.val = this.clientService.miniClientInfo.client_email;
      this.application.client_phone.val = this.clientService.miniClientInfo.client_phone;
      this.application.client_doc_type = this.clientService.miniClientInfo.client_doc_type;
      this.application.client_type = this.clientService.miniClientInfo.client_type;
      this.changeStatus = this.clientService.miniClientInfo.changeStatus;

      if (typeof this.clientService.miniClientInfo.app_status !== 'undefined' && this.clientService.miniClientInfo.app_status !== '') {
        this.changeStatusApplications(this.clientService.miniClientInfo.app_status);
      }
    });

    if (this.globalParams.getShortHire === 2) {
      this.shortHireService.getCurrentList().then((data: any) => {
          this.shotHire = data;
          this.application.id_callibri = data.id;
          this.application.callibri_type = data.type;
          this.application.branch.val = data.branch;
          this.application.client_phone.val = data.client_phone;
          this.application.client_birth = data.client_birth;
          this.application.client_id = data.client_id;
          this.application.client_fio.val = data.client_fio;
          this.application.client_email.val = data.client_email;
          this.application.comment = data.comment;
          this.globalParams.getShortHire = 1;
        },
        (error) => {
          console.log('Ошибка при получении касс: ', error);
        });
    }

    this.financeService.getFinanceCashBOx().then((data: InterFaceFinanceCashBox[]) => {
        this.financeCashBox = data;
        this.showFinanceCashBox = this.financeCashBox.filter(item => item.zalog === '0' && item.delivery === '0');
      },
      (error) => {
        console.log('Ошибка при получении касс: ', error);
      });

    this.applicationsService.getApplicationsStatus().then((data: InterFaceDopParams[]) => {
        this.showApplicationsStatus = this.applicationsStatus = data;
        if (this.application.status.val === null) {
          this.changeStatusApplications(this.applicationsStatus[0].val);
        }
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });

    this.applicationsCreateService.getEqCategory().then((data) => {
        this.eqCategory = data;
      },
      (error) => {
        console.log('Ошибка при получении каталога: ', error);
      });

    this.applicationsCreateService.getApplicationsDelivery().then((data: InterFaceDopParams[]) => {
        this.applicationsDelivery = data;
        this.application.delivery.val = this.applicationsDelivery[1].val;
      },
      (error) => {
        console.log('Ошибка при получении статусов доставки: ', error);
      });

    this.applicationsCreateService.getApplicationsTypeLease().then((data: InterFaceDopParams[]) => {
        this.applicationsTypeLeases = data;
        this.application.typeLease.val = data[0].val;
        this.changeTypeLease();
      },
      (error) => {
        console.log('Ошибка при получении типов аренды: ', error);
      });

    this.equipmentsService.getEquipmentsType().then((data: InterFaceDopParams[]) => {
        this.equipmentsTypeList = data;
      },
      (error) => {
        console.log('Ошибка при получении списка типов оборудования: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.searchAllClientBranch('');
    this.getEquipments('');
  }

  // изменение типа доставки
  changeTypeLease(dt = null) {
    if (dt !== null) {
      this.application.typeLease.val = dt;
    }
    const time_diff = 0;
    const _this = this;
    this.application.equipments.forEach(function (value) {
      if (value.rent_start === null) {
        value.rent_start = moment().format().slice(0, 16);
      } else {
        value.rent_start = moment(value.rent_start).format().slice(0, 16);
      }

      const tmpDate = moment(value.rent_start);

      if (time_diff === 0) {
        if (_this.application.typeLease.val === 1) {
          value.rent_end = tmpDate.add(24, 'hours').format().slice(0, 10);
        } else {
          value.rent_end = tmpDate.add(30, 'day').format().slice(0, 10);
        }
      } else {
        const currentDate = moment().add(time_diff, 'hours').format();
        value.rent_start = currentDate.slice(0, 16);

        if (_this.application.typeLease.val === 1) {
          value.rent_end = tmpDate.add(24 + time_diff, 'hours').format().slice(0, 10);
        } else {
          value.rent_end = tmpDate.add(30 + time_diff, 'day').format().slice(0, 10);
        }
      }

      value.countDay = 1;
    });

    this.changeSum();
  }

  // запрос оборудования
  getEquipments(filter = '') {
    this.equipmentsService.allEquipmentsBranchNew({
      filter: filter,
      branch: this.application.branch.val,
      applicationStatus: this.typeEquipments,
      category: this.catalog.category,
      type: this.catalog.type
    }).then((data: any) => {
        if (this.application.equipments.length !== 0) {
          const eqArr = this.application.equipments.map(item => item.id);
          this.showAddEquipments.equipments = data.filter(item => eqArr.indexOf(item.id) === -1);
        } else {
          this.showAddEquipments.equipments = data;
        }

        this.showAddEquipments.show = true;
      },
      (error) => {
        console.log('Ошибка при получении списка оборудования: ', error);
      });
  }

  // вычесляем сумму аренды
  changeSum() {
    if (this.application.equipments.length === 0) {
      return true;
    }

    if (+this.application.sale_in_sum > +this.globalParamsUser.settings.sale_rub_limit) {
      this.application.sale_in_sum = this.globalParamsUser.settings.sale_rub_limit;
      this.globalParamsMessage.data = {
        title: `Максимальная скидка ` + this.globalParamsUser.settings.sale_rub_limit,
        type: 'error',
        body: ''
      };
    }
    if (+this.application.sale > +this.globalParamsUser.settings.sale_percent_limit) {
      this.application.sale = this.globalParamsUser.settings.sale_percent_limit;
      this.globalParamsMessage.data = {
        title: `Максимальная скидка ` + this.globalParamsUser.settings.sale_percent_limit,
        type: 'error',
        body: ''
      };
    }
    this.application.delivery_sum = this.application.delivery.val === 1 ? this.application.delivery_sum : '0';

    this.applicationsService.hireChangeSum({
      equipments: this.application.equipments,
      sale: this.application.sale,
      sale_in_sum: this.application.sale_in_sum,
      typeLease: this.application.typeLease.val,
      lesa: 0,
      month_sum: 0
    }).then((result: any) => {
        this.application.sum = result.sum;
        this.application.equipments = result.equipments;
        this.allSum = parseFloat(this.application.sum) + parseFloat(this.application.delivery_sum);
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });
  }

  changeDateEnd(index) {
    this.application.equipments[index].rent_end = moment(this.application.equipments[index].rent_end).format().slice(0, 10);
    this.changeSum();
  }

  // смена статуса заявки
  changeStatusApplications(val) {
    if (this.application.status.val === 3 || val === 3) {
      this.application.equipments = [];
    }

    this.application.status.val = val;

    if (val === 1) {
      this.buttonText = 'Создание проката';
    }
    if (val === 2) {
      this.buttonText = 'Создание брони';
    }
    if (val === 3) {
      this.buttonText = 'Создание спроса';
    }
    if (val === 4) {
      this.buttonText = 'Создание отказа';
    }
    if (val === 5) {
      this.buttonText = 'Создание "узнали"';
    }
  }

  // поиск клиентов из бд
  searchAllClientBranch(filter) {
    this.clientService.searchAllClientBranch({
      branch: this.application.branch.val,
      filter: filter
    }).then((data: InterFaceSearchClient[]) => {
        this.showSearchClient.clients = data;
      },
      (error) => {
        console.log('Ошибка при получении списка сотрудников: ', error);
      });
  }

  // показ модального окна для добавления платежа
  showInsertSum(equipment_id) {
    this.addPay = {
      equipment_id,
      show: true,
      sum: '',
      cashBox: null,
      revertSum: false,
      currentDate: moment().format().slice(0, 16),
      branch: this.application.branch.val
    };
  }

  // заполнение данными из справочника
  insertClientData(index) {
    const tmpClient = this.showSearchClient.clients[index];

    this.application.client_id = tmpClient.id;
    this.application.client_phone.val = tmpClient.phone;
    this.insertClient = tmpClient;

    this.showSearchClient.show = false;

    this.showSearchClient = {
      show: false,
      filter: '',
      clients: []
    };
  }

  // возврат платежа
  changeRevert() {
    this.addPay.revertSum = !this.addPay.revertSum;
  }

  // выбор оборудования
  insertEquipmentsDataStatus(index, count = 1, force = false) {
    this.showCatalogModal = false;
    if (this.typeEquipments === 'demand' && this.application.equipments.length > 0) {
      this.globalParamsMessage.data = {title: `В спрос можно добавить только одну позицию`, type: 'error', body: ''};
      return false;
    }

    const eq = this.showAddEquipments.equipments[index];
    this.showAddEquipments.equipments.splice(index, 1);
    this.insertEquipmentsDataStatusSecond(eq, count);
    this.changeTypeLease();
  }

  changeCheckStatus() {
    const ch = this.application.equipments.filter(item => item.check_click === '0');

    if (ch.length > 0) {
      this.changeStatusApplications(2);
      this.showApplicationsStatus = this.applicationsStatus.filter(item => item.val !== 3 && item.val !== 1 && item.val !== 6);
    } else {
      this.showApplicationsStatus = this.applicationsStatus.filter(item => item.val !== 3 && item.val !== 6);
    }
  }

  // выбор оборудования
  insertEquipmentsDataStatusSecond(eq, count) {
    this.insertEquipmentsData(eq, count);
    // зачищать или нет список оборудования
    this.showAddEquipments.filter = '';
    this.showAddEquipments.show = false;

    // определяем тип оборудования и меняем его статус
    if (eq.type === 'eq') {
      this.typeEquipments = 'eq';
      this.application.branch.val = eq.branch_id;
      this.application.branch.name = eq.branch_name;

      this.changeCheckStatus();
    } else {
      this.showAddEquipments.equipments = [];
      this.typeEquipments = 'demand';

      this.application.branch.val = this.globalParamsUser.branch.val;
      this.application.branch.name = this.globalParamsUser.branch.name;
      this.showApplicationsStatus = this.applicationsStatus.filter(item => item.val === 3);
      this.application.status.val = 3;
    }

    this.changeSum();
  }

  // выбор оборудования
  insertEquipmentsData(eq, count = 1, force = false) {
    const rent_start = moment().format().slice(0, 16);
    const rent_end = moment().add(1, 'days').format().slice(0, 16);
    const tmp = {
      id: eq.id,
      name: eq.name,
      in_stock: eq.count,
      type: eq.type,
      count: count,
      price: eq.price_per_day,
      photo: eq.photo,
      photo_alias: eq.photo_alias,
      allCount: eq.count,
      check_click: eq.check_click,
      payList: [],
      comment: '',
      countDay: 1,
      rent_start: rent_start,
      rent_end: rent_end,
      discount: 0,
    };
    this.application.equipments.push(tmp);
  }

  // проверяем есть клиент или нет, если есть - создаем просто заявку, если нет - то отправляем команду сначала на создание клиента
  addApplication(checkSaveWorld) {
    this.checkSaveWorld = checkSaveWorld;

    if (this.application.client_id === null && [3, 4, 5].indexOf(this.application.status.val) === -1) {
      this.checkSaveWorldCount++;
    } else {
      this.addApplicationSecond(checkSaveWorld);
    }
  }

  // вызов создания завяки с компонента создания клиента
  onChangedCreate(data) {
    this.application.client_id = data.client_id;
    this.application.client_phone = data.client_phone;
    this.addApplication(this.checkSaveWorld);
  }

  // создание заявки
  addApplicationSecond(checkSaveWorld) {
    if ([3, 4, 5].indexOf(this.application.status.val)) {
      for (const value in this.application) {
        if (value === 'client_id') {
          continue;
        }
        if (this.application.status.val === 2 || this.application.status.val === 1) {
          if (this.application.hasOwnProperty(value) && typeof this.application[value] !== 'undefined') {

            if (this.application[value] !== null) {
              if (this.application[value].required &&
                (this.application[value].val === '' || this.application[value].val === null)) {
                this.globalParamsMessage.data = {
                  title: `Необходимо заполнить поле "${this.application[value].name}"`,
                  type: 'error',
                  body: ''
                };
                return false;
              }
            }
          }
        }
      }

      const _this = this;
      this.application.equipments.forEach(function (value) {
        value.rent_end = value.rent_end.substr(0, 10) + value.rent_start.substr(-6, 6);
        const date1 = new Date(value.rent_start);
        const date2 = new Date(value.rent_start);

        if (date2 < date1) {
          _this.globalParamsMessage.data = {title: `Период аренды указан некорректно`, type: 'error', body: ''};
          return false;
        }
      });

      if ([4, 5].indexOf(this.application.status.val) !== -1 && this.application.equipments.length === 0) {
        this.insertEquipmentsData(1, 1, true);
      }
    }

    if ((this.application.status.val === 4 || this.application.status.val === 5)) {

      if (this.application.comment === '') {
        this.globalParamsMessage.data = {title: `Необходимо указать комментарий`, type: 'error', body: ''};
        return false;
      }
    }

    if (this.application.status.val === 3 && this.application.srok === '') {
      this.globalParamsMessage.data = {title: `Необходимо указать срок`, type: 'error', body: ''};
      return false;
    }
    let diffInSeconds = null;
    if (this.application.reminder_date) {
      const startDate = new Date();
      const endDate = new Date(this.application.reminder_date);
      diffInSeconds = this.dateDiffInSeconds(startDate, endDate);
    }
    this.applicationsCreateService.addApplication({
      equipments: this.application.equipments,
      typeLease: this.application.typeLease.val,
      sale: this.application.sale,
      sale_in_sum: this.application.sale_in_sum,
      delivery: this.application.delivery.val,
      client_id: this.application.client_id,
      sum: this.application.sum,
      srok: this.application.srok,
      delivery_sum: this.application.delivery_sum,
      comment: this.application.comment,
      branch: this.application.branch.val,
      status: this.application.status.val,
      lesa: false,
      month_sum: this.application.month_sum.val,
      square: this.application.square.val || 0,
      address: this.application.address.val,
      client_phone: this.application.client_phone.val,
      id_callibri: this.application.id_callibri,
      callibri_type: this.application.callibri_type,
      delivery_address: this.application.delivery_address,
      span_demand: this.span_demand,
      count_eq: this.application.count_eq,
      reminder_date: this.application.reminder_date,
      remind_diff: diffInSeconds
    }).then((result: any) => {
        this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
        this.globalParams.getShortHire = 1;

        if (checkSaveWorld && result.length > 0) {
          const _this = this;
          _this.documentService.getWord({hireInfoId: result[0], lesa: 0, check: 1, multiple: true}).then((resultDoc: any) => {
              window.open(_this.globalParams.path + '/uploads/tmp/' + resultDoc, '_blank');
            },
            (error) => {
              console.log('Ошибка при скачивании документа: ', error);
            });
        }

        if (!checkSaveWorld) {
          result.forEach(function (value) {
            window.open('https://str-stroika-arenda.ru/hire/' + value);
          });
        }

        if (this.application.status.val === 3) {
          this.redirectTo('/demand');
        } else if (this.application.status.val === 4 || this.application.status.val === 5) {
          this.redirectTo('/application');
        } else {
          this.redirectTo('/hire');
        }

        this.globalParams.createAppNew = false;
      },
      (error) => {
        console.log('Ошибка при добавлении новой заявки: ', error);
      });
  }

  // удаление оборудования из списка
  deleteEquipment(index) {
    this.application.equipments.splice(index, 1);
    this.changeCheckStatus();
    this.changeSum();
  }

  // проверка через приставов
  getBailiffs() {
    this.clientService.getBailiffs({
      fio: this.application.client_fio.val,
      region: this.application.client_branch,
      type: this.application.client_type
    }).then((data: any) => {
        if (data.length === 0) {
          this.globalParamsMessage.data = {title: 'У клиента нет долгов', type: 'success', body: ''};
        }

        if (data.length !== 0) {
          this.debts = {show: true, data: data};
        }

      },
      (error) => {
        console.log('Ошибка при получении данных от приставов: ', error);
      });
  }
  dateDiffInSeconds(date1, date2) {
    const diffInMilliseconds = Math.abs(date2 - date1);
    return Math.floor(diffInMilliseconds / 1000);
  }
  // добавляем платеж
  addPayList() {
    if (this.addPay.sum === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать сумму', type: 'error', body: ''};
      return false;
    }

    if (this.addPay.cashBox === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }

    if (this.checkDelivery && this.checkPledge) {
      this.globalParamsMessage.data = {title: 'Нельзя выбрать и "залог" и "доставка"!', type: 'error', body: ''};
      return false;
    }

    const fCashBox = this.financeCashBox.filter(item => item.val === this.addPay.cashBox)[0];
    const fCashBoxTxt = fCashBox.name.split(' - ');

    let fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.zalog === '0' && item.delivery === '0');

    if (this.checkDelivery) {
      fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.delivery === '1');
    }

    if (this.checkPledge) {
      fCashBoxCurrent = this.financeCashBox.filter(item => item.name.indexOf(fCashBoxTxt[0]) !== -1 && item.zalog === '1');
    }

    if (fCashBoxCurrent.length === 0) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }
    this.addPay.cashBox = fCashBoxCurrent[0].val;

    const eq = this.application.equipments.filter(item => item.id === this.addPay.equipment_id);

    eq[0].payList.push({
      sum: this.addPay.sum,
      cashBox: this.addPay.cashBox,
      revertSum: this.addPay.revertSum,
      currentDate: this.addPay.currentDate,
      branch: this.addPay.branch
    });
    this.clear();
  }

  // удаление платежа
  deletePayList(equipment, index) {
    equipment.payList.splice(index, 1);
  }

  clear() {
    this.addPay = {equipment_id: null, show: false, sum: '', cashBox: null, revertSum: false, currentDate: '', branch: null};
  }

  // отмена заявки с Callibri
  sendCancelHire() {
    if (this.cancelComment === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать комментарий', type: 'error', body: ''};
      return false;
    }

    this.shortHireService.sendCancelHire({
      cancelComment: this.cancelComment,
      id_callibri: this.application.id_callibri
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Заявка успешно отменена', type: 'success', body: ''};
        this.globalParams.getShortHire = 1;
        this.router.navigate(['/main']);
      },
      (error) => {
        console.log('Ошибка при отмене заявки: ', error);
      });
  }

  // создание нового оборудования
  addEquipment() {
    if (this.showAddEquipments.filter === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать наименование', type: 'error', body: ''};
      return false;
    }

    this.equipmentsCreateMiniService.addEquipment({
      model: this.showAddEquipments.filter,
    }).then(() => {
        this.getEquipments(this.showAddEquipments.filter);
      },
      (error) => {
        console.log('Ошибка при добавлении нового пользователей: ', error);
      });
  }

  checkChangeDate() {
    if (this.globalParamsUser.rights.indexOf(3) !== -1) {
      return false;
    } else if (this.application.status.val === 1) {
      return true;
    }

    return false;
  }

  changeCountDay(index) {
    const equipments = this.application.equipments[index];
    const tmpDate = moment(equipments.rent_start);

    if (this.application.typeLease.val === 1) {
      equipments.rent_end = tmpDate.add(24 * equipments.countDay, 'hours').format().slice(0, 10);
    } else {
      equipments.rent_end = tmpDate.add(30 * equipments.countDay, 'day').format().slice(0, 10);
    }

    this.changeSum();
  }

  changeCountDayOne(type, index) {
    const equipments = this.application.equipments[index];
    const tmpDate = moment(equipments.rent_start);

    if (type === 'plus') {
      if (this.application.typeLease.val === 1) {
        equipments.rent_end = moment(equipments.rent_end).add(24, 'hours').format().slice(0, 10);
      } else {
        equipments.rent_end = moment(equipments.rent_end).add(30, 'day').format().slice(0, 10);
      }
    } else {
      if (this.application.typeLease.val === 1) {
        equipments.rent_end = moment(equipments.rent_end).subtract(24, 'hours').format().slice(0, 10);
      } else {
        equipments.rent_end = moment(equipments.rent_end).subtract(30, 'day').format().slice(0, 10);
      }
    }

    this.changeSum();
  }

  getHireTimeOut() {
    this.catalog = {category: '', type: ''};
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }


    this.timerId = setTimeout(() => {
      this.getEquipments(this.showAddEquipments.filter);
    }, 1000);
  }

  checkStatus() {
    return this.application.status.val !== 3 && this.application.status.val !== 4 && this.application.status.val !== 5;
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
  }

  changeApp(data) {
    if (data === 'remote') {
      this.globalParams.createAppRemote = true;
      this.globalParams.createAppNew = false;
    }
    if (data === 'lesa') {
      this.globalParams.createAppLesa = true;
      this.globalParams.createAppNew = false;
    }
  }

  changeCommit() {
    this.showList = this.list[this.application.status.val];
  }

  changeShowCatalog() {
    this.showAddEquipments.equipments = [];
    this.showCatalogModal = true;
    this.eqCategoryType = this.eqCategory[0].type;
    this.catalog.category = this.eqCategory[0].id;
    this.getEquipments('');
  }

  tapCategory(data) {
    this.eqCategoryType = data.type;
    this.catalog.category = data.id;
    this.getEquipments('');
  }

  tapType(data) {
    this.catalog.type = data.id;

    this.getEquipments('');
  }

  onChangedPay(increased: any, data: any, type: string) {
    data[type] = increased;
  }

  onChangedAddPay(increased: any, type: string) {
    this.addPay[type] = increased;
  }

  onChangedApplication(increased: any) {
    this.application.delivery.val = increased;
    this.changeSum();
  }
}
