import {Component} from '@angular/core';
import {ApplicationsBronService} from './applicationsBron.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import * as moment from 'moment';
import {DopParamsService} from '../../services/dopParams.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {GlobalParams} from '../../storage/global-params';
import {CdkDragDrop, CdkDragStart, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {HireService} from '../hire/hire.service';
import {MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-applications-bron',
  templateUrl: './applicationsBron.component.html',
})
export class ApplicationsBronComponent {
  p = 1;
  // отображение полей
  showActiveFields = false;
  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};
  // отображение колонок таблицы
  showTableColumns = false;

  filters = {
    sort: {active: '', direction: ''},
    offset: 0,
    limit: 50,
    branch: [],
    date_start: '',
    date_end: '',
    phone: '',
    client: '',
    eq: '',
    comment: '',
  };

  cdkDragDisabled = true;
  previousIndex: number;
  displayedColumns: string[] = [];
  activeFieldsTablesSecond = [];
  applications: InterFaceApplicationsBron[] = [];
  sortedData: InterFaceApplicationsBron[];
  hiresCount = null;
  currentBron: InterFaceApplicationsBron = {
    id: null,
    branch: '',
    client_phone: '',
    recording: '',
    client_fio: '',
    comment: '',
    date_create: '',
    date_bron: '',
    date_bron_format: '',
    user: '',
    eq_id: '',
    eq_name: ''
  };

  changeModal = {
    date: '',
    comment: '',
    show: false
  };

  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  dataSource: any = [];
  branchS = [];
  branchTmp = [];
  branches: InterFaceDopParams[] = [];

  dropdownSettingsStatus = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };

  showFilters = false;
  timerId = null;

  constructor(private applicationsBronService: ApplicationsBronService,
              private globalParamsMessage: GlobalParamsMessage,
              public globalParamsUser: GlobalParamsUser,
              public hireService: HireService,
              public globalParams: GlobalParams,
              private dopParamsService: DopParamsService) {

    this.cdkDragDisabled = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(window.navigator.userAgent));

    this.applicationsBronService.getApplicationsBronFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении списка полей заявки: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.getApplicationsBron();
  }

  // отображение полей
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }

  // отображение фильтра
  changeShowFilters() {
    this.showFilters = !this.showFilters;
  }

  // изменение отображений записекй у полей
  changeShowFields() {
    this.activeFieldsTablesSecond = [];
    for (const value of this.activeFields) {
      if (value.flag === 1) {
        this.activeFieldsTablesSecond.push(value);
      }
      this.activeFieldsTables[value.code] = value.flag;
    }
    this.setDisplayedColumns(false);
  }

  // изменение списка активных полей
  changeFields() {
    this.applicationsBronService.changeBronFields({data: this.activeFields}).then(() => {
        this.showActiveFields = false;
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }
  // отображение колонок таблицы
  changeTableColumns() {
    this.showTableColumns = !this.showTableColumns;
  }
  // изменение списка полей
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  getApplicationsBron() {
    this.applicationsBronService.getApplicationsBron({
      sort: Object.keys(this.filters.sort).length !== 0,
      offset: this.filters.offset,
      branch: this.filters.branch,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      phone: this.filters.phone,
      client: this.filters.client,
      eq: this.filters.eq,
      comment: this.filters.comment,
    }).then((data: { data: InterFaceApplicationsBron[], hiresCount: number }) => {
        this.sortedData = data.data.slice();
        this.hiresCount = data.hiresCount;

        if (Object.keys(this.filters.sort).length !== 0) {
          this.applications = data.data;
          this.sortData(this.filters.sort);
        } else {
          this.applications = data.data;
        }

        this.dataSource = new MatTableDataSource(this.applications);
      },
      (error) => {
        console.log('Ошибка при получении списка заявок брони: ', error);
      });
  }

  // очистка фильтра
  clearFilter() {
    this.filters = {
      sort: {active: '', direction: ''},
      offset: 0,
      limit: 50,
      branch: [],
      date_start: '',
      date_end: '',
      phone: '',
      client: '',
      eq: '',
      comment: '',
    };

    this.getApplicationsBron();
  }

  sortData(sort) {
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.sort = sort;
      this.getApplicationsBron();
    } else {
      const data = this.applications.slice();
      this.applications = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'client_fio':
            return compare(a.client_fio, b.client_fio, isAsc);
          case 'client_phone':
            return compare(a.client_phone, b.client_phone, isAsc);
          case 'user':
            return compare(a.user, b.user, isAsc);
          case 'eq_name':
            return compare(a.eq_name, b.eq_name, isAsc);
          case 'date_create':
            const date_create_a = new Date(a.date_create).getTime();
            const date_create_b = new Date(b.date_create).getTime();
            return compare(date_create_a, date_create_b, isAsc);
          case 'date_bron':
            const date_bron_a = new Date(a.date_bron).getTime();
            const date_bron_b = new Date(b.date_bron).getTime();
            return compare(date_bron_a, date_bron_b, isAsc);
          case 'branch':
            return compare(a.branch, b.branch, isAsc);
          default:
            return 0;
        }
      });
    }
  }

  changePage(data) {
    this.p = data;
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.offset = this.p * 50 - 50;
      this.getApplicationsBron();
    }
  }

  // изменить бронь
  changeApp(data) {
    this.currentBron = data;

    this.changeModal = {
      date: moment(data.date_bron_format).format().slice(0, 16),
      comment: '',
      show: true
    };
  }

  // очистка полей
  clearChangeModal() {
    this.changeModal = {
      date: '',
      comment: '',
      show: false
    };

    this.currentBron = {
      id: null,
      branch: '',
      client_phone: '',
      recording: '',
      client_fio: '',
      comment: '',
      date_create: '',
      date_bron: '',
      date_bron_format: '',
      user: '',
      eq_id: '',
      eq_name: ''
    };
  }

  // изменить бронь
  saveChangeModal(data) {
    if (data === 'cancel' && this.changeModal.comment === '') {
      this.globalParamsMessage.data = {title: `Необходимо указать комментарий`, type: 'error', body: ''};
      return false;
    }

    this.applicationsBronService.changeApplicationsBron({
      id: this.currentBron.id,
      date_bron: moment(this.changeModal.date).format().slice(0, 16),
      comment: this.changeModal.comment,
      status: data
    }).then(() => {
        this.getApplicationsBron();
        this.clearChangeModal();
      },
      (error) => {
        console.log('Ошибка при измнении брони: ', error);
      });
  }

  // подтвердить бронь
  confirmBron() {
    this.applicationsBronService.confirmBron({
      id: this.currentBron.id
    }).then(() => {
        this.getApplicationsBron();
        this.clearChangeModal();
      },
      (error) => {
        console.log('Ошибка при измнении брони: ', error);
      });
  }

  changeCount(count) {
    this.filters.limit = count;
    this.getApplicationsBron();
  }

  changeFilterBranch() {
    if (JSON.stringify(this.branchS) !== JSON.stringify(this.branchTmp)) {
      this.branchTmp = this.branchS;
      this.filters.branch = this.branchS.map(item => item.val);
      this.getApplicationsBron();
    }
  }

  // изменение списка активных полей
  clearApplicationsBron() {
    this.applicationsBronService.clearApplicationsBron().then(() => {
        this.getApplicationsBron();
      },
      () => {
        console.log('Ошибка при удалении броней');
      });
  }

  setDisplayedColumns(update) {
    this.activeFieldsTablesSecond.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.code;
    });

    if (update) {
      this.hireService.sortColumn({data: this.displayedColumns, type: 'bron'}).then(() => {
        },
        () => {
          console.log('Ошибка при сортировке колонок');
        });
    }
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.activeFieldsTablesSecond, this.previousIndex, index);
      this.setDisplayedColumns(true);
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event) {
      moveItemInArray(this.activeFieldsTablesSecond, event.previousIndex, event.currentIndex);
      this.setDisplayedColumns(true);
    }
  }
  getHireTimeOut() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }


    this.timerId = setTimeout(() => {
      this.filters.offset = 0;
      this.p = 1;
      this.getApplicationsBron();
    }, 1000);
  }
}

function compare(a: Date | number | string, b: Date | number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
