import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SettingsSaleDaysService {
  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  public addSaleDays(data) {
      return new Promise((resolve, reject) => {
        this.httpService.prepareQuery('add-sale-days', data)
          .then((result: any) => {
              this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
              resolve('');
            },
            (error) => {
              console.log('Ошибка при добавлении скидок', error);
              reject();
            }
          );
      });
    }

    public getSaleDays() {
      return new Promise((resolve, reject) => {
        this.httpService.prepareQuery('get-sale-days', '')
          .then((result: any) => {
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении скидок', error);
              reject();
            }
          );
      });
    }

    public deleteSaleDays(data) {
      return new Promise((resolve, reject) => {
        this.httpService.prepareQuery('delete-sale-days', data)
          .then((result: any) => {
              this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
              resolve('');
            },
            (error) => {
              console.log('Ошибка при удалении скидок', error);
              reject();
            }
          );
      });
    }
}
