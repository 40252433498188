import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';

@Injectable()
export class ApplicationsProcessedService {
  applicationsFieldsList: InterFaceActiveFields[] = [];

  constructor(private httpService: HttpService) {
  }

  // получение списка активных полей
  public getApplicationsFields() {
    return new Promise((resolve, reject) => {
      if (this.applicationsFieldsList.length === 0) {
        this.httpService.prepareQuery('get-applications-processed-field', '')
          .then((result: InterFaceActiveFields[]) => {
              this.applicationsFieldsList = result;
              resolve(result);
            },
            (error) => {
              console.log('Ошибка при получении списка полей для заявок', error);
              reject();
            }
          );
      } else {
        resolve(this.applicationsFieldsList);
      }
    });
  }

  // получение списка заявов
  public getApplicationProcessed(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-applications-processed', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении заявок', error);
            reject();
          }
        );
    });
  }

  // очистка заявок
  public clearApplicationsProcessed(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('clear-applications-processed', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при очистке заявок', error);
            reject();
          }
        );
    });
  }
}
