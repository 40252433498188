import {Component, OnInit} from '@angular/core';
import {FinanceService} from './finance.service';
import {Router} from '@angular/router';
import {DopParamsService} from '../../services/dopParams.service';
import {Sort} from '@angular/material/sort';
import {ApplicationsCreateService} from '../applications-create/applicationsCreate.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {GlobalParamsUser} from '../../storage/global-params-user';
import * as moment from 'moment';
import { from } from 'rxjs';
import { groupBy, mergeMap, reduce  } from 'rxjs/operators';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
})
export class FinanceComponent implements OnInit {
  p = 1;
// отображение фильтра
  showFilters = false;
  // отображение выборки для выручку
  showRevenue = false;
  showDetailRevenue = false;
  revenueData = [];
  // отображение фильтра
  showActiveFields = false;
  // список активных полей
  activeFields: InterFaceActiveFields[] = [];
  // список активных полей
  activeFieldsTables = {};
  financeCategory: InterFaceDopParams[] = [];
  financeType: InterFaceDopParams[] = [];
  branches: InterFaceDopParams[] = [];
  financeCashBox: InterFaceFinanceCashBox[] = [];
  // список финансов
  financeList: InterFaceFinance[] = [];
  sortedData: InterFaceFinance[] = [];
  // список финансов
  operationSum: InterFaceFinanceSum = {rate: [], income: []};
  // сумма всех финансов
  allSum = '0';
  allSumAll = '0';
  // сумма дохода
  income_sum = '0';
  // сумма расхода
  rate_sum = '0';
  applicationsSource: InterFaceDopParams[] = [];
  finance: InterFaceNewFinance = {
    name: '',
    category: null,
    source: null,
    type: null,
    sum: '',
    cashBox: null,
    branch: null,
    date: moment().format().slice(0, 16),
    by_finance_page: true,
  };
  today = moment().format('D.D.MMY');
  dateChecker = moment().format('Y-MM-DD');
  // фильтр
  filters: InterFaceFinanceFilter = {
    like: '',
    category: null,
    cashBox: [],
    type: 0,
    date_start: moment().format('Y-MM-DD'),
    date_end: moment().format('Y-MM-DD'),
    branch: null,
    sort: {},
    offset: 0,
    limit: 50
  };
  showCreate = false;
  sendReport = false;
  cashboxData: any = {
    yesterday: 0,
    today: 0,
    amount: 0,
  };
  sendReportData: any =  {
    income: [],
    expense: [],
    list: [],
    income_sum: 0,
    rate_sum: 0,
    cashBoxAmount: 0,
  };

  paginationParams: InterFaceFinancePagination = {
    totalCount: 0
  };

  dropdownSettingsDopStatus = {
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    selectAllText: 'Все',
    unSelectAllText: 'Отменить',
  };

  // по сколько страниц показывать
  limits = [
    {name: 50, val: 50},
    {name: 100, val: 100},
    {name: 150, val: 150},
    {name: 200, val: 200},
  ];

  financeCount = null;
  showZalog=false;

  constructor(private financeService: FinanceService,
              private dopParamsService: DopParamsService,
              public globalParamsUser: GlobalParamsUser,
              private applicationsCreateService: ApplicationsCreateService,
              private router: Router,
              private globalParamsMessage: GlobalParamsMessage) {

    this.financeService.getFinanceCategory().then((data: InterFaceDopParams[]) => {
        this.financeCategory = data;
      },
      (error) => {
        console.log('Ошибка при получении категорий: ', error);
      });

    this.financeService.getFinanceType().then((data: InterFaceDopParams[]) => {
        this.financeType = data;
      },
      (error) => {
        console.log('Ошибка при получении типов: ', error);
      });

    this.financeService.getFinanceCashBOx().then((data: InterFaceFinanceCashBox[]) => {
        this.financeCashBox = data;
      },
      (error) => {
        console.log('Ошибка при получении касс: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        if (this.globalParamsUser.type !== 1) {
          this.filters.branch = this.globalParamsUser.branch.val;
          const accessBranch = data.filter(item => item.val === this.globalParamsUser.branch.val);
          this.branches = accessBranch;
          this.finance.branch = this.globalParamsUser.branch.val;
        } else {
          this.branches = data;
        }
      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.financeService.getActiveFields().then((data: InterFaceActiveFields[]) => {
        this.activeFields = data;

        this.changeShowFields();
      },
      (error) => {
        console.log('Ошибка при получении источников: ', error);
      });

    this.applicationsCreateService.getApplicationsSource().then((data: InterFaceDopParams[]) => {
        this.applicationsSource = data;
      },
      (error) => {
        console.log('Ошибка при получении источников: ', error);
      });

    this.getFinance();
  }

  ngOnInit() {
  }

  financeDetails(finance_id) {
    this.router.navigate(['/finance/' + finance_id]);
  }
  getRevenueModal() {
    let branchFiltered = null;
    if (this.filters.branch > 0) {
      branchFiltered = [this.filters.branch];
    }
    this.financeService.getFinance({
      cashBox: [{val: 11}, {val: 12}, {val: 13}, {val: 14}, {val: 15}, {val: 26},
      ],
      type: 0,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      branch: branchFiltered,
    }).then((data: any) => {
      const source = from(data.list);
      source.pipe(
        groupBy(p => {
          return p['date_create'];
        }),
        mergeMap((group$) => group$.pipe(reduce((acc, cur) => [...acc, cur], []))),
      ).subscribe(p => {
        let amount  = 0;
        p.forEach(finance => {
          amount += finance.sum;
        });
        this.revenueData.push({
          date: p[0].date_create,
          amount: amount});
      });
      this.showDetailRevenue = true;
    });

  }
  changeType(data) {
    this.filters.type = data;
    this.getFinance();
  }

  // отображен ие фильтра
  changeShowFilters() {
    this.showFilters = !this.showFilters;
    this.showRevenue = false;
  }
  // отображен выборки для выручки
  changeShowRevenue() {
    this.showRevenue = !this.showRevenue;
    this.showFilters = false;
}
  // отображение активных полей
  changeActiveFields() {
    this.showActiveFields = !this.showActiveFields;
  }

  // изменение прав у нового пользователя
  changeCheckbox(i) {
    this.activeFields[i].flag = this.activeFields[i].flag === 0 ? 1 : 0;
    this.changeShowFields();
  }

  // изменение флага
  changeCheckboxFlag(data) {
    data.flag = data.flag === '1' ? '2' : '1';
    this.financeService.changeCheckboxFlag({id: data.id, flag: data.flag}).then(() => {
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });
  }

  // изменение списка активных полей
  changeFields() {
    this.financeService.changeFields({data: this.activeFields}).then(() => {
      },
      () => {
        console.log('Ошибка при изменение списка отображаемых полей');
      });

    this.showActiveFields = !this.showActiveFields;
  }

  // изменение отображений записей у полей
  changeShowFields() {
    for (const value of this.activeFields) {
      this.activeFieldsTables[value.code] = value.flag;
    }
  }

  // изменение статуса
  changeStatus(finance) {
    this.financeService.updateStatusFinance({finance_id: finance.id, finance_category: finance.category_id}).then(() => {
      },
      (error) => {
        console.log('Ошибка при изменении статуса у финансов: ', error);
      });
  }

  // очистка фильтра
  clearFilters() {
    this.filters = {
      like: '',
      category: null,
      cashBox: [],
      type: 0,
      date_start: '',
      date_end: '',
      branch: null,
      sort: {},
      offset: 0,
      limit: 50
    };

    this.getFinance();
  }

  // установка кассы
  setCashBox(id) {
    if (id === 1) {
      this.filters.cashBox = [];
    } else {
      const index = this.filters.cashBox.indexOf(id);

      if (index === -1) {
        this.filters.cashBox.push(id);
      } else {
        this.filters.cashBox.splice(index, 1);
      }
    }

    this.getFinance();
  }

  getFinance() {
    let branchFiltered = null;
    if (this.filters.branch > 0) {
      branchFiltered = [this.filters.branch];
    }
    this.financeService.getFinance({
      like: this.filters.like,
      category: this.filters.category,
      cashBox: this.filters.cashBox,
      type: this.filters.type,
      date_start: this.filters.date_start !== '' ? moment(this.filters.date_start).format().slice(0, 10) : '',
      date_end: this.filters.date_end !== '' ? moment(this.filters.date_end).format().slice(0, 10) : '',
      branch: branchFiltered,
      sort: Object.keys(this.filters.sort).length !== 0,
      offset: this.filters.offset,
      limit: this.filters.limit,
    }).then((data: any) => {
        this.financeList = data.list;
        this.sortedData = this.financeList.slice();
        this.operationSum = data.sum;
        this.allSumAll = data.allSum;
        this.income_sum = data.income_sum;
        this.rate_sum = data.rate_sum;
        this.showFilters = false;
        this.financeCount = data.count;
        this.cashboxData = data.cashbox;

        if (Object.keys(this.filters.sort).length !== 0) {
          this.sortData(this.filters.sort);
        }
      },
      (error) => {
        console.log('Ошибка при получении списка финансов: ', error);
      });
  }

  // добавление финансов
  addFinance() {
    if (this.finance.name === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать назначение', type: 'error', body: ''};
      return false;
    }

    if (this.finance.category === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать категорию', type: 'error', body: ''};
      return false;
    }

    if (this.finance.type === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать тип', type: 'error', body: ''};
      return false;
    }

    if (this.finance.sum === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать сумму', type: 'error', body: ''};
      return false;
    }

    if (this.finance.cashBox === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать кассу', type: 'error', body: ''};
      return false;
    }

    if (this.finance.branch === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать филиал', type: 'error', body: ''};
      return false;
    }

    if (this.finance.category === 12 && this.finance.source === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать источник', type: 'error', body: ''};
      return false;
    }

    this.financeService.addFinance({
      id: '',
      name: this.finance.name,
      category: this.finance.category,
      type: this.finance.type,
      sum: this.finance.sum,
      cashBox: this.finance.cashBox,
      branch: this.finance.branch,
      source: this.finance.source,
      date: this.finance.date,
      by_finance_page: this.finance.by_finance_page
    }).then(() => {
        this.globalParamsMessage.data = {title: 'Заявка успешно добавлена', type: 'success', body: ''};
        this.ngOnInit();
        this.showCreate = false;
      },
      (error) => {
        console.log('Ошибка при добавлении новой записи: ', error);
        this.showCreate = false;
      });
  }

  sortData(sort: Sort) {
    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.sort = sort;
      this.getFinance();
    } else {
      const data = this.financeList.slice();
      this.financeList = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'type':
            return compare(a.type, b.type, isAsc);
          case 'name':
            return compare(a.name, b.name, isAsc);
          case 'category':
            return compare(a.category, b.category, isAsc);
          case 'date':
            return compare(a.date_create, b.date_create, isAsc);
          case 'payer':
            return compare(a.payer, b.payer, isAsc);
          case 'sum':
            return compare(a.sum, b.sum, isAsc);
          case 'branch':
            return compare(a.branch, b.branch, isAsc);
          case 'cashBox':
            return compare(a.cashBox, b.cashBox, isAsc);
          default:
            return 0;
        }
      });
    }
  }
  removeRow(type, index) {
    if (type === 'income') {
      this.sendReportData.income.splice(index, 1);
    } else {
      this.sendReportData.expense.splice(index, 1);
    }
    this.calcReportAmount();
  }
  addRow(type) {
    if (type === 'income') {
      this.sendReportData.income.push({
        name: '',
        sum: 0,
        type: 'Доход',
        cashboxId: null,
      });
    } else {
      this.sendReportData.expense.push({
        name: '',
        sum: 0,
        type: 'Расход',
        cashboxId: null,
      });
    }

  }
  calcReportAmount() {
    console.log('CALL - = calcReportAmount');
    const getSumByKey = (arr, key) => {
      return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0);
    }
    const totalIncome = getSumByKey(this.sendReportData.income, 'sum');
    const totalExpense = getSumByKey(this.sendReportData.expense, 'sum');
    this.sendReportData.income_sum = totalIncome;
    this.sendReportData.rate_sum = totalExpense;

    let amount = 0;
    this.sendReportData.income.forEach(item => {
      if (item.cashBoxId === 11 || item.cashBoxId === 16 || item.cashBoxId === 21) {
        amount += Number(item.sum);
      }
    });
    this.sendReportData.expense.forEach(item => {
      console.log(item);
      if (item.cashBoxId === 11 || item.cashBoxId === 16 || item.cashBoxId === 21) {
        amount += Number(item.sum);
      }
    });

    this.sendReportData.cashBoxAmount = amount;

  }
  changeCashbox(increased: any, item: any) {
    item.cashBoxId = increased;
    this.calcReportAmount();
  }
  openSendReportModal() {
    const date = moment().format('Y-MM-DD');
    this.financeService.getFinance({
      /*
      cashBox: [{val: 11}, {val: 12}, {val: 13}, {val: 14}, {val: 15}, {val: 26},
      ],
       */
      type: 0,
      date_start: date,
      date_end: date,
      short_name: true,
    }).then((data: any) => {
        const source = from(data.list);
        const example = source.pipe(
          groupBy(p => {
            return p['type'];
          }),
        mergeMap((group$) => group$.pipe(reduce((acc, cur) => [...acc, cur], []))),
        ).subscribe(p => {
          if (p.length > 0 && p[0].type === 'Доход') {
            this.sendReportData.income = p;
          } else {
            if (p.length > 0 && p[0].type === 'Расход') {
              this.sendReportData.expense = p;
            }
          }

        });
        this.sendReportData.list = data.list;
        const amount = this.calcReportAmount();
        this.sendReportData.income_sum = (data.income_sum > 0) ? data.income_sum : 0;
        this.sendReportData.rate_sum = (data.rate_sum > 0) ? data.rate_sum * -1 : 0;
        this.sendReportData.cashBoxAmount = amount;
        this.calcReportAmount();
        this.sendReport = true;
      },
      (error) => {
        console.log('Ошибка при получении списка финансов: ', error);
      });


  }
  sendReportTelegram() {
    this.financeService.sendReport(this.sendReportData).then((data: any) => {
        this.sendReport = false;
      },
      (error) => {
        console.log('Ошибка при отправки данных: ', error);
      });
  }
  changePage(data) {
    this.p = data;

    if (Object.keys(this.filters.sort).length === 0) {
      this.filters.offset = this.p * this.filters.limit - this.filters.limit;
      this.getFinance();
    }
  }

  changeCount(count) {
    this.filters.limit = count;
    this.getFinance();
  }

  onChangedFilters(increased: any, type: string) {
    this.filters[type] = increased;
  }
  onChangedFiltersGetFinance (increased: any, type: string) {
    this.filters[type] = increased;
    this.getFinance();
  }
  onChanged(increased: any, type: string) {
    this.finance[type] = increased;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
