import {Component, DoCheck, OnChanges} from '@angular/core';
import {throwError as observableThrowError} from 'rxjs';
import {NotificationMessage} from './notification';

@Component({
  selector: 'app-message-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./message_alert.scss']
})
export class NotificationComponent implements DoCheck {
  data: any;
  timerId = null;

  static handlerError(error: any = {}) {
    console.log('Ошибка после http-запроса', error);

    let text_error: string;
    if (typeof error.status !== 'undefined') {
      if (error.status === 0) {
        text_error = 'Неизвестная ошибка';
      } else if (error.status === 200) {
        text_error = error.body.json();
      } else if (error.status === 404) {
        text_error = 'Страница не найдена';
      } else if (error.status === 300) {
        text_error = 'Доступ запрещен';
      } else if (error.status === 500) {
        text_error = 'временно не доступен';
        throw {name: 'CRM', message: 'CRM временно не доступен'};
      } else {
        text_error = '';
      }
    } else {
      text_error = '';
    }

    return observableThrowError(text_error);
  }

  constructor(private notificationMessage: NotificationMessage) {
    this.data = notificationMessage;
  }

  ngDoCheck() {
    if (this.data.data.type !== '') {
      if (this.timerId !== null) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.clear();
      }, 10000);
    }
  }
  clear() {
    this.data.data.type = '';
    this.data.data.title = '';
    this.data.data.body = '';
    console.log('set clear close window');
  }
}
