import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './components/main/main.component';
import {ClientComponent} from './components/client/client.component';
import {SettingsComponent} from './components/settings/settings.component';
import {ClientCreateComponent} from './components/client_create/clientCreate.component';
import {ClientInfoComponent} from './components/client_info/clientInfo.component';
import {StockComponent} from './components/stock/stock.component';
import {AuthComponent} from './components/auth/auth.component';
import {EquipmentsCreateComponent} from './components/equipments_create/equipmentsCreate.component';
import {EquipmentsComponent} from './components/equipments/equipments.component';
import {ApplicationsComponent} from './components/applications/applications.component';
import {SetsComponent} from './components/sets/sets.component';
import {FinanceComponent} from './components/finance/finance.component';
import {ReportComponent} from './components/reports/report.component';
import {FinanceCreateComponent} from './components/finance_create/financeCreate.component';
import {FinanceInfoComponent} from './components/finance_info/financeInfo.component';
import {HireInfoComponent} from './components/hire-info/hireInfo.component';
import {HireLesaComponent} from './components/lesa_hire/hire-lesa.component';
import {DemandComponent} from './components/demand/demand.component';
import {DemandInfoComponent} from './components/demand_info/demand_info.component';
import {BlankComponent} from './components/blank/blank.component';
import {SchedulerComponent} from './components/scheduler/scheduler.component';
import {SchedulerInfoComponent} from './components/scheduler_info/schedulerInfo.component';
import {ProfileComponent} from './components/profile/profile.component';
import {ApplicationsCreateLesaComponent} from './components/applications-create-lesa/applicationsCreateLesa.component';
import {ApplicationsCreateRemoteComponent} from './components/applications-create-remote/applicationsCreateRemote.component';
import {HireOrBronComponent} from './components/hire_or_bron/hireOrBron.component';
import {FaqComponent} from './components/faq/faq.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import {CheckHireComponent} from './components/check_hire/checkHire.component';
import {CheckClientComponent} from './components/check_client/checkClient.component';
import {ApplicationsMobileInfoComponent} from './components/applications_mobile_info/applicationsMobileInfo.component';


const routes: Routes = [
  {
    path: '',
    component: AuthComponent
  },
  {
    path: 'main',
    component: MainComponent
  }, {
    path: 'clients',
    component: ClientComponent
  }, {
    path: 'client-create',
    component: ClientCreateComponent
  }, {
    path: 'clients/:id',
    component: ClientInfoComponent
  }, {
    path: 'settings',
    component: SettingsComponent
  }, {
    path: 'stock',
    component: StockComponent
  }, {
    path: 'demand',
    component: DemandComponent
  }, {
    path: 'demand/:id',
    component: DemandInfoComponent
  }, {
    path: 'sets',
    component: SetsComponent
  }, {
    path: 'equipments-create',
    component: EquipmentsCreateComponent
  }, {
    path: 'equipments/:id',
    component: EquipmentsComponent
  }, {
    path: 'application',
    component: ApplicationsComponent
  },
  {
    path: 'application-create-lesa',
    component: ApplicationsCreateLesaComponent,
  },
  {
    path: 'application-create-remote',
    component: ApplicationsCreateRemoteComponent
  },
  {
    path: 'finance',
    component: FinanceComponent
  },
  {
    path: 'finance-create',
    component: FinanceCreateComponent
  },
  {
    path: 'finance/:id',
    component: FinanceInfoComponent
  },
  {
    path: 'report',
    component: ReportComponent
  },
  {
    path: 'hire',
    component: HireOrBronComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'hire/:id',
    component: HireInfoComponent
  },
  {
    path: 'hire-lesa',
    component: HireLesaComponent
  },
  {
    path: 'blank',
    component: BlankComponent
  },
  {
    path: 'scheduler',
    component: SchedulerComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'scheduler/:id',
    component: SchedulerInfoComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'subscription',
    component: SubscriptionComponent
  },
  {
    path: 'check-hire',
    component: CheckHireComponent
  },
  {
    path: 'check-client',
    component: CheckClientComponent
  },
  {
    path: 'mobile-hire/:id',
    component: ApplicationsMobileInfoComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
  constructor() {
  }
}
