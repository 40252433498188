import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {ShortHireService} from '../short_hire/short_hire.service';

@Injectable()
export class ApplicationsMobileInfoService {

  constructor(private httpService: HttpService) {
  }

  public deleteApplicationsInfoMobileForCrm(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('delete-applications-info-mobile-for-crm', data)
        .then((result) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при добавлении новой заявки', error);
            reject();
          }
        );
    });
  }
}
