import {Injectable} from '@angular/core';

@Injectable()
export class NotificationMessage {
  data = {
    title: '',
    body: '',
    type: '',
  };
}
