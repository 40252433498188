import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {NgModule, Injectable} from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import {AppComponent} from './app.component';
import {MainService} from './components/main/main.service';
import {HttpService} from './utils/http/http.service';
import {HttpClientModule} from '@angular/common/http';
import {MessageAlertComponent} from './components/message_alert/message_alert.component';
import {ClarityModule} from '@clr/angular';
import {GlobalParamsMessage} from './components/message_alert/global-params-message';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SessionStorageService} from './storage/session-storage.service';
import {MenuComponent} from './components/menu/menu.component';
import {HeaderComponent} from './components/header/header.component';
import {ClientComponent} from './components/client/client.component';
import {AppRoutingModule} from './app-routing.module';
import {ClientService} from './components/client/client.service';
import {DigitsNumbersPipe} from './pipes/digits_numbers.pipe';
import {TelephoneNumberPipe} from './pipes/telephone_number.pipe';
import {DopParamsService} from './services/dopParams.service';
import {FormsModule} from '@angular/forms';
import {MainComponent} from './components/main/main.component';
import {UsersComponent} from './components/users/users.component';
import {UsersService} from './components/users/users.service';
import {SaleComponent} from './components/sale/sale.component';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {SettingsComponent} from './components/settings/settings.component';
import {ClientCreateComponent} from './components/client_create/clientCreate.component';
import {ClientInfoService} from './components/client_info/clientInfo.service';
import {ClientInfoComponent} from './components/client_info/clientInfo.component';
import {StockComponent} from './components/stock/stock.component';
import {StockService} from './components/stock/stock.service';
import {EquipmentsService} from './components/equipments/equipments.service';
import {AuthComponent} from './components/auth/auth.component';
import {AuthService} from './components/auth/auth.service';
import {GlobalParams} from './storage/global-params';
import {EquipmentsCreateComponent} from './components/equipments_create/equipmentsCreate.component';
import {EquipmentsCreateService} from './components/equipments_create/equipmentsCreate.service';
import {EquipmentsComponent} from './components/equipments/equipments.component';
import {ApplicationsComponent} from './components/applications/applications.component';
import {ApplicationsService} from './components/applications/applications.service';
import {ApplicationsCreateService} from './components/applications-create/applicationsCreate.service';
import {FooterComponent} from './components/footer/footer.component';
import {SetsComponent} from './components/sets/sets.component';
import {DopParamsChangeService} from './services/dopParamsChange.service';
import {FinanceComponent} from './components/finance/finance.component';
import {FinanceService} from './components/finance/finance.service';
import {FaqService} from './components/faq/faq.service';
import {ReportService} from './components/reports/report.service';
import {ReportComponent} from './components/reports/report.component';
import {FinanceCreateComponent} from './components/finance_create/financeCreate.component';
import {FinanceInfoComponent} from './components/finance_info/financeInfo.component';
import {HireComponent} from './components/hire/hire.component';
import {HireService} from './components/hire/hire.service';
import {HireInfoComponent} from './components/hire-info/hireInfo.component';
import {GlobalPayList} from './components/pay_list/global-pay-list';
import {PayListComponent} from './components/pay_list/pay_list.component';
import {PayComponent} from './components/pay/pay.component';
import {GlobalParamsPay} from './components/pay/global-params-pay';
import {RentalComponent} from './components/rental/rental.component';
import {GlobalParamsRental} from './components/rental/global-params-rental';
import {GlobalExtensionsList} from './components/extensions_list/global-extensions-list';
import {ExtensionsListComponent} from './components/extensions_list/extensions_list.component';
import {TelephoneNumberNotPlusPipe} from './pipes/telephone_number_not_plus.pipe';
import {
  MAT_DATE_LOCALE,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule, MatPaginatorModule,
  MatSortModule, MatTableModule
} from '@angular/material';
import {GlobalPayListClient} from './components/pay_list_client/global-pay-list-client';
import {PayListClientComponent} from './components/pay_list_client/pay_list_client.component';
import {ApplicationListClientComponent} from './components/application_list_client/application_list_client.component';
import {GlobalApplicationListClient} from './components/application_list_client/global-application-list-client';
import {ClientChangeStatusComponent} from './components/client_change_status/client_change_status.component';
import {GlobalParamsClientChangeStatus} from './components/client_change_status/global-params-client-change-status';
import {GlobalClientChangeStatusList} from './components/client_change_status_list/global-client_change_status_list';
import {ClientChangeStatusListComponent} from './components/client_change_status_list/client_change_status_list.component';
import {GlobalParamsUser} from './storage/global-params-user';
import {SettingsParamsComponent} from './components/settingsParams/settingsParams.component';
import {SettingsParamsService} from './components/settingsParams/settingsParams.service';
import {DocumentService} from './services/document.service';
import {EquipmentsCreateMiniService} from './components/equipments_create_mini/equipmentsCreateMini.service';
import {EquipmentsCreateMiniComponent} from './components/equipments_create_mini/equipmentsCreateMini.component';
import {TameComponent} from './components/tame/tame.component';
import {GlobalParamsTame} from './components/tame/global-params-tame';
import {HireLesaComponent} from './components/lesa_hire/hire-lesa.component';
import {DemandComponent} from './components/demand/demand.component';
import {DemandInfoComponent} from './components/demand_info/demand_info.component';
import {ReportEqComponent} from './components/reports_eq/report_eq.component';
import {ReportHireComponent} from './components/reports_hire/report_hire.component';
import {ReportMarketingComponent} from './components/reports_marketing/report_marketing.component';
import {ShortHireService} from './components/short_hire/short_hire.service';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {BlankComponent} from './components/blank/blank.component';
import {SchedulerService} from './components/scheduler/scheduler.service';
import {SchedulerComponent} from './components/scheduler/scheduler.component';
import {SchedulerInfoComponent} from './components/scheduler_info/schedulerInfo.component';
import {ProfileComponent} from './components/profile/profile.component';
import {OwlModule} from 'ngx-owl-carousel';
import {SettingsManagerPlanService} from './components/settings_manager_plan/settingsManagerPlan.service';
import {SettingsManagerPlanComponent} from './components/settings_manager_plan/settingsManagerPlan.component';
import {ComingLesaComponent} from './components/coming_lesa/coming_lesa.component';
import {GlobalParamsComingLesa} from './components/coming_lesa/global-params-coming-lesa';
import {ConsumptionLesaComponent} from './components/consumption_lesa/consumption_lesa.component';
import {GlobalParamsConsumptionLesa} from './components/consumption_lesa/global-params-consumption_lesa';
import {ApplicationsCreateLesaComponent} from './components/applications-create-lesa/applicationsCreateLesa.component';
import {ApplicationsCreateLesaService} from './components/applications-create-lesa/applicationsCreateLesa.service';
import {ApplicationsCreateRemoteComponent} from './components/applications-create-remote/applicationsCreateRemote.component';
import {DemandReportComponent} from './components/demand-report/demand-report.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {LesaHistoryComponent} from './components/lesa_history/lesa_history.component';
import {GlobalParamsLesaHistory} from './components/lesa_history/global-params-lesa-history';
import {ProfileService} from './components/profile/profile.service';
import {ProfileDataComponent} from './components/profile_data/profile.component';
import {SettingsLevelComponent} from './components/settings_level/settingsLevel.component';
import {SettingsLevelService} from './components/settings_level/settingsLevel.service';
import {SettingsManagerPlanBonusComponent} from './components/settings_manager_plan_bonus/settingsManagerPlanBonus.component';
import {SettingsManagerPlanBonusService} from './components/settings_manager_plan_bonus/settingsManagerPlanBonus.service';
import {SettingsManagerAwardService} from './components/settings_manager_award/settingsManagerAward.service';
import {SettingsManagerAwardComponent} from './components/settings_manager_award/settingsManagerAward.component';
import {ApplicationsProcessedComponent} from './components/applicationsProcessed/applicationsProcessed.component';
import {ApplicationsProcessedService} from './components/applicationsProcessed/applicationsProcessed.service';
import {ApplicationsInformingService} from './components/applications_informing/applicationsInforming.service';
import {ApplicationsInformingComponent} from './components/applications_informing/applicationsInforming.component';
import {ApplicationsBronService} from './components/applications-bron/applicationsBron.service';
import {ApplicationsBronComponent} from './components/applications-bron/applicationsBron.component';
import {HireOrBronComponent} from './components/hire_or_bron/hireOrBron.component';
import {SettingsOrganizationService} from './components/settings_organization/settingsOrganization.service';
import {SettingsOrganizationComponent} from './components/settings_organization/settingsOrganization.component';
import {SettingsSiteComponent} from './components/settings_site/settingsSite.component';
import {SettingsSiteService} from './components/settings_site/settingsSite.service';
import {SettingsFileComponent} from './components/settings_file/settingsFile.component';
import {SettingsFileService} from './components/settings_file/settingsFile.service';
import {
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter, NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {CustomDateFormat1Directive, CustomDateFormat2Directive} from './directive/date.directive';
import {InputMaskModule} from 'racoon-mask-raw';
import {ApplicationsCreateNewComponent} from './components/applications-create-new/applicationsCreateNew.component';
import {ApplicationsCreateNewService} from './components/applications-create-new/applicationsCreateNew.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkTreeModule} from '@angular/cdk/tree';
import {CdkTableModule} from '@angular/cdk/table';
import {StylePaginatorDirective} from './directive/stylePaginator.directive';
import {EquipmentsInfoComponent} from './components/equipments_info/equipments_info.component';
import {SettingsCarouselComponent} from './components/settings_carousel/settingsCarousel.component';
import {SettingsCarouselService} from './components/settings_carousel/settingsCarousel.service';
import {FaqComponent} from './components/faq/faq.component';
import {SettingsSaleDaysComponent} from './components/settings_sale_days/settingsSaleDays.component';
import {SettingsSaleDaysService} from './components/settings_sale_days/settingsSaleDays.service';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {SelectComponent} from './services/select/select.component';
import {SubscriptionComponent} from './components/subscription/subscription.component';
import {FinanceZalogComponent} from './components/finance_zalog/financeZalog.component';
import {SettingsClientBonusComponent} from './components/settings_client_bonus/settingsClientBonus.component';
import {SettingsClientBonusService} from './components/settings_client_bonus/settingsClientBonus.service';
import {ApplicationsMobileComponent} from './components/applications_mobile/applicationsMobile.component';
import {ApplicationsMobileService} from './components/applications_mobile/applicationsMobile.service';
import {ApplicationsMobileInfoComponent} from './components/applications_mobile_info/applicationsMobileInfo.component';
import {SettingsClientBonusPercentService} from './components/settings_client_bonus_percent/settingsClientBonusPercent.service';
import {SettingsClientBonusPercentComponent} from './components/settings_client_bonus_percent/settingsClientBonusPercent.component';
import {CheckClientModalComponent} from './components/check_client_modal/checkClientModal.component';
import {CheckHireComponent} from './components/check_hire/checkHire.component';
import {CheckClientComponent} from './components/check_client/checkClient.component';
import {CheckCallsModalComponent} from './components/check_calls_modal/checkCallsModal.component';
import {NotificationsService} from './components/notifications/notifications.service';
import {CheckHireModalComponent} from './components/check_hire_modal/checkHireModal.component';
import {ApplicationsMobileInfoService} from './components/applications_mobile_info/applicationsMobileInfo.service';
import { WebsocketModule } from './websocket/websocket.module';
import { environment } from '../environments/environment';
import {IncomeCallComponent} from './components/message_alert/income_call.component';
import {IncomeCallMessage} from './components/message_alert/income_call';
import {NotificationComponent} from './components/message_alert/notification.component';
import {NotificationMessage} from './components/message_alert/notification';
import {SocketioService} from './socketio.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': {velocity: 0.4, threshold: 20} // override default settings
  };
}

@NgModule({
  declarations: [
    DigitsNumbersPipe,
    TelephoneNumberPipe,
    TelephoneNumberNotPlusPipe,
    AppComponent,
    MainComponent,
    MessageAlertComponent,
    NotificationComponent,
    IncomeCallComponent,
    PayListComponent,
    PayListClientComponent,
    MenuComponent,
    HeaderComponent,
    ClientComponent,
    UsersComponent,
    SaleComponent,
    SettingsComponent,
    ClientCreateComponent,
    ClientInfoComponent,
    StockComponent,
    AuthComponent,
    EquipmentsCreateComponent,
    EquipmentsComponent,
    ApplicationsComponent,
    FooterComponent,
    SetsComponent,
    FinanceComponent,
    ReportComponent,
    FinanceCreateComponent,
    FinanceInfoComponent,
    HireComponent,
    HireInfoComponent,
    PayComponent,
    ExtensionsListComponent,
    RentalComponent,
    ApplicationListClientComponent,
    ClientChangeStatusComponent,
    ClientChangeStatusListComponent,
    SettingsParamsComponent,
    EquipmentsCreateMiniComponent,
    TameComponent,
    HireLesaComponent,
    DemandComponent,
    DemandInfoComponent,
    ReportEqComponent,
    ReportHireComponent,
    ReportMarketingComponent,
    NotificationsComponent,
    BlankComponent,
    SchedulerComponent,
    SchedulerInfoComponent,
    ProfileComponent,
    SettingsManagerPlanComponent,
    ComingLesaComponent,
    ConsumptionLesaComponent,
    ApplicationsCreateLesaComponent,
    ApplicationsCreateRemoteComponent,
    DemandReportComponent,
    LesaHistoryComponent,
    ProfileDataComponent,
    SettingsLevelComponent,
    SettingsManagerPlanBonusComponent,
    SettingsManagerAwardComponent,
    ApplicationsProcessedComponent,
    ApplicationsInformingComponent,
    ApplicationsBronComponent,
    HireOrBronComponent,
    SettingsOrganizationComponent,
    SettingsSiteComponent,
    SettingsFileComponent,
    CustomDateFormat1Directive,
    CustomDateFormat2Directive,
    StylePaginatorDirective,
    ApplicationsCreateNewComponent,
    EquipmentsInfoComponent,
    SettingsCarouselComponent,
    FaqComponent,
    SettingsSaleDaysComponent,
    SelectComponent,
    SubscriptionComponent,
    FinanceZalogComponent,
    SettingsClientBonusComponent,
    ApplicationsMobileComponent,
    ApplicationsMobileInfoComponent,
    SettingsClientBonusPercentComponent,
    CheckClientModalComponent,
    CheckHireComponent,
    CheckClientComponent,
    CheckCallsModalComponent,
    CheckHireModalComponent
  ],
  imports: [
    BrowserModule,
    NgxMatMomentModule,
    NgxPaginationModule,
    HttpClientModule,
    ClarityModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    MatSortModule,
    NgxMaskModule.forRoot(options),
    OwlModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    NgxMatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    InputMaskModule,
    MatTableModule,
    DragDropModule,
    CdkTreeModule,
    CdkTableModule,
    MatPaginatorModule,
    NgScrollbarModule,
    WebsocketModule.config({
      url: environment.ws
    })
  ],
  providers: [
    MainService,
    HttpService,
    GlobalParamsMessage,
    GlobalParamsPay,
    GlobalPayListClient,
    GlobalParamsRental,
    GlobalParamsTame,
    GlobalPayList,
    GlobalApplicationListClient,
    GlobalExtensionsList,
    GlobalParams,
    GlobalParamsUser,
    GlobalParamsComingLesa,
    GlobalParamsConsumptionLesa,
    SessionStorageService,
    IncomeCallMessage,
    NotificationMessage,
    ClientService,
    DopParamsService,
    UsersService,
    ClientInfoService,
    StockService,
    EquipmentsService,
    AuthService,
    EquipmentsCreateService,
    ApplicationsService,
    ApplicationsCreateService,
    DopParamsChangeService,
    FinanceService,
    FaqService,
    ReportService,
    HireService,
    GlobalParamsClientChangeStatus,
    GlobalClientChangeStatusList,
    SettingsParamsService,
    DocumentService,
    EquipmentsCreateMiniService,
    ShortHireService,
    SchedulerService,
    SettingsManagerPlanService,
    ApplicationsCreateLesaService,
    GlobalParamsLesaHistory,
    ProfileService,
    SettingsLevelService,
    SettingsManagerPlanBonusService,
    SettingsManagerAwardService,
    ApplicationsProcessedService,
    ApplicationsInformingService,
    ApplicationsBronService,
    SettingsOrganizationService,
    SettingsSiteService,
    SettingsFileService,
    MatDatepickerModule,
    ApplicationsCreateNewService,
    SettingsCarouselService,
    SettingsSaleDaysService,
    SettingsClientBonusService,
    ApplicationsMobileService,
    SettingsClientBonusPercentService,
    NotificationsService,
    ApplicationsMobileInfoService,
    SocketioService,
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},

    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
}
