import {Injectable} from '@angular/core';
import {HttpService} from '../../utils/http/http.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';

@Injectable()
export class SchedulerService {
  activeFields: InterFaceActiveFields[] = [];
  status: InterFaceSchedulerStatus[] = [];

  constructor(private httpService: HttpService,
              private globalParamsMessage: GlobalParamsMessage) {
  }

  // получение активных полей
  public getActiveFields() {
    return new Promise((resolve, reject) => {
      if (this.activeFields.length === 0) {
        this.httpService.prepareQuery('get-scheduler-fields', '')
          .then((result: InterFaceActiveFields[]) => {
              this.activeFields = result;
              resolve(this.activeFields);
            },
            (error) => {
              console.log('Ошибка при получении отображаемых полей для планировщика', error);
              reject();
            }
          );
      } else {
        resolve(this.activeFields);
      }
    });
  }

  // получение статусов
  public getStatus() {
    return new Promise((resolve, reject) => {
      if (this.activeFields.length === 0) {
        this.httpService.prepareQuery('get-scheduler-status', '')
          .then((result: InterFaceSchedulerStatus[]) => {
              this.status = result;
              resolve(this.status);
            },
            (error) => {
              console.log('Ошибка при получении статусов для планировщика', error);
              reject();
            }
          );
      } else {
        resolve(this.status);
      }
    });
  }

  // изменение списка активных полей для таблицы
  public changeFields(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('change-scheduler-fields', data)
        .then(() => {
            resolve('');
          },
          (error) => {
            console.log('Ошибка при изменение списка отображаемых полей для планировщика', error);
            reject();
          }
        );
    });
  }

  // Получение списка записей
  public getSchedulers(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-schedulers', data)
        .then((result: InterFaceSchedulerList[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка планировщика', error);
            reject();
          }
        );
    });
  }

  // Получение одной записи
  public getCurrentScheduler(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('get-schedulers-info', data)
        .then((result: InterFaceSchedulerList[]) => {
            resolve(result);
          },
          (error) => {
            console.log('Ошибка при получении списка планировщика', error);
            reject();
          }
        );
    });
  }

  // добавить новую запись
  public addNewScheduler(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('add-scheduler', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при добавлении новой записи', error);
            reject();
          }
        );
    });
  }

  // добавить новую запись
  public updateScheduler(data) {
    return new Promise((resolve, reject) => {
      this.httpService.prepareQuery('update-scheduler', data)
        .then((result: any) => {
            this.globalParamsMessage.data = {title: result.msg, type: 'success', body: ''};
            resolve('');
          },
          (error) => {
            console.log('Ошибка при обновлении записи', error);
            reject();
          }
        );
    });
  }
}
