import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {DopParamsService} from '../../services/dopParams.service';
import {GlobalParamsMessage} from '../message_alert/global-params-message';
import {GlobalParams} from '../../storage/global-params';
import {ClientService} from '../client/client.service';
import {GlobalParamsUser} from '../../storage/global-params-user';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-client-create',
  templateUrl: './clientCreate.component.html',
})

export class ClientCreateComponent implements OnChanges {
  @Input() app_branch = null;
  @Input() app_source = null;
  @Input() app_phone = '';
  @Input() hiddenButtons = false;
  @Input() dataClient;
  @Input() checkSaveWorld;
  @Output() onChangedCreate = new EventEmitter<any>();
  // список статусов
  statusList: InterFaceDopParams[] = [];
  // список филиалов
  branches: InterFaceDopParams[] = [];
  // список скидок
  discounts: InterFaceDopParams[] = [];
  // список исчтоников
  sources: InterFaceDopParams[] = [];
  // список документов
  typeDocs: InterFaceDopParams[] = [];
  newClient: InterFaceNewClient = {
    id: null,
    sale: null,
    branch: null,
    new_status: null,
    source: null,
    inn: null,
    kpp: null,
    name_chief: '',
    name: '',
    phone_1: this.app_phone,
    phone_2: '',
    phone_3: '',
    email: '',
    date_birth: '',
    comment: '',
    name1: '',
    name2: '',
    name3: '',
    typeDoc: null,
    doc_series: '',
    doc_number: '',
    type: null,
    korSchet: '',
    rasSchet: '',
    bik: '',
    bank: '',
    ogrn: ''
  };

  debts: InterFaceDebts = {
    show: false,
    data: [{
      bailiff: '',
      department: '',
      details: '',
      exe_production: '',
      ip_end: '',
      name: '',
      subject: ''
    }]
  };

  clientType = [
    {'val': 1, 'name': 'Физ. лицо'},
    {'val': 2, 'name': 'OOO'},
    {'val': 3, 'name': 'ИП'},
  ];

  createClientCheck = false;
  showSelect = false;

  constructor(private clientService: ClientService,
              private dopParamsService: DopParamsService,
              private globalParamsMessage: GlobalParamsMessage,
              private globalParams: GlobalParams,
              public globalParamsUser: GlobalParamsUser,
              private router: Router) {
    this.dopParamsService.getStatusClient().then((data: InterFaceDopParams[]) => {
        this.statusList = data;
      },
      (error) => {
        console.log('Ошибка при получении статусов: ', error);
      });

    this.dopParamsService.getBranch().then((data: InterFaceDopParams[]) => {
        this.branches = data;

        if (this.globalParamsUser.type === 1) {
          this.newClient.branch = this.globalParamsUser.branch.val;
        } else if (this.app_branch !== null && this.app_branch !== 0) {
          this.newClient.branch = this.app_branch;
        } else if (this.globalParamsUser.type === 2) {
          this.newClient.branch = this.globalParamsUser.branch.val;
        }

      },
      (error) => {
        console.log('Ошибка при получении филиалов: ', error);
      });

    this.dopParamsService.getDiscount().then((data: InterFaceDopParams[]) => {
        this.discounts = data;
        this.newClient.sale = this.discounts[0].val;
      },
      (error) => {
        console.log('Ошибка при получении списка скидок: ', error);
      });

    this.dopParamsService.getClientTypeDoc().then((data: InterFaceDopParams[]) => {
        this.typeDocs = data;
      },
      (error) => {
        console.log('Ошибка при получении типов документов: ', error);
      });

    this.dopParamsService.getSource().then((data: InterFaceDopParams[]) => {
        this.newClient.source = this.app_source != null ? this.app_source : 1;
        this.sources = data;
      },
      (error) => {
        console.log('Ошибка при получении источников: ', error);
      });

    this.newClient.phone_1 = this.app_phone;
  }

  ngOnChanges() {
    if (this.hiddenButtons === true) {
      this.showSelect = false;
    }

    if (typeof this.dataClient !== 'undefined') {
      this.newClient = this.dataClient;
    }

    if (this.checkSaveWorld !== 0) {
      this.addClient();
    }
  }

  addClient() {
    if (this.createClientCheck) {
      return false;
    }

    this.createClientCheck = true;
    if (this.newClient.type === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать тип клиента', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }
    if (this.newClient.sale === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать скидку клиента', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }

    if (this.newClient.branch === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать город аренды клиента', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }

    if (this.newClient.new_status === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать статус клиента', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }
    if (this.newClient.type === 1) {
      /**
       * Валидация для создание Физ.лица
       */
      if (this.newClient.date_birth === '') {
        this.globalParamsMessage.data = {title: 'Необходимо указать дату рождения клиента', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.name === '') {
        this.globalParamsMessage.data = {title: 'Необходимо указать ФИО клиента', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.typeDoc === null) {
        this.globalParamsMessage.data = {title: 'Необходимо указать тип документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.doc_series === '') {
        this.globalParamsMessage.data = {title: 'Необходимо указать серию документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.doc_number.length === 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать номер документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.date_birth.length === 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать дату рождения клиента', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
    } else if (this.newClient.type === 2) {
      /**
       * валидация для создание ООО
       */
      if (this.newClient.name.length  === 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать Название компании', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      /*
      if (this.newClient.name_chief.length  === 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать Представителя', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
       */
      if (this.newClient.date_birth.length === 0 && this.newClient.name_chief.length > 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать дату рождения клиента', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.typeDoc === null && this.newClient.name_chief.length > 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать тип документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.doc_number.length === 0 && this.newClient.name_chief.length > 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать номер документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.doc_series.length === 0 && this.newClient.name_chief.length > 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать серию документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
    } else if (this.newClient.type === 3) {
      /**
       * Валидация для создание ИП
       */
      if (this.newClient.name.length  === 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать Название компании', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      /*
      if (this.newClient.name_chief.length  === 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать Представителя', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
       */
      if (this.newClient.date_birth.length === 0 && this.newClient.name_chief.length > 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать дату рождения клиента', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.typeDoc === null  && this.newClient.name_chief.length > 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать тип документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.doc_number.length === 0  && this.newClient.name_chief.length > 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать номер документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
      if (this.newClient.doc_series.length === 0  && this.newClient.name_chief.length > 0) {
        this.globalParamsMessage.data = {title: 'Необходимо указать серию документа', type: 'error', body: ''};
        this.createClientCheck = false;
        return false;
      }
    }
    if (this.newClient.source === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать Источник', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }
    if (this.newClient.type === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать тип клиента', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }


    if (this.newClient.phone_1 === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать номер телефона клиента', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }

    if (this.newClient.phone_2 === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать 2 номер телефона клиента', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }

    if (this.newClient.phone_2 === this.newClient.phone_1) {
      this.globalParamsMessage.data = {title: 'Номера телефонов у клиента не должны совпадать', type: 'error', body: ''};
      this.createClientCheck = false;
      return false;
    }

    this.clientService.addClient({
      sale: this.newClient.sale,
      branch: this.newClient.branch,
      new_status: this.newClient.new_status,
      source: this.newClient.source,
      name: this.newClient.name,
      inn: this.newClient.inn,
      kpp: this.newClient.kpp,
      name_chief: this.newClient.name_chief,
      email: this.newClient.email,
      clientId: '',
      phone_1: this.newClient.phone_1.replace(/[\),\(,\-,+,\s]/g, ''),
      phone_2: this.newClient.phone_2.replace(/[\),\(,\-,+,\s]/g, ''),
      phone_3: this.newClient.phone_3.replace(/[\),\(,\-,+,\s]/g, ''),
      date_birth: this.newClient.date_birth !== '' ? moment(this.newClient.date_birth).format().slice(0, 10) : '',
      comment: this.newClient.comment,
      name1: this.newClient.name1,
      name2: this.newClient.name2,
      name3: this.newClient.name3,
      typeDoc: this.newClient.typeDoc,
      doc_series: this.newClient.doc_series,
      doc_number: this.newClient.doc_number,
      type: this.newClient.type,
      korSchet: this.newClient.korSchet,
      rasSchet: this.newClient.rasSchet,
      bik: this.newClient.bik,
      bank: this.newClient.bank,
      ogrn: this.newClient.ogrn,
    }).then((result: InterFaceMiniClientInfo) => {
        this.globalParamsMessage.data = {title: 'Пользователь успешно добавлен', type: 'success', body: ''};
        this.globalParams.showModalCreateUser = false;

        if (this.hiddenButtons === true) {
          this.onChangedCreate.emit({
            'client_id': result.client_id,
            'client_phone': result.client_phone,
            'checkSaveWorld': this.checkSaveWorld
          });
        } else {
          if (this.router.url === '/client-create') {
            this.router.navigate(['/clients']);
          }
        }

        this.createClientCheck = false;
      },
      (error) => {
        console.log('Ошибка при добавлении нового пользователей: ', error);
        this.createClientCheck = false;
      });
  }

  // проверка через приставов
  getBailiffs() {
    if (this.newClient.branch === null) {
      this.globalParamsMessage.data = {title: 'Необходимо указать филиал', type: 'success', body: ''};
      return false;
    }

    if (this.newClient.name === '') {
      this.globalParamsMessage.data = {title: 'Необходимо указать имя или наименование организации', type: 'success', body: ''};
      return false;
    }

    this.clientService.getBailiffs({fio: name, region: this.newClient.branch, type: this.newClient.type}).then((data: any) => {
        if (data.length !== 0) {
          this.debts = {show: true, data: data};
        }
      },
      (error) => {
        console.log('Ошибка при получении данных от приставов: ', error);
      });
  }

  onChanged(increased: any, type: string, update = false) {
    this.newClient[type] = increased;
  }
}
